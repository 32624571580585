'use strict';

angular.module('tailor').directive('onDocumentClick', function onDocumentClick($document) {
  return {
    restrict: 'A',
    link: function ($scope, $element, $attrs) {
      const onClick = function (event) {
        const isPopup = $element[0] === event.target;
        const isClickedElementChildOfPopup = $element.find(event.target).length > 0;

        if (!isPopup && !isClickedElementChildOfPopup) {
          $scope.$apply(function () {
            $scope.$eval($attrs.onDocumentClick);
          });
        }
      };

      $document.on('click', onClick);

      $scope.$on('$destroy', function () {
        $document.off('click', onClick);
      });
    },
  };
});
