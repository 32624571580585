'use strict';
/**
 * @directive fieldHistory
 * @desc utility function for displaying history for any _field_ in a standard way
 * If you wanna display history on a different piece of data (e.g. collection or questions),
 * use a one of those history directives intead.
 */
/**
 * @service fieldHistoryValue
 * @desc internal directive used by the `fieldHistory` directive
 * !! not recommended to use separately
 */
angular.module('tailor')
  .directive('fieldHistory', function fieldHistory($compile, DEBOUNCE_RATE, historyService) {
    return {
      restrict: 'A',
      templateUrl: 'views/directives/history/field-history.html',
      priority: 1,
      controller: function($scope, $element, $attrs) {
        const fieldName = $scope.$eval($attrs.fieldHistory);
        $scope.fieldHistory = { changes: [] };
        $scope.fieldHistoryButtonLocation = $element;
        $scope.configuration = $scope.$parent.$parent.$parent.configuration;
        $scope.module = $scope.$parent.$parent.$parent.module;
        $scope.unit = $scope.$parent.$parent.$parent.unit;
        $scope.$watch('history', function() {
          if ($scope.history) {
            $scope.fieldHistory = historyService.computeChanges(fieldName, $scope.history);
          }
        });

        // if the field changes, automatically push changes onto the history
        let prior = _.cloneDeep($scope.model[fieldName]);
        const updateFieldHistory = _.debounce(function() {
          const newValue = _.cloneDeep($scope.model[fieldName]);
          $scope.fieldHistory.changes.unshift({
            revisionId: null,
            timestamp: (new Date()).getTime(),
            whoDunnit: $scope.$root.user.email,
            change: newValue,
            prior,
          });
          prior = newValue;

          if($scope.configuration.viewOptions.changes.type === 'tillToday') {
            $scope.configuration.edited = true;
            $scope.module.edited = true;
            $scope.unit.edited = true;
            const section = $scope.field.section;
            for(let i = 0; i < $scope.unit.sections.length; i++) {
              if($scope.unit.sections[i].name === section) {
                $scope.unit.sections[i].edited = true;
              }
            }
          }

        }, DEBOUNCE_RATE);
        $scope.$on('field:change', function(event, field) {
          if (field.split('.')[1] === fieldName) {
            updateFieldHistory();
          }
        });
      },
    };
  })
  .directive('fieldHistoryValue', function($compile, fieldTemplateService) {
    return {
      restrict: 'A',
      scope: true,
      require: ['^historicValue'],
      link: function($scope, $element, $attrs, ctrls) {
        const change = ctrls[0].historicValue;
        const definition = $scope.definition;
        if (definition && change) {
          const value = {};
          switch(definition.type) {
            case 'eligibility':
              _.merge(value, change.prior, change.change);
              change.prior.value.associated_questions = _.reduce(change.prior.value.associated_questions, function(agg, val, k) {
                agg[k] = val;
                return agg;
              }, {});
              change.change.value.associated_questions = _.reduce(change.change.value.associated_questions, function(agg, val, k) {
                agg[k] = val;
                return agg;
              }, {});
              break;
            case 'group':
              Object.assign(value, (change.prior || {}).value, change.change.value);
              break;
            case 'collection':
              Object.assign(value, $scope.$parent.model, change.change);
              break;
            case 'picker':
              value[definition.name] = change.change;
              // undo array vs object from deep-diff.js
              if (value[definition.name] && value[definition.name].value && value[definition.name].value.items && !Array.isArray(value[definition.name].value.items)) {
                value[definition.name].value.items = _.values(value[definition.name].value.items);
              }
              break;
            default:
              value[definition.name] = change.change;
              break;
          }

          // todo, maybe: if we separate a fieldWrapper service and fieldService,
          // we might be able to remove this stuff, and do it via attributes?
          // not sure if that's better.
          const fieldLabel = fieldTemplateService.build(definition, '').find('.field-label');
          const fieldValue = fieldLabel.find('[name="field"]');
          fieldLabel.find('comment-bubble').remove();
          fieldLabel.find('help-text').remove();
          fieldValue.attr('disabled', true).attr('ng-disabled', true);

          // special case for richtexts,
          // which need a different disabled state for history than normal rendering
          // cause they doesn't need to be rendered as editable, ever
          if (definition.type === 'richtext') {
            fieldValue
              .removeAttr('richtext')
              .attr('ng-bind-html', 'model.' + definition.name + '.value');
          }

          $scope.model = value || '';
          $element.html(fieldLabel);
          $compile(fieldLabel)($scope);
        }
      }
    };
  });
