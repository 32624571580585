'use strict';

angular.module('tailor')
  .directive('sidebar', function Sidebar($parse) {
    return {
      restrict: 'A',
      templateUrl: 'views/directives/sidebar.html',
      transclude: true,
      scope: true,
      link: function($scope, $element, $attrs) {
        const closeFn = $parse($attrs.onClose);
        $scope.close = function($event) {
          if (closeFn) {
            closeFn($scope, { $event });
          }
        };
        function render(newValue) {
          if(newValue) {
            $element.children('.sidebar').addClass('open');
          } else {
            $element.children('.sidebar').removeClass('open');
          }
        }
        $scope.$watch($attrs.active, render);
      }
    };
  });
