'use strict';

angular.module('tailor')
  .directive('sectionWrapper', function sectionWrapper(authorizationService) {
    return {
      restrict: 'A',
      templateUrl: 'views/directives/section-wrapper.html',
      transclude: true,
      scope: {},
      controller: function controller($scope) {
        authorizationService.addAuthToScope($scope);
        // we need an isolated scope for each section and unit
        // but, do isolate it via the directive scope keyword
        // (a.k.a. the standard angular way)
        // then we would need a LOT of attributes in the dom,
        // plus a lot of scope reassignments at line 9 here
        // instead, we use the "transcludeAndIsolateScope" code below
        // and programatically pass forward parent scope things
        $scope.definition = $scope.$parent.definition;
        $scope.unit = $scope.$parent.unit;
        $scope.module = $scope.$parent.module;
        $scope.configuration = $scope.$parent.configuration;
        $scope.customer = $scope.$parent.customer;
        $scope.unitDisplayName = $scope.$parent.unitDisplayName;

        $scope.model = $scope.unit;
      },
      compile: function transcludeAndIsolateScope(element, attr, $transclude) {
        // the below is a (drastically) simplified version of the isolate scope
        // from ng-repeat in angular core

        let block;
        // check if elements have already been rendered
        if(block) {
          // if so remove them from DOM, and destroy their scope
          block.el.remove();
          block.scope.$destroy();
          block = null;
        }
        return function($scope, $element/* , $attr*/) {
          const childScope = $scope.$new();
          const appendClone = function(clone) {
            $element.append(clone);
            // keep these for garbage collection
            block = {
              el: clone,
              scope: childScope
            };
          };
          $transclude(childScope, appendClone);
        };
      }
    };
  });
