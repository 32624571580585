'use strict';

angular.module('tailor')
  .directive('trackVisits', function TrackVisits() {
    return {
      restrict: 'A',
      require: '?^form',
      link: function postLink($scope, $element, $attrs, form) {
        function markElementAsVisited() {
          if($element.hasClass('visited')) {
            $element.off('focus', markElementAsVisited);
          } else {
            $scope.$apply(function() {
              if(form) {
                form.$visited = true;
              }
              $element.addClass('visited');
              $($element).closest('[ng-form]').addClass('visited');
              $element.off('focus', markElementAsVisited);
            });
          }
        }

        $element.on('focus', markElementAsVisited);
      }
    };
  });
