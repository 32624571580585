'use strict';

angular.module('tailor')
  .factory('moduleDefinitionTemplateService', function moduleDefinitionTemplateService(sectionTemplateService, unitTemplateService, moduleTemplateService) {
    function build(moduleDefinition, path, options) {
      switch(path.split('.').length) {
        case 1:
          return moduleTemplateService.buildFromModuleDefinition(moduleDefinition, path);
        case 2:
          return unitTemplateService.buildFromUnitDefinition(moduleDefinition, path);
        case 3:
          return sectionTemplateService.buildFromSectionDefinition(moduleDefinition, path, options);
      }
      throw ('An invalid path was passed: ' + path);
    }

    return {
      build
    };
  });
