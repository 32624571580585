'use strict';

angular
  .module('tailor')
  .factory('debounceHttpService', function debounceHttpService($http, DEBOUNCE_RATE, $q, $timeout) {
    const debounces = {};
    function debounceByUrlAndKey(httpMethod, url, key, newData, config) {
      let debouncedCall, deferred;

      const identifier = url + '|' + key + '|' + newData[key];
      debouncedCall = debounces[identifier];
      const makeCall = function () {
        delete debounces[identifier];
        httpMethod(url, debouncedCall.data, config)
          .then(function (response) {
            deferred.resolve(response);
          })
          .catch(function (error) {
            deferred.reject(error);
          });
      };

      if (typeof debouncedCall === 'undefined') {
        deferred = $q.defer();

        debouncedCall = {
          timeout: $timeout(makeCall, DEBOUNCE_RATE),
          data: newData,
          deferred,
          url,
          config,
        };

        debounces[identifier] = debouncedCall;
      } else {
        deferred = debouncedCall.deferred;

        $timeout.cancel(debouncedCall.timeout);
        _.merge(debouncedCall.data, newData);

        debouncedCall.timeout = $timeout(makeCall, DEBOUNCE_RATE);
      }

      return deferred.promise;
    }

    return {
      patch: function (url, data, config) {
        config = config || {};
        return debounceByUrlAndKey($http.patch, url, config.debounceBy, data, config);
      },
    };
  });
