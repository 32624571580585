'use strict';

angular
  .module('tailor')
  .directive('commentBox', function CommentBox($timeout, accordionConfig) {
    return {
      restrict: 'EA',
      require: ['^field'],
      scope: false,
      templateUrl: 'views/directives/comment-box.html',
      controller: function CommentBoxCtrl($scope, commentsService, featureFlagService, $q) {
        const unit = $scope.$parent.$parent.unit;
        const model = $scope.$parent.$parent.model;
        const fieldName = $scope.$parent.definition.name;
        const allComments = model[fieldName].comments;

        $scope.isExpanded = false;
        $scope.newComment = {};
        $scope.comments = [];

        $scope.fetchCommentData = function (emptyCommentIds) {
          if (emptyCommentIds.length) {
            return $q.all(emptyCommentIds.map(id =>
              commentsService.get(id)
            )).then(data => {
              return data;
            });
          }
          return $q.resolve([]);
        };

        if (featureFlagService.get($scope.user, 'builder-1670-collection-comment-fixes') === 'on') {
          $scope.$watchCollection(
            '$parent.$parent.model.' + fieldName + '.comments',
            function () {
              const emptyCommentIds = [];
              $scope.comments =
                allComments.filter(function commentExistsOnUnit(comment) {
                  // comment is empty obj w/ id
                  // comment on unit has all comment data
                  const commentOnUnit = _.find(getUnitComments(), {
                    id: comment.id,
                  });
                  if (comment.deleted) {
                    return false;
                  }
                  // if text missing from commentOnUnit fetch comment text from DB
                  if (!commentOnUnit || !commentOnUnit.text) {
                    emptyCommentIds.push(comment.id);
                    return false;
                  }
                  _.merge(comment, commentOnUnit);
                  return true;
                });

              $scope.fetchCommentData(emptyCommentIds).then(data => {
                $scope.comments = [...$scope.comments, ...data];
              });
            }
          );
        } else {
          $scope.$watchCollection(
            '$parent.$parent.model.' + fieldName + '.comments',
            function () {
              $scope.comments = _.filter(
                allComments,
                function commentExistsOnUnit(comment) {
                  const commentOnUnit = _.find(getUnitComments(), {
                    id: comment.id,
                  });
                  _.merge(comment, commentOnUnit);
                  return Boolean(commentOnUnit);
                }
              );
            }
          );
        }

        function getUnitComments() {
          // grabbbing comment details we need but only by id for a given unit
          // need to share results of this across plans/prem groups/across units
          let unitLookup = $scope.$parent.$parent; // same depth as thev unit var delared earlier
          while (unitLookup && unitLookup.unit && !unitLookup.unit.comments) {
            unitLookup = unitLookup.$parent;
          }
          return unit.comments || (unitLookup.unit && unitLookup.unit.comments);
        }

        function updateCommentCount(incOrDec) {
          let current = $scope;
          while (typeof current.section === 'undefined') {
            current = current.$parent;
          }

          current.module.commentCount += incOrDec;
          const unitId = current.unit.id;
          const sectionName = current.section.name;
          for (let i = 0; i < current.module.Units.length; i++) {
            if (current.module.Units[i].id === unitId) {
              current.module.Units[i].commentCount += incOrDec;
              for (
                let j = 0;
                j < current.module.Units[i].sections.length;
                j++
              ) {
                if (current.module.Units[i].sections[j].name === sectionName) {
                  current.module.Units[i].sections[j].commentCount += incOrDec;
                }
              }
            }
          }
          current.section.commentCount += incOrDec;
        }

        $scope.closeBox = function () {
          $scope.isExpanded = !$scope.isExpanded;
        };
        $scope.saveEdits = function (comment) {
          return commentsService
            .update(comment, { text: comment.text })
            .then(function (updatedComment) {
              const commentIdx = _.findIndex($scope.comments, {
                id: updatedComment.id,
              });
              $scope.comments[commentIdx] = updatedComment;
              comment.editing = false;
            });
        };
        $scope.deleteComment = function (comment) {
          return commentsService.remove(comment).then(function () {
            $scope.comments.splice(
              _.findIndex($scope.comments, function (i) {
                return i.id === comment.id;
              }),
              1
            );
            const unitComments = getUnitComments();
            unitComments.splice(
              _.findIndex(unitComments, function (i) {
                return i.id === comment.id;
              }),
              1
            );
            if (!comment.resolved) {
              updateCommentCount(-1);
            }
          });
        };
        $scope.addComment = function (comment) {
          $scope.$emit('field:addComment', fieldName, comment, allComments);
          $scope.newComment = {};
        };
        $scope.toggleResolved = function (comment) {
          return commentsService
            .update(comment, { resolved: !comment.resolved })
            .then(function (updatedComment) {
              const commentIdx = _.findIndex($scope.comments, {
                id: updatedComment.id,
              });
              $scope.comments[commentIdx] = updatedComment;

              if (updatedComment.resolved) {
                updateCommentCount(-1);
              } else {
                updateCommentCount(1);
              }
            });
        };
      },
      link: function (scope, element) {
        const $pane = element.find('v-accordion').find('v-pane');
        function scrollToBottom() {
          // v-pane-accordion is going to animate in across 500 ms and eased
          // move our scroll top w/ that animation
          for (
            let i = 0;
            i < accordionConfig.expandAnimationDuration * 1000;
            i += 50
          ) {
            $timeout(function () {
              $pane[0].scrollTop = 100000;
            }, i);
          }
        }

        scope.$watch('isExpanded', function () {
          if (scope.isExpanded) {
            scrollToBottom();
          }
        });
      },
    };
  })
  .directive('commentBubble', function CommentBubble($compile) {
    return {
      restrict: 'EA',
      require: ['^field'],
      template:
        '<span ng-show=\'user.role !== "partner" || !$root.isDIY(configuration.customerTier)\' ng-class=\'[(comments | filter: { resolved: false }).length ? "fa fa-comment" : "fa fa-comment-o", isExpanded ? "active-icon" : "", (comments | filter: { resolved: true }).length === comments.length ? "all-comments-resolved" : ""]\' ng-click=\'isExpanded = !isExpanded\'></span>',
      link: function (scope, element, attrs, ctrl) {
        scope.field = ctrl[0].definition;

        const $element = angular.element(element);
        const commentBox = angular.element(
          '<comment-box class="comment-box" ng-show="isExpanded"/>'
        );
        const commentBoxForThisField = $compile(commentBox)(scope);
        const grandparentEl = $element.parent().parent()[0];
        let $parentToAppend;

        if (
          grandparentEl.hasAttribute('group-wrapper') ||
          grandparentEl.hasAttribute('collection-wrapper')
        ) {
          $parentToAppend = $element.parent().parent();
        } else {
          $parentToAppend = $element.parent();
        }
        $parentToAppend.append(commentBoxForThisField);

        scope.$on('$destroy', function () {
          commentBoxForThisField.remove();
        });
      },
    };
  });
