'use strict';

angular.module('tailor').directive('number', function NumberDirective() {
  return {
    restrict: 'A',
    require: ['ngModel'],
    link: function postLink($scope, $element, $attrs, ctrl) {
      const ngModel = ctrl[0];

      ngModel.$parsers.push(function (value) {
        let parsed = value.trim().replace(/,/g, '');
        parsed = parseFloat(parsed);

        if (isNaN(parsed)) {
          return value;
        } else {
          return parsed;
        }
      });

      ngModel.$validators.number = function (modelValue /* , viewValue*/) {
        if (typeof modelValue === 'undefined' || modelValue === '') {
          return true; // defer to `required`
        } else {
          // eslint-disable-next-line eqeqeq
          return parseFloat(modelValue) == modelValue;
        }
      };
    },
  };
});
