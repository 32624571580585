'use strict';

angular
  .module('tailor')
  .factory(
    'moduleService',
    function ModuleService($http, $q, API, debounceHttpService, unitService) {
      function get(moduleId) {
        return $http.get(API.base + '/modules/' + moduleId).then(function (response) {
          const module = response.data;
          // filter out deleted modules
          module.units = _.filter(module.units, { deleted: false });
          return module;
        });
      }

      function update(module, data) {
        return debounceHttpService
          .patch(API.base + '/modules/' + module.id, data)
          .then(function () {
            return module;
          });
      }

      function upload(module, file) {
        const csvData = new FormData();
        csvData.append('file', file);

        return debounceHttpService
          .patch(API.base + '/modules/' + module.id + '/units', csvData, {
            transformRequest: angular.identity,
            headers: {
              'Content-Type': undefined,
            },
          })
          .then(function () {
            return module;
          });
      }

      function downloadCSV(module, unit, collection, path) {
        return $http
          .get(
            API.base +
              '/modules/' +
              module.id +
              '/units/data?unit=' +
              unit.name +
              '&path=' +
              path +
              '&format=text/csv'
          )
          .then(function () {
            return module;
          });
      }

      function reorderUnits(module, unitsInOrder) {
        module.units.splice(0, module.units.length);
        module.units.push(...unitsInOrder);

        const data = { order: _.map(unitsInOrder, 'id') };

        return $http
          .post(API.base + '/modules/' + module.id + '/units/reorder', data)
          .then(function () {
            return module;
          });
      }

      function createZipList(module, data) {
        return $http
          .post(API.base + '/modules/' + module.id + '/zips', data)
          .then(function (response) {
            return response.data;
          });
      }

      function getZipList(module, zipListId) {
        return $http
          .get(API.base + '/modules/' + module.id + '/zips/' + zipListId)
          .then(function (response) {
            return response.data;
          });
      }

      function getPrevalidatedLabels(modules, configuration) {
        const moduleLabels = [];

        _.forEach(modules, function (module) {
          const label = _.find(module.definition.labels, {
            type: 'module-category',
          });

          if (label) {
            moduleLabels.push(label);
          }

          _.forEach(module.Units, function (unit) {
            unitService.prevalidate(unit, configuration.customerTier);
          });
        });

        return moduleLabels;
      }

      function getSortedLabelsByIdOrder(moduleLabels) {
        return _.sortBy(_.uniqBy(moduleLabels, 'id'), 'order');
      }

      return {
        createZipList,
        downloadCSV,
        get,
        getPrevalidatedLabels,
        getSortedLabelsByIdOrder,
        getZipList,
        reorderUnits,
        update,
        upload,
      };
    }
  );
