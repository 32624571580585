'use strict';

angular
  .module('tailor')
  .controller(
    'EditCustomerCtrl',
    function EditCustomerCtrl(
      $scope,
      $state,
      $q,
      customer,
      customerService,
      MAX_CUSTOMER_PATH_LENGTH
    ) {
      $scope.customer = customer;
      $scope.editing = true;
      $scope.MAX_CUSTOMER_PATH_LENGTH = MAX_CUSTOMER_PATH_LENGTH;

      $scope.returnCharsRemaining = function (urlPathLength) {
        return Math.max(MAX_CUSTOMER_PATH_LENGTH - urlPathLength, 0);
      };

      function validateCustomerSlug(slug, field) {
        $scope.form[field].$setValidity('slugValid', true);
        $scope.form[field].$setValidity('slugAvailable', true);

        let isValidSoFar = true;
        if (!slug || !slug.length) {
          isValidSoFar = false;
        } else if (
          field === 'customerPath' &&
          slug.length > MAX_CUSTOMER_PATH_LENGTH
        ) {
          isValidSoFar = false;
        }

        if (!isValidSoFar) {
          $scope.form[field].$setValidity('slugValid', isValidSoFar);
          return $q.when($scope.form[field].$valid);
        } else {
          $scope.form[field].$setValidity('slugValid', true);
          return customerService
            .checkSlugAvailability(slug, field, $scope.customer.id)
            .then(function (result) {
              // it's possible that the field has changed
              // while this request was running over the network
              // only update the validity if the tested slug matches the current value
              if ($scope.form[field].$modelValue === slug) {
                $scope.form[field].$setValidity('slugAvailable', result);
                return $scope.form[field].$valid;
              }
            });
        }
      }
      $scope.$watch('customer.customerPath', function (slug) {
        validateCustomerSlug(slug, 'customerPath');
        if (slug === '') {
          $scope.form.customerPath.$setPristine(true);
        }
      });
      function onFieldChange(event, field, value) {
        const fieldName = field.split('.')[1];
        customerService.update($scope.customer, fieldName, value);
      }
      $scope.$on('field:change', onFieldChange);
    }
  );
