'use strict';
/*
* This should never be called directly, and should be interfaced with the AnalyticsService
*/
angular.module('tailor')
  .factory('googleAnalytics', function GoogleAnalyticsService($window) {
    const ga = $window.ga || function() {};

    function setGlobalProperties(properties) {
      _.each(properties, function(propValue, propKey) {
        ga('set', propKey, propValue);
      });
    }

    function send(type, properties) {
      ga('send', type, properties);
    }

    function addAction(properties) {
      _.defaults(properties, {
        eventCategory: 'Action',
        eventAction: 'unknown'
      });

      send('event', properties);
    }

    function addError(message) {
      const properties = {
        exDescription: message,
        exFatal: true
      };

      send('exception', properties);
    }

    function pageview() {
      send('pageview', {});
    }

    return {
      pageview,
      addAction,
      addError,
      setGlobalProperties
    };
  });

ga('create', 'UA-52220157-3', 'auto');
ga('send', 'pageview');
