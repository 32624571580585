'use strict';

angular
  .module('tailor')
  .controller('SectionCtrl', function SectionCtrl(
    $scope,
    $parse,
    $window,
    configuration,
    module,
    unit,
    section,
    uploadCSVModal,
    unitService, /* , customerService*/
    moduleService
  ) {
    function onFieldChange(event, ngModelName, newValue, newValidity) {
      if (_.includes(ngModelName, 'model')) {
        const fieldTokens = ngModelName.split('.');
        const fieldName = fieldTokens[1];
        const updates = { data: {} };
        updates.data[fieldName] = {
          value: newValue,
          valid: newValidity,
        };

        // see the comment from unit-ctrl
        // "our router setup is isolating two different scopes in our app"
        // scope manipulation nonsense;
        const module = _.find($scope.configuration.Modules, {
          name: $scope.module.name,
        });
        const unitOnScope = _.find(module.Units, { id: $scope.unit.id });
        _.merge(unitOnScope.data[fieldName], updates.data[fieldName]);

        unitService.update($scope.unit, updates).then(function() {
          if (fieldName === 'benefits_counselor_url_group') {
            $scope.$broadcast('bcUrlGroupChanged');
          }
        });
      }
    }
    function onCollectionAddItem(event, collection, path) {
      return unitService
        .addCollectionItem($scope.unit, collection, path)
        .then(function(collection) {
          $scope.$broadcast('addedCollectionItem', collection, path);
        });
    }
    function onCollectionDeleteItem(event, collection, path) {
      return unitService
        .deleteCollectionItem($scope.unit, collection, path)
        .then(function() {
          $scope.$broadcast('removedCollectionItem', path);
        });
    }

    function onCollectionUploadCSV() {
      uploadCSVModal.activate({
        module: $scope.module
      });
    }

    function onDownloadCSV($event, collection, path) {
      return unitService.downloadCSV($scope.unit, collection, path);
    }
    function onDownloadCSVForAllUnits($event, collection, path) {
      return moduleService.downloadCSV($scope.module, $scope.unit, collection, path);
    }

    function onFileUpload(event, field, file) {
      const fieldName = field.split('.')[1];
      const updates = { data: {} };
      $scope.unit.data[fieldName].value.fileName = file.name;
      updates.data[fieldName] = $scope.unit.data[fieldName];
      const fieldDefinition = _.find($scope.unit.definition.children, function(child) {
        return child.name === fieldName;
      });
      const useLegacyFileStructure = fieldDefinition.useLegacyFileStructure || false;
      return unitService
        .uploadFile($scope.unit, updates, file, {
          useLegacyFileStructure,
        })
        .then(function() {
          return file;
        });
    }

    function onFileDownload(event, field) {
      const fieldName = field.split('.')[1];
      unitService.getFileURL($scope.unit.id, fieldName).then(function(fileURL) {
        $window.location.href = fileURL;
      });
    }

    function onFileDelete(event, field) {
      const fieldName = field.split('.')[1];
      $scope.model[fieldName].value = {
        mimetype: null,
        extension: null,
        fileName: null,
        s3Path: null,
        filesize: null,
        dimensions: {
          width: null,
          height: null,
        }
      };
      return unitService.deleteFile($scope.unit.id, fieldName);
    }

    function updateSectionValidity(newValidity, oldValidity) {
      if (newValidity !== oldValidity) {
        $scope.section.valid = newValidity;
        $scope.$emit('section:validChange', $scope.section, $scope.module, $scope.unit);
      }
    }

    /* #techdebt
    similar to comment in unit-ctrl.js...
    these two listeners below are repeated from the UnitCtrl
    because the configuration.publishing state (see router.js)
    does not share the UnitCtrl higher up on the scope
    so the listeners in the UnitCtrl do not get fired
    and do not update the unit/module validity properly
    */
    $scope.$on('section:validChange', function(event, section, module, unit) {
      if ($scope.section.name === 'Publishing') {
        const priorUnitValidity = $scope.unit.data.valid;
        const unitOnParentScope = _.find($scope.module.Units, { id: unit.id });
        const unitSection = _.find(unitOnParentScope.sections, { name: section.name });

        unitSection.valid = section.valid;
        unitOnParentScope.data.valid = $scope.unit.data.valid = section.valid;
        if (priorUnitValidity !== $scope.unit.data.valid) {
          $scope.$emit('unit:validChange', $scope.unit);
        }
      }
    });
    $scope.$on('unit:validChange', function(/* event, unit*/) {
      if ($scope.section.name === 'Publishing') {
        const priorModuleValidity = $scope.module.valid;
        $scope.module.valid = _.every($scope.module.Units, function(u) {
          return u.data.valid;
        });
        if (priorModuleValidity !== $scope.module.valid) {
          $scope.$emit('module:validChange', $scope.module);
        }
      }
    });

    function onFieldAddComment(event, path, comment, commentsArray) {
      const commentData = {
        field: path,
        comment,
      };
      return unitService
        .addComment(unit, commentData)
        .then(function(newComment) {
          commentsArray.push(newComment.data);
          $scope.unit.comments.push(newComment.data);
          $scope.module.commentCount += 1;
          const unitId = $scope.unit.id;
          const sectionName = $scope.section.name;
          for(let i = 0; i < $scope.module.Units.length; i++) {
            if($scope.module.Units[i].id === unitId) {
              $scope.module.Units[i].commentCount += 1;
              for(let j = 0; j < $scope.module.Units[i].sections.length; j++) {
                if($scope.module.Units[i].sections[j].name === sectionName) {
                  $scope.module.Units[i].sections[j].commentCount += 1;
                }
              }
            }
          }
          $scope.unit.commentCount += 1;
          $scope.section.commentCount += 1;
        });
    }

    $scope.configuration = configuration;
    $scope.module = module;
    $scope.unit = unit;
    $scope.section = section;
    $scope.model = section.data;

    $scope.$on('field:addComment', onFieldAddComment);
    $scope.$on('field:change', onFieldChange);
    $scope.$on('field:upload', onFileUpload);
    $scope.$on('field:delete', onFileDelete);
    $scope.$on('field:download', onFileDownload);
    $scope.$on('collection:addItem', onCollectionAddItem);
    $scope.$on('collection:deleteItem', onCollectionDeleteItem);
    $scope.$on('collection:upload', onCollectionUploadCSV);
    $scope.$on('collection:download', onDownloadCSV);
    $scope.$on('collection:downloadAcrossAllUnits', onDownloadCSVForAllUnits);
    $scope.$watch('form.$valid', updateSectionValidity);
  });
