'use strict';

angular.module('tailor')
  .controller('ConfigurationCtrl', function ConfigurationCtrl(
    $controller,
    $scope,
    $rootScope,
    $rootElement,
    $window,
    $http,
    $state,
    configurationService,
    generalModal,
    configuration,
    customer,
    products,
    schema,
    unitService,
    TIERS,
    TIER_CAPABILITIES,
    ownerService,
    featureFlagService
  ) {

    // Add properties to $scope for the template and child controllers
    $scope.customer = customer;
    $scope.configuration = configuration;
    $scope.configurationName = configuration.name;
    $scope.customerTier = configuration.customerTier;

    // featureFlagService available to the template
    $scope.featureFlagService = featureFlagService;

    // Check the flag state for a specific feature
    $scope.isFeatureEnabled = function (flagName) {
      return featureFlagService.get($scope.user, flagName) === 'on';
    };


    if ($scope.$root.user.role === 'partner') {
      $scope.availableTiers = Object.values(TIERS).filter(function(t) {
        return ownerService.isDIY(t) && TIER_CAPABILITIES.CURRENT_TIERS.includes(t);
      });
    } else {
      $scope.availableTiers = TIER_CAPABILITIES.CURRENT_TIERS;
    }

    if (!$scope.availableTiers.includes($scope.customerTier)) {
      $scope.availableTiers.push($scope.customerTier);
    }

    const brokerProducts = ['benefits_counselor_evergreen', 'benefits_sneak_peek', 'newhire', 'medicare'];
    if ($scope.$root.user.role === 'broker') {
      $scope.products = products.filter(function(p) {
        return brokerProducts.includes(p.productKey);
      });
    } else {
      $scope.products = products;
    }
    $scope.schema = schema;

    // inject smaller, compartmentalized ctrls
    $controller('LockCtrl', { $scope });
    $controller('PublishCtrl', { $scope });

    $scope.$on('module:validChange', function(/* event, module*/) {
      $scope.configuration.valid = _.every($scope.configuration.Modules, { valid: true });
    });

    $scope.saveEdits = function(config) {
      if (config.name !== $scope.configurationName) {
        return configurationService
          .update(config, { name: config.name })
          .then(function(newConfig) {
            $scope.configurationName = newConfig.name;
            config.editing = false;
          });
      }
      config.editing = false;
    };

    $scope.updateConfigurationTier = function(config) {
      if (config.customerTier !== $scope.customerTier) {
        return configurationService
          .update(config, { customerTier: config.customerTier })
          .then(function(newConfig) {
            $scope.customerTier = newConfig.customerTier;
          });
      }
    };

    $scope.showConfiguring = function() {
      if (!$state.includes('configuration.module.unit')) {
        const module = _.find($scope.configuration.Modules, { active: true });
        if (!module) {
          generalModal.activate({
            headerText: 'Not quite yet!',
            text: 'You must turn on a product and module before you can configure stuff!',
            okText: 'Oh right!',
            confirm: function() {
              $state.go('configuration.setup', {
                configurationId: $scope.configuration.id,
              });
            },
          });
        }
        const unitName = _.first(module.Units).name;
        const unitId = _.first(_.filter(module.Units, { name: unitName })).id;
        $state.go('configuration.module.unit', {
          configurationId: $scope.configuration.id,
          moduleName: module.name,
          unitName,
          unitId,
        });
      }
    };

    $scope.unitDisplayName = unitService.getUnitDisplayName;

    $scope.canSeeSection = function(section) {
      return configurationService.canSeeSection($scope.configuration, section, $scope.customerTier, $scope.user);
    };

    // todo:
    // if we continue to use the right side panel
    // then the following tab lines and the html from configuration.html
    // should all be moved to a directive
    $scope.activeTab = null;
    $scope.sidebarActive = false;
    $scope.$root.showPanel = function(tab, localScope) {
      $scope.activeField = {
        definition: localScope.definition,
        value: localScope.value
      };

      $scope.sidebarActive = true;
      $scope.activeTab = tab;
    };
    $scope.changeTab = function(tab) {
      $scope.activeTab = tab;
    };
    $scope.hideSidebar = function() {
      $scope.sidebarActive = false;
      $scope.activeTab = null;
    };

    $scope.encyclopediaActive = false;
    $scope.$root.showEncyclopedia = function(definition) {
      $scope.encyclopediaField = {
        definition,
        id: definition.encyclopediaId,
      };
      $scope.encyclopediaActive = true;
    };
    $scope.hideEncyclopedia = function() {
      $scope.encyclopediaActive = false;
    };

    var toggles = $rootScope.toggles = {
      'expanded-side-nav': true, // toggleExpandedSideNav
    };
    _.forOwn(toggles, function(defaultValue, toggle) {
      var name = _.startCase(toggle).replace(/\s/g, '');
      var toggleFn = 'toggle' + name;
      $rootScope[toggleFn] = function _toggleFn(val) {
        if (typeof val !== 'undefined') {
          toggles[toggle] = val;
        } else {
          toggles[toggle] = !toggles[toggle];
        }
        $window.localStorage.setItem('layout' + name, toggles[toggle]);
        if (toggles[toggle]) {
          $rootElement.addClass(toggle);
        } else {
          $rootElement.removeClass(toggle);
        }
      };
      var lastValue = $window.localStorage.getItem('layout' + name);
      if(typeof lastValue !== 'undefined') {
        toggles[toggle] = JSON.parse(lastValue);
      } else {
        toggles[toggle] = defaultValue;
      }
      $rootScope[toggleFn](toggles[toggle]);
    });

    // if window is shrunk from wide to narrow, automatically collapse side nav for the user
    var oldWidth = $window.innerWidth;
    angular.element($window).bind('resize', function() {
      var newWidth = $window.innerWidth;
      if (oldWidth > 1000 && newWidth < oldWidth) {
        $rootScope.toggleExpandedSideNav(false);
      }
      oldWidth = newWidth;
    });
  });