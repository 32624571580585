'use strict';

/**
 * @description The `field-read-only` directive contains the bulk of the
 * templating logic for the html export process.
 * See the return of `getRenderers()` to see which fields are explicitly handled here.
 * See docs/html-export.md for more.
 */
angular
  .module('tailor')
  .directive(
    'fieldReadOnly',
    function fieldReadOnly(
      fieldTemplateReadOnlyService,
      ownerService,
      templateService
    ) {
      // list of field types handled by field-template-read-only
      const templates = ['date', 'label', 'richtext', 'text'];

      const createTag = templateService.createTag;

      const outerTag = createTag('div', '', {
        className: 'field-read-only-field',
      });

      function createValueTag(text) {
        return createTag('span', text, {
          className: 'text-underline',
        });
      }

      /**
       * In order to render, much of `compile` context is needed.
       * @example var renderer = getRenderers($scope, $attrs, $element)[definition.type];
       * @param {Object} scope from compile
       * @param {Object} attrs from compile
       * @param {Object} element from compile
       * @returns {Object} { [field type]: rendererFunction }
       */
      function getRenderers(scope, attrs, element) {
        const $scope = scope;
        const definition = $scope.definition || {};
        const fieldName = definition.name;
        const $element = element;
        const $attrs = attrs;

        const unitDataPath =
        $attrs.path !== 'false' ? $attrs.path : definition.name;

        const unitValue = _.get($scope.model, unitDataPath).value;

        return {
          collection: function () {
            const sortedCollection = _.sortBy(unitValue, 'id');

            const cTemplate = [];

            // outer loop: collectionItem
            _.each(sortedCollection, function (item, idx) {
              const collectionLabel = [
                'Collection ',
                idx + 1,
                ': ',
                item.name.value,
              ].join('');

              const labelTag = createTag('div', collectionLabel, {
                className: 'field-read-only-collection-item-label',
              });

              const $itemWrapper = angular
                .element('<div></div>')
                .addClass('field-read-only-collection-item')
                .append(labelTag);

              const cItems = [];

              // inner loop: collectionItem fields
              _.each($scope.definition.children, function (childField) {
                const itemData =
                  fieldName + '.value[' + idx + '].' + childField.name;

                cItems.push(
                  fieldTemplateReadOnlyService.build(childField, {
                    collectionLength: sortedCollection.length,
                    unitData: itemData,
                    className: 'field-read-only-collection-item-field',
                  })
                );
              });

              // final step of outer loop: create collection item hierarchy
              $itemWrapper.append(
                angular
                  .element('<div></div>')
                  .addClass('field-read-only-collection-item-fields')
                  .append(cItems)
              );

              cTemplate.push($itemWrapper);
            });

            $element.append(cTemplate).addClass('field-read-only-collection');
          },

          dropdown: function () {
            let dropdownValueText = _.find(
              $scope.definition.attributes.options,
              function (text) {
                return text.value === unitValue;
              }
            );

            dropdownValueText =
              dropdownValueText == null ? '^^' : dropdownValueText.text;

            const dropdownValueTag = createValueTag(dropdownValueText);
            $element.append(
              outerTag,
              templateService.getFieldText($scope.definition.displayText, dropdownValueTag)
            );
          },

          eligibility: function () {
            let availability = unitValue.available_to_all;

            // premium availability is calculated on front end
            if (
              $scope.definition.section === 'Premiums' &&
              availability === true
            ) {
              const collectionLength = Number($attrs.collectionLength);
              availability = collectionLength <= 1;
            }

            const availabilityValueTag = createValueTag(
              availability === true ? 'is' : 'is not'
            );

            const availabilityTag = 'This '+ availabilityValueTag + ' available to all employees.';

            const configQuestions = $scope.configuration.Questions;

            const questions = _.reduce(
              unitValue.associated_questions,
              function (acc, question) {
                // question: { id: Number, answers: ArrayOf({ id: Number }) }

                const qDefinition = _.find(configQuestions, {
                  id: question.id,
                });

                const textTag = createTag('span', qDefinition.text, {
                  className: 'text-bold',
                });
                const clarificationTag = qDefinition.clarification
                  ? createTag('span', qDefinition.clarification)
                  : '';
                const questionLabel = createTag(
                  'div',
                  textTag + clarificationTag
                );

                let selectedAnswers;

                // qDefinition.answerType - one of 'choice' | 'range' | 'zip'
                if (qDefinition.answerType === 'zip') {
                  const inclusiveLabel = createValueTag(
                    question.inclusive ? 'lives' : 'does not live'
                  );
                  const fileName = createValueTag(question.fileName);
                  selectedAnswers =
                    'Employee ' +
                    inclusiveLabel +
                    ' in one of the zip codes listed in ' +
                    fileName;
                } else if (qDefinition.answerType === 'range') {
                  // {Object} question.range: { min_value, max_value, operator ('between', etc)}
                  const range = question.range;
                  const rangeValue = range.operator === 'less than' ? range.max_value : range.min_value;
                  let valuesInRange = createValueTag(rangeValue);

                  if (range.operator === 'between') {
                    valuesInRange = [
                      valuesInRange,
                      'and',
                      createValueTag(range.max_value),
                    ].join(' ');
                  }
                  selectedAnswers = [
                    createValueTag(range.operator),
                    ' ',
                    valuesInRange,
                    '.',
                  ].join('');
                } else {
                  // qDefinition.Choices - Array of { id: 971, value: "Full-time", etc}
                  selectedAnswers = _.reduce(
                    qDefinition.Choices,
                    function (choicesAcc, choice) {
                      const foundAnswer = _.find(question.answers, {
                        id: choice.id,
                      });
                      if (foundAnswer) {
                        choicesAcc.push(createTag('div', choice.value));
                      }
                      return choicesAcc;
                    },
                    []
                  ).join('');
                }

                const answers = createTag('div', selectedAnswers);

                acc.push(
                  createTag('div', questionLabel + answers, {
                    className: 'field-read-only-eligibility-question',
                  })
                );
                return acc;
              },
              []
            );

            const eligibilityHeader = createTag('h6', 'Eligibility', {
              className: 'field-read-only-eligibility-header',
            });
            const $questionsElement = angular
              .element('<div></div>')
              .append(questions)
              .addClass('field-read-only-eligibility-question-wrapper');

            const $eWrapper = angular
              .element(outerTag)
              .append(eligibilityHeader, availabilityTag, $questionsElement);

            $element.append($eWrapper);
            return;
          },

          file: function () {
            let fileText = 'null';

            if (unitValue != null) {
              if (typeof unitValue === 'string') {
                fileText = unitValue;
              } else if (typeof unitValue === 'object') {
                fileText = unitValue.fileName || unitValue.s3Path || 'null';
              }
            }

            if (fileText.length > 35) {
              fileText = fileText.slice(0, 35) + '...';
            }

            const fileValueTag = createValueTag(fileText);
            $element.append(
              outerTag,
              templateService.getFieldText($scope.definition.displayText, fileValueTag)
            );
          },

          group: function () {
            const childTags = _.map($scope.definition.children, function (childField) {
              return fieldTemplateReadOnlyService.build(
                childField,
                {
                  className: 'field-read-only-group-field',
                }
              );
            });

            const wrapper = angular
              .element('<div></div>')
              .addClass('field-read-only-group-wrapper')
              // here we add on a directive that rebinds the model to the value of the group.
              // This is necessary because we'll also be utilizing the 'dependent'.  It is
              // expecting that the value of 'model' will be not be the unit, but will be
              // the group within the unit.
              .attr('one-time-model', 'model.' + fieldName + '.value')
              .append(childTags);

            $element
              .append(wrapper);
          },

          picker: function () {
            // see directives/fields/picker.js - this was lifted from there
            const referencedCollection = $scope.definition.collection;

            let referencedModule; // analogous to `currentModule` in dependency.js
            if (referencedCollection.module) {
              referencedModule = _.find($scope.configuration.Modules, {
                name: referencedCollection.module,
              });
            } else {
              referencedModule = $scope.module;
            }
            let associatedCollection; // analogous to `currentUnit` in dependency.js
            if (referencedCollection.settings) {
              associatedCollection = _.find(referencedModule.Units, {
                name: 'settings',
              });
            } else {
              associatedCollection = $scope.unit.data;
            }
            if (referencedCollection.unit) {
              associatedCollection = _.filter(referencedModule.Units, {
                name: referencedCollection.unit,
              });
              associatedCollection = _.map(
                associatedCollection,
                function (unit) {
                  return _.assign({ id: unit.id }, unit.data);
                }
              );
            } else if (referencedCollection.field) {
              if (referencedCollection.group) {
                associatedCollection =
                  associatedCollection[referencedCollection.group].value[
                    referencedCollection.field
                  ].value;
              } else {
                associatedCollection =
                  associatedCollection[referencedCollection.field].value;
              }
            }

            let associatedCollectionValue = JSON.stringify(unitValue);
            if (associatedCollection.length > 0) {
              associatedCollectionValue = _.map(
                associatedCollection,
                function (item) {
                  let label = item.name;
                  if (label.value) {
                    label = label.value;
                  }
                  if (referencedCollection.unit) {
                    const unit = _.find(referencedModule.Units, { id: item.id });
                    label = $scope.unitDisplayName(unit);
                  }
                  return label
                    ? createTag('div', createValueTag(label))
                    : JSON.stringify(item);
                }
              ).join('');
            }

            const $pickerWrapper = angular
              .element(outerTag)
              .append(
                templateService.getFieldText($scope.definition.displayText, associatedCollectionValue)
              );

            $element.append($pickerWrapper);
          },

          toggle: function () {
            const valueText =
              unitValue === true
                ? $scope.definition.attributes.onLabel
                : $scope.definition.attributes.offLabel;

            const toggleValueTag = createValueTag(valueText);
            const fieldText = templateService.getFieldText($scope.definition.displayText, toggleValueTag);

            $element.append(
              outerTag,
              fieldText
            );
          },
        };
      }

      return {
        restrict: 'A',
        priority: 30000,
        scope: true,
        replace: true,
        controller: function fieldReadOnlyCtrl(
          $scope,
          $rootScope,
          // $element,
          $attrs /* , $transclude*/
        ) {
          $scope.definition = $scope.$eval($attrs.fieldReadOnly);
          this.definition = $scope.definition || {};
          $scope.user = $rootScope.user;
        },
        compile: function ($element, $attrs /* ,  transclude */) {
          return {
            pre: function ($scope) {
              const definition = $scope.definition;
              const customerTier = (
                ($scope.$parent.configuration || {}).customerTier || ''
              ).toLowerCase();
              const serviceLevels = definition.serviceLevels || [];
              const showToUser = ownerService
                .shouldShowFieldForRole(definition, $scope.user.role, customerTier);
              definition.attributes = definition.attributes || {};

              // extract all this into field util someday

              if (
                definition.attributes.hiddenToUI ||
                !showToUser ||
                !_.includes(
                  serviceLevels || [],
                  ownerService.lookupServiceLevel(customerTier).toLowerCase()
                ) ||
                (definition.ownerOnly && !ownerService.isOwner(customerTier))
              ) {
                // ng-hide adds watchers and doesn't get applied correctly
                // because there's no http call made.
                $element.hide();
                return;
              }

              if (_.includes(templates, definition.type)) {
                return; // already handled
              }

              const renderer = getRenderers($scope, $attrs, $element)[definition.type];

              if (!renderer) {
                // todo: better handling
                console.error('renderer type', definition.type, 'not found.');
                return;
              }

              return renderer();
            },
          };
        },
      };
    }
  );
