'use strict';
angular
  .module('tailor')
  .directive('dependent', function Dependent(
    $animate,
    dependencyService,
    dependencyHacks
  ) {
    return {
      transclude: 'element',
      priority: 20000,
      terminal: true,
      restrict: 'A',
      $$tlb: true,
      require: ['dependent', '?field', '?fieldReadOnly'],
      controller: function controller($scope /* , $element, $attrs*/) {
        function onUpdateInclusion(field, callback) {
          const orDependencies = field.dependencies;
          // update visibility in response to model change
          if (orDependencies && orDependencies.length) {
            _.each(orDependencies, function(andDependencies) {
              _.each(andDependencies, function watchField(andDependency) {
                let dependee;
                if (andDependency.group) {
                  dependee =
                    'model.' +
                    andDependency.group +
                    '.value.' +
                    andDependency.field +
                    '.value';
                } else {
                  dependee = 'model.' + andDependency.field + '.value';
                }
                let scopeToWatch = $scope;
                if (andDependency.parent) {
                  for (let i = 0; i < andDependency.parent; i++) {
                    // scopes to jump up
                    // = dependent, field, group|collection
                    // todo: we should figure out how to make this not use angular scopes
                    // look up the scope tree until we find the field
                    // hard coded limit of 10 $parent lookups
                    let j = 0;
                    while (j < 10) {
                      if (scopeToWatch.$parent) {
                        scopeToWatch = scopeToWatch.$parent;
                        if (scopeToWatch.model && scopeToWatch.model[andDependency.field]) {
                          break;
                        }
                      } else {
                        break;
                      }
                      j++;
                    }
                  }
                }
                scopeToWatch.$watch(dependee, function() {
                  // wait one cycle, allowing for other field updates to process
                  // (see "won't save" in ALEX-1920)
                  _.delay(callback, 1);
                });
              });
            });
          }
        }
        this.onUpdateInclusion = onUpdateInclusion;
      },
      link: function postLink($scope, $element, $attrs, ctrl, $transclude) {
        const dependentCtrl = ctrl[0];
        let block, childScope;
        const field = (ctrl[1] || ctrl[2]).definition;

        function updateInclusion() {
          const include = dependencyService.evaluate(
            field,
            $scope.configuration,
            $scope.module, // current module
            $scope.unit, // current unit
            $scope.model // current local data object
          );
          const hackInclude = dependencyHacks.evaluate(
            field,
            $scope.configuration,
            $scope.module,
            include
          );

          // lifted wholesale from the definition of ngIf
          if (include && hackInclude) {
            if (!childScope) {
              childScope = $scope.$new();
              $transclude(childScope, function(clone) {
                // Note: We only need the first/last node of the cloned nodes.
                // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                // by a directive with templateUrl when its template arrives.
                block = {
                  clone,
                };

                $animate.enter(clone, $element.parent(), $element);
              });
            }
          } else {
            if (childScope) {
              childScope.$destroy();
              childScope = null;
            }

            if (block) {
              $animate.leave(getBlockElements(block.clone)).then(function() {
                // re-check if the block exists -
                // for fields with multiple dependencies,
                // the block could have been removed by a different dependency during the same digest cycle
                if(block) {
                  $scope.$apply();
                }
              });
              block = null;
            }
          }
        }

        // lifted from src/Angular.js since it's not exported as an angular.fn
        function getBlockElements(nodes) {
          const startNode = nodes[0],
            endNode = nodes[nodes.length - 1];
          if (startNode === endNode) {
            return $(startNode);
          }

          let element = startNode;
          const elements = [element];

          do {
            element = element.nextSibling;
            if (!element) {
              break;
            }
            elements.push(element);
          } while (element !== endNode);

          return $(elements);
        }

        // determine initial visibility and required state
        updateInclusion();
        dependentCtrl.onUpdateInclusion(field, updateInclusion);
      },
    };
  });
