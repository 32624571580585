'use strict';

angular.module('tailor')
  .factory('answersService', function answersService($http, API, debounceHttpService) {
    function updateAnswer(answer, field, value) {
      const data = {};
      data[field] = value;
      return debounceHttpService.patch(API.base + '/answers/' + answer.id, data);
    }
    function uploadFile(answer, file) {
      // see-also: item service;

      const formData = new FormData();
      formData.append('file', file);

      return $http.post(API.base + '/answers/' + answer.id + '/file', formData, {
        // Override $http's settings so that we don't transform the request at all or use application/json as the Content-Type.
        // In this case, it's because Content-Type needs to be multipart/form-data, which
        // has a boundary string that we can't predict to separate the parts. So we're just gonna let $http handle it.
        transformRequest: angular.identity,
        headers: {
          'Content-Type': undefined
        }
      });
    }

    function deleteFile(answer) {
      return $http.delete(API.base + '/answers/' + answer.id + '/file').then(function() {
        answer.file = null;
      });
    }

    // We're just returning the URL, because we don't actually want to do a GET on it.
    // Instead, we'll let whoever is calling this figure out how to manipulate the browser into
    // downloading the file.
    function getFileURL(answer) {
      return API.base + '/answers/' + answer.id + '/file';
    }
    return {
      updateAnswer,
      uploadFile,
      deleteFile,
      getFileURL
    };
  });
