'use strict';

angular.module('tailor')
  .factory('analytics', function AnalyticsService(googleAnalytics) {

    function addAction(properties) {
      googleAnalytics.addAction(properties);
    }

    function addError(message) {
      googleAnalytics.addError(message);
    }

    function addLogin() {
      addAction({
        eventAction: 'login'
      });
    }

    function addPageView() {
      googleAnalytics.pageview();
    }

    return {
      addAction,
      addError,
      addLogin,
      addPageView
    };
  });
