'use strict';

angular.module('tailor')
  .controller('CustomersCtrl', function CustomersCtrl($scope, $state, $q, customers, customerService, loader, deleteModal) {
    $scope.customers = customers;
    $scope.readOnly = $scope.$root.user.readOnly;

    $scope.searchTerm = null;
    $scope.matches = function(searchTerm) {
      return function(customer) {
        if(!searchTerm) {
          return true;
        }
        searchTerm = searchTerm.toLowerCase();
        const matches =
              (String(customer.id)).indexOf(searchTerm) >= 0 ||
              customer.name.toLowerCase().indexOf(searchTerm) >= 0 ||
              customer.informalName.toLowerCase().indexOf(searchTerm) >= 0;
        return matches;
      };
    };

    $scope.activeOrder = 'name';
    $scope.reverse = false;
    $scope.applyOrder = function(order) {
      if($scope.activeOrder === order) {
        $scope.reverse = !$scope.reverse;
      } else {
        $scope.activeOrder = order;
        $scope.reverse = false;
      }
    };
    $scope.orderFunc = function() {
      return function(customer) {
        return customer[$scope.activeOrder] || customer.name;
      };
    };

    $scope.confirmDeleteCustomer = function confirmDeleteCustomer(event, customer) {
      event.stopPropagation();
      deleteModal.activate({
        model: {
          customer
        },
        confirm: $scope.deleteCustomer,
        cancel: angular.noop,
        okText: 'Delete it!',
        cancelText: 'Oops, forget it.',
        headerText: 'Delete?',
        text: 'Are you sure you want to delete this customer? You will not be able to undo once deleted.'
      });
    };

    $scope.deleteCustomer = function(obj) {
      const customer = obj.customer;
      loader.open('Deleting customer');
      customerService.remove(customer.id)
        .then(function() {
          $scope.customers = _.reject($scope.customers, { id: customer.id });
          return customer;
        })
        .then(loader.close);
    };

    $scope.search = function search(searchKeyword) {
      if(!searchKeyword) {
        $scope.hasError = true;
      }else{
        $scope.searchKeyword = searchKeyword;
        customerService.search(searchKeyword)
          .then(function(res) {
            $scope.customers = res;
            $scope.dataNotFound = !res.length && true;
            return res;
          });
      }
    };
    $scope.$watch('searchTerm', function(newValue) {
      if(newValue) {
        $scope.hasError = false;
      }
    });
  });
