angular.module('tailor').directive('validateUrlSlug', function () {
  return {
    require: 'ngModel',
    link: function (scope, elm, attrs, ctrl) {
      ctrl.$validators.validateUrlSlug = function (modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue)) {
          return true;
        }
        const alreadyUsedByCustomer = _.some(
          scope.$eval(attrs.validateUrlSlug),
          function (item) {
            return item.value === viewValue;
          }
        );
        return !alreadyUsedByCustomer;
      };
    },
  };
});
