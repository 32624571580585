'use strict';

angular
  .module('tailor')
  .factory('generalModal', function (btfModal) {
    return btfModal({
      controller: 'generalModalCtrl',
      controllerAs: 'ctrl',
      templateUrl: 'views/modals/general-modal.html',
    });
  })
  .controller('generalModalCtrl', function ($scope, generalModal) {
    function cancel() {
      if (typeof $scope.cancel === 'function') {
        $scope.cancel();
      }
      generalModal.deactivate();
    }

    function ok() {
      if (typeof $scope.confirm === 'function') {
        $scope.confirm($scope.model);
      }
      generalModal.deactivate();
    }
    $scope._cancel = cancel;
    $scope._ok = ok;
  });
