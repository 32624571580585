'use strict';

angular
  .module('tailor')
  .factory('commentsService', function commentsService(
    $http,
    $q,
    API,
    debounceHttpService
  ) {
    function remove(comment) {
      return $http.delete(API.base + '/comments/' + comment.id);
    }

    function get(id) {
      return $http.get(API.base + '/comments/' + id)
        .then(function (response) {
          return response.data;
        });
    }

    function update(comment, commentData) {
      return debounceHttpService
        .patch(API.base + '/comments/' + comment.id, commentData)
        .then(function (response) {
          return response.data;
        });
    }
    function getCommentCount(data) {
      let commentCount = 0;
      if (typeof data === 'object' && data !== null) {
        if (data.comments) {
          commentCount += data.comments.filter(function (comment) {
            return !comment.deleted && !comment.resolved;
          }).length;
        }
        for (const i in data) {
          const deeperComments = getCommentCount(data[i]);
          commentCount += deeperComments;
        }
      }
      return commentCount;
    }

    return {
      remove,
      update,
      getCommentCount,
      get,
    };
  });
