'use strict';

angular.module('user')
  .constant('API', {
    domain: '//' + window.location.host,
    base: '//' + window.location.host + '/api'
  });
angular.module('tailor')
  .constant('DEBOUNCE_RATE', 1200)
  .constant('MAX_CUSTOMER_PATH_LENGTH', 45)
  .constant('TIERS', window.env.TIERS)
  .constant('TIER_CAPABILITIES', window.env.TIER_CAPABILITIES)
  .constant('SERVICE_LEVELS', window.env.SERVICE_LEVELS)
  .constant('TIER_TO_SERVICE_LEVEL_MAP', window.env.TIER_TO_SERVICE_LEVEL_MAP)
  .constant('ELIGIBILITY_REPORT_URL', window.env.ELIGIBILITY_REPORT_URL)
  .config(function disableScrolling($uiViewScrollProvider) {
    $uiViewScrollProvider.useAnchorScroll();
  })
  .config(function($animateProvider) {
    $animateProvider.classNameFilter(/animate/);
  })
  .config(function($httpProvider) {
    $httpProvider.defaults.headers.post = {
      'Content-type': 'application/json'
    };
    $httpProvider.defaults.withCredentials = true;

    // each interceptor should individually $q.reject() if the error should be caught elsewhere
    // $httpProvider.interceptors.push('httpErrorLogger'); temporarily removed.
    $httpProvider.interceptors.push('progressInterceptor');
    $httpProvider.interceptors.push('httpErrorInterceptor');
    $httpProvider.interceptors.push('inUseErrorInterceptor');
    $httpProvider.interceptors.push('addHeadersInterceptor');
    $httpProvider.interceptors.push('lockedErrorInterceptor');
    $httpProvider.interceptors.push('authorizationInterceptor');
    $httpProvider.interceptors.push('fifoInterceptor');
  })
  .config(function($urlMatcherFactoryProvider) {
    $urlMatcherFactoryProvider.strictMode(false);
  })
  .config(function (JSONEditorProvider) {
    JSONEditorProvider.configure({
      defaults: {
        options: {
          iconlib: 'fontawesome4',
          theme: 'foundation5'
        }
      }
    });
  });
