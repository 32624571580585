'use strict';

angular.module('tailor')
  .factory('createConfigurationModal', function(btfModal) {
    return btfModal({
      controller: 'CreateConfigurationModalCtrl',
      controllerAs: 'ctrl',
      templateUrl: 'views/modals/create-configuration-modal.html'
    });
  })
  .controller('CreateConfigurationModalCtrl', function($scope, $state, $q, createConfigurationModal) {
    $scope.form = {
      selectedCustomerId: null,
      customerType: 'existing',
      searchTerm: null,
    };
    $scope.close = createConfigurationModal.deactivate;

    $scope.$watch('form.searchTerm', function() {
      $scope.form.selectedCustomerId = null;
    });

    $scope.matches = function(searchTerm) {
      return function(customer) {
        if(!searchTerm) {
          return true;
        }
        searchTerm = searchTerm.toLowerCase();
        const matches =
              (String(customer.id)).indexOf(searchTerm) >= 0 ||
              customer.name.toLowerCase().indexOf(searchTerm) >= 0 ||
              customer.informalName.toLowerCase().indexOf(searchTerm) >= 0;
        return matches;
      };
    };

  });
