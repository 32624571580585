'use strict';

angular
  .module('tailor')
  .controller(
    'PublishCtrl',
    function PublishCtrl(
      $scope,
      $q,
      configurationService,
      ownerService,
      btfModal,
      generalModal,
      loader,
      contentService
    ) {
      let productToPublish = null;
      let consumerToPublish = null;
      let invoice;

      function fieldsPass(consumer) {
        const fieldsToCheck = consumer.options.fields || [];
        return fieldsToCheck.every(function (field) {
          const module = $scope.configuration.Modules.find(function (m) {
            return m.name === field.module;
          });
          const unit = module.Units.find(function (u) {
            return u.name === field.unit;
          });
          return unit.data[field.name].value === field.value;
        });
      }
      function previouslyLaunched(config, productKey) {
        const previousLaunchEvents =
          config &&
          config.Events &&
          config.Events.filter(function (e) {
            return e.type === 'LAUNCH_EVENT' && e.data.productKey === productKey;
          });
        return previousLaunchEvents && previousLaunchEvents.length > 0;
      }

      $scope.consumerTense = function (consumer, productKey) {
        if (consumer.name && consumer.name.toLowerCase() === 'launch') {
          return previouslyLaunched($scope.configuration, productKey) ? 'Relaunch' : consumer.name;
        }
        return consumer.name;
      };

      function consumerIncludesRole(consumer) {
        return (consumer.options.roles || []).includes($scope.user.role);
      }

      function consumerIncludesTier(consumer) {
        const serviceLevel = ownerService.lookupServiceLevel($scope.configuration.customerTier);
        return (consumer.options.serviceLevels || []).includes((serviceLevel || '').toLowerCase());
      }

      $scope.shouldShowConsumer = function (consumer, product) {
        const orTierCheck =
          (fieldsPass(consumer) && consumerIncludesRole(consumer)) ||
          (consumerIncludesTier(consumer) && consumerIncludesRole(consumer));
        const andTierCheck =
          fieldsPass(consumer) && consumerIncludesRole(consumer) && consumerIncludesTier(consumer);
        return product.productKey === 'newhire' || product.productKey === 'benefits_sneak_peek'
          ? andTierCheck
          : orTierCheck;
      };
      $scope.checkProductStatus = function (products, productName) {
        const productSelection = _.find(products, { name: productName });
        return productSelection.active;
      };
      $scope.preview = function (product) {
        loader.open();
        let consumer;
        if (product.Consumers.length === 1) {
          consumer = product.Consumers[0];
        } else {
          consumer = product.Consumers.find(function (c) {
            return c.name === 'Publish' || c.name === 'Production';
          });
        }
        configurationService
          .previewPublishJSON($scope.configuration, consumer, product)
          .then(function (preview) {
            loader.close();
            const json = JSON.stringify(preview);
            const blob = new Blob([json], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
          });
      };

      $scope.beginPublishing = function beginPublishing(product, consumer) {
        productToPublish = product;
        consumerToPublish = consumer;

        return checkValid()
          .then(confirmSpanish)
          .then(checkLocked)
          .then(confirmInvoice)
          .then(confirmPublish)
          .then(makePublishRequest);
      };

      function checkValid() {
        loader.open('Validating configuration');
        const deferred = $q.defer();
        let cancelText;
        const productModules = $scope.configuration.Modules.filter(function (module) {
          return module.definition.Products.some(function (product) {
            return product.productKey === productToPublish.productKey;
          });
        });
        const activeProductModules = productModules.filter(function (module) {
          return module.active;
        });
        const valid = _.every(activeProductModules, { valid: true });
        if (valid) {
          deferred.resolve();
        } else {
          if ($scope.user.role === 'employee') {
            cancelText = 'publish anyway, please';
          }
          generalModal.activate({
            headerText: 'Needs a little more work...',
            text:
              "This configuration isn't 100% complete. Look for the arrows in the menu on " +
              'the left to figure out where you still need to fill in some answers.',
            okText: 'OK',
            cancelText,
            confirm: function () {
              loader.close();
              generalModal.deactivate();
              deferred.reject();
            },
            cancel: function () {
              generalModal
                .deactivate()
                .then(function () {
                  deferred.resolve();
                })
                .catch(function () {
                  deferred.resolve();
                });
            },
          });
        }
        return deferred.promise;
      }
      function checkLocked() {
        const deferred = $q.defer();
        deferred.resolve();
        // @TODO  TAILOR-29 and TAILOR-33 Validate and Lock configurations before publish
        // loader.open('Validating configuration');
        // return configurationService.validate($scope.configuration)
        //   .then(function(configuration) {
        //     loader.close();
        //     if(configuration.valid && configuration.locked) {
        //       return $q.resolve(true);
        //     } else if (configuration.valid) {
        //       generalModal.activate({
        //         headerText: 'Not quite there!',
        //         text: 'The benefit configuration isn\'t ready for publishing yet — it looks ' \
        //                 'like you still need to lock it. Would you like to lock it now?',
        //         okText: 'Lock it now and publish!',
        //         cancelText: 'No wait',
        //         confirm: function(){
        //           loader.open('Locking configuration');
        //           return configurationService.lock($scope.configuration)
        //             .then(loader.close)
        //             .then(function(){
        //               return deferred.resolve();
        //             });
        //         },
        //         cancel: function() {
        //           deferred.reject();
        //         }
        //       });
        //     } else {
        //       generalModal.activate({
        //         headerText: 'Not quite there!',
        //         text: 'The benefit configuration isn\'t ready for publishing yet — it looks ' \
        //                 'like you missed a few fields here and there. Look for the orange arrow ' \
        //                 'in the menu on the left to figure out where you still need to fill in ' \
        //                 'some information.',
        //         okText: 'Aw, man!',
        //         confirm: function(){
        //           deferred.resolve();
        //         },
        //         cancel: function(){
        //           deferred.reject();
        //         },
        //       });
        //     }
        // });
        return deferred.promise;
      }
      function confirmSpanish() {
        if (productToPublish.productKey !== 'benefits_counselor_evergreen') {
          return $q.resolve(true);
        }
        loader.open();
        const spanishModule = $scope.configuration.Modules.find(function (m) {
          return m.name === 'alex_go';
        });
        const spanishModuleActive = spanishModule.active;
        const spanishField = spanishModule.Units[0].data.alex_go_language.value;
        const spanishFieldOn = spanishField === 'spanish' || spanishField === 'english_and_spanish';
        const action = $scope
          .consumerTense(consumerToPublish, productToPublish.productKey)
          .toLowerCase();
        if (
          !spanishModuleActive ||
          !spanishFieldOn ||
          action === 'create/update portal implementation'
        ) {
          return $q.resolve(true);
        }
        const deferred = $q.defer();

        contentService.getModalMessage('alex_go_spanish').then(content => {
          generalModal.activate({
            headerText: 'Alex Go',
            text: content,
            okText: 'OK',
            cancelText: 'Oops, forget it',
            confirm: function () {
              generalModal.deactivate();
              deferred.resolve();
            },
            cancel: function () {
              loader.close();
              generalModal.deactivate();
              deferred.reject();
            },
          });
        });
        return deferred.promise;
      }
      function confirmInvoice() {
        if (
          consumerToPublish.options.requiresInvoicing &&
          ownerService.isDIY($scope.configuration.customerTier)
        ) {
          const deferred = $q.defer();

          const moduleForPublishedProduct = $scope.configuration.Modules.find(function (m) {
            return m.name === productToPublish.productKey;
          });
          const settingsUnit = moduleForPublishedProduct.Units.find(function (u) {
            return u.name === 'settings';
          }).data;
          const fields = Object.keys(settingsUnit);
          const urlGroup = fields.find(function (field) {
            return field.includes('_url_group');
          });
          const urlField = Object.keys(settingsUnit[urlGroup].value).find(function (f) {
            return f.includes('_url_path');
          });
          const urlPath = settingsUnit[urlGroup].value[urlField].value;
          const customerPath =
            'www.myalex.com/' + $scope.customer.customerPath.trim() + '/' + urlPath.trim();
          const customerName = $scope.customer.name;
          const config = $scope.configuration;

          loader.open();
          const invoiceModal = btfModal({
            templateUrl: 'views/modals/invoicing-modal.html',
            controller: [
              '$scope',
              function ($scope) {
                $scope.expirationDate = (
                  moduleForPublishedProduct.expirationDate
                    ? new Date(moduleForPublishedProduct.expirationDate)
                    : new Date(new Date().setDate(new Date().getDate() + 365))
                ).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
                $scope.customerTierDisplay =
                  config.customerTier === 'DIY' ? 'Benefits Counselor' : config.customerTier;
                $scope.customerName = customerName;
                $scope.customerPath = customerPath;
                $scope.productToPublishKey = productToPublish.productKey;
                $scope.previouslyLaunched = previouslyLaunched(config, $scope.productToPublishKey);
                $scope.cancel = function () {
                  invoiceModal.deactivate();
                  deferred.reject();
                };
                $scope.launch = function () {
                  invoiceModal.deactivate();
                  deferred.resolve();
                };
              },
            ],
          });

          const invoicingConsumer = productToPublish.Consumers.find(function (c) {
            return c.url.includes('invoicing');
          });

          return configurationService
            .getResource($scope.configuration, invoicingConsumer, productToPublish, 'invoice')
            .then(function (resource) {
              invoice = resource;
              for (const item of invoice.items) {
                item.displayName =
                  item.description === 'Benefits Counselor' &&
                  $scope.configuration.customerTier !== 'DIY'
                    ? $scope.configuration.customerTier
                    : item.description;
              }
              loader.close();
              invoiceModal.activate({
                invoice: resource,
              });
              return deferred.promise;
            });
        } else {
          return $q.when(true);
        }
      }
      function confirmPublish() {
        const deferred = $q.defer();
        const videoBuild =
          (productToPublish.productKey === 'newhire' ||
            productToPublish.productKey === 'benefits_sneak_peek') &&
          ownerService.isDIY($scope.configuration.customerTier);
        const customerName = $scope.customer.name;
        let action = $scope.consumerTense(consumerToPublish, productToPublish.productKey);
        if (videoBuild && action === 'Relaunch') {
          action = 'Launch';
        }
        if (consumerToPublish.confirmationRequired) {
          const publishConfirmModal = btfModal({
            controller: [
              '$scope',
              function ($scope) {
                $scope.consumerToPublish = consumerToPublish;
                $scope.productToPublish = productToPublish;
                $scope.videoBuild = videoBuild;
                $scope.customerName = customerName;
                $scope.action = action;
                $scope.ok = function () {
                  publishConfirmModal.deactivate();
                  deferred.resolve();
                };
                $scope.cancel = function () {
                  loader.close();
                  publishConfirmModal.deactivate();
                  deferred.reject();
                };
              },
            ],
            templateUrl: 'views/modals/publish-confirm-popup.html',
          });
          publishConfirmModal.activate();
        } else {
          deferred.resolve();
        }
        return deferred.promise;
      }
      function makePublishRequest() {
        loader.open();
        if (consumerToPublish.options.updatePortal) {
          return configurationService
            .postResource(
              $scope.configuration,
              consumerToPublish,
              productToPublish,
              'sendImplementation'
            )
            .then(function () {
              loader.close();
            });
        } else {
          return configurationService
            .publish($scope.configuration, consumerToPublish, invoice)
            .then(function (result) {
              loader.close();
              const deferred = $q.defer();
              const action = consumerToPublish.name;
              const headerText = result.headerText || action + ' Started';
              let text = result.message;
              const okText = 'Rock on!';
              if (
                ownerService.isDIY($scope.configuration.customerTier) &&
                previouslyLaunched($scope.configuration, productToPublish.productKey)
              ) {
                text = text.replace('launch', 'relaunch');
              }
              generalModal.activate({
                headerText,
                text,
                okText,
                confirm: function () {
                  deferred.resolve();
                },
                cancel: function () {
                  deferred.resolve();
                },
              });
              return deferred.promise;
            })
            .catch(function (rejection) {
              generalModal.activate({
                headerText: 'Uh oh!',
                text: 'Something went wrong' + (rejection.message ? ': ' + rejection.message : ''),
                okText: 'Oh no!',
              });
            });
        }
      }
    }
  );
