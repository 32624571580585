'use strict';

angular.module('user', ['ng'])
  .config(function($httpProvider) {
    $httpProvider.defaults.withCredentials = true;
  })
  .factory('userService', function(
    $http, $q, $window, API, $rootScope, googleAnalytics, loginCtrl, loader, featureFlagService
  ) {
    function getQueryStrings() {
      const assoc = {};
      const decode = function(s) {
        return decodeURIComponent(s.replace(/\+/g, ' '));
      };
      const currentUrl = window.location.href;
      const queryString = currentUrl.slice(currentUrl.indexOf('?') + 1);
      const keyValues = queryString.split('&');

      for(const i in keyValues) {
        const key = keyValues[i].split('=');
        if(key.length > 1) {
          assoc[decode(key[0])] = decode(key[1]);
        }
      }
      return assoc;
    }

    function getToken() {
      const deferred = $q.defer();
      $rootScope.auth.userhandler = {
        onSuccess: function(token) {
          deferred.resolve(token);
        },
        onFailure: function(err) {
          deferred.reject('Invalid token', err);
        }
      };
      $rootScope.auth.getSession();
      return deferred.promise;
    }

    // successful cognito login redirects to a url with the code as a query
    // param `parseCognitoWebResponse` parses the url, does code exchange and
    // then runs the onSuccess callback in onSuccess, we remove the `code` query
    // param, simplifying the url back to '/#/configurations'
    function parse() {
      $rootScope.auth.setState(window.location.href);
      if(window.location.search.indexOf('code=') > -1) {
        const curUrl = window.location.href;

        const deferred = $q.defer();
        $rootScope.auth.userhandler = {
          onSuccess: function() {
            window.location = getQueryStrings().state;
          },
          onFailure: function(err) {
            deferred.reject('Invalid authorization code', err);
          }
        };
        $rootScope.auth.parseCognitoWebResponse(curUrl);
        return deferred.promise;
      } else {
        return $q.when();
      }
    }

    // the cognito library is config based, not callback based
    // and only supports one callback at a time. #womp;
    // so, to get around this,
    // make sure we only make one refresh attempt at a time
    // i.e. memoize incomplete refreshes till they're finished `currentRefresh`
    let currentRefresh = null;
    function refresh() {
      if (currentRefresh) {
        return currentRefresh;
      } else {
        localStorage.setItem('loginRedirect', window.location.pathname + window.location.hash);
        const deferred = $q.defer();
        $rootScope.auth.userhandler = {
          onSuccess: function() {
            deferred.resolve();
          },
          onFailure: function(err) {
            deferred.reject('Logged out', err);
            const url = $rootScope.auth.getFQDNSignIn();
            const storageKey = 'CognitoIdentityServiceProvider.' + $rootScope.auth.clientId;
            for (const key in localStorage) {
              if (key.indexOf(storageKey) >= 0) {
                localStorage.removeItem(key);
              }
            }
            loader.close();
            loginCtrl.activate({
              url,
              errorTitle: 'Logged out',
              errorMessage: 'You have been automatically logged out after 30 days. Please log '
                            + 'back in!',
              errorButton: 'Sign in again'
            });
          }
        };
        const refreshToken = $rootScope.auth.signInUserSession.refreshToken.refreshToken;
        $rootScope.auth.refreshSession(refreshToken);
        currentRefresh = deferred.promise;
        return currentRefresh
          .finally(function() {
            currentRefresh = null;
          });
      }
    }

    function getUser() {
      return parse()
        .then(getToken)
        .catch(function() {
          return refresh();
        })
        .then(function() {
          return $http.get(API.base + '/users');
        })
        .then(function(response) {
          const user = new User(response.data);
          window.heap.identify(user.email, 'email');
          window.heap.addUserProperties({
            Organization: user.organizationName,
            Group: user.role
          });
          if (!localStorage.getItem('loginDate')) {
            localStorage.setItem('loginDate', new Date());
          }
          $rootScope.user = user;
          return user;
        })
        .then(function(user) {
          setGlobalProperties(user);
          return user;
        })
        .then(function(user) {
          featureFlagService.cacheAll(user);
        })
        .then(function() {
          const loginRedirect = localStorage.getItem('loginRedirect') || '';
          if (loginRedirect && loginRedirect !== '') {
            localStorage.setItem('loginRedirect', '');
            window.location = location.origin + '/' + loginRedirect.replace(/(^\s*\/)/g, '');
          }
        })
        .catch(function() {
          localStorage.setItem('loginRedirect', window.location.pathname + window.location.hash);
          const url = $rootScope.auth.getFQDNSignIn();
          loginCtrl.activate({
            url,
            errorTitle: 'Something went wrong!',
            errorMessage: 'Well that didn\'t work. Double check your credentials, and if you still '
                          + 'have trouble please let your friends at Jellyvision know.',
            errorButton: 'Try Again'
          });
        });
    }

    function logOut() {
      window.heap.resetIdentity();
      $rootScope.auth.signOut();
    }

    function setGlobalProperties(user) {
      const properties = {
        dimension1: user.josefServiceLevel,
        dimension2: user.organizationName,
        appName: 'Tailor'
      };

      properties.dimension4 = '1.0.0';
      properties.appVersion = '1.0.0';
      googleAnalytics.setGlobalProperties(properties);
    }

    function parseIntoPhoneNumber(phoneNumber) {
      phoneNumber = phoneNumber.replace(/\D/g, ''); // remove everything besides numbers;
      if (phoneNumber.charAt(0) === '1') {
        phoneNumber = phoneNumber.substr(1);
      }
      return '(' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, 6) + ' - '
        + phoneNumber.substring(6, 10);
    }

    function User(userData) {
      this.givenName = userData.givenName || 'Anonymous';
      this.email = userData.email;
      this.role = userData.role;
      this.userName = userData.userName || 'Anonymous';
      this.organizationName = userData.organizationName;
      this.readOnly = userData.readOnly;
      this.josefServiceLevel = userData.josefServiceLevel || [];
      this.supportRepTelephoneNumber = userData.supportRepTelephoneNumber || '(312) 340-6414';
      this.supportRepName = userData.supportRepName || 'ALEX Support';
      this.supportRepEmail = userData.supportRepEmail || 'support@alexportal.zendesk.com';
      this.salesRepTelephoneNumber = userData.salesRepTelephoneNumber || '(312) 340-6414';
      this.salesRepName = userData.salesRepName || 'ALEX Support';
      this.salesRepEmail = userData.salesRepEmail || 'support@alexportal.zendesk.com';
    }

    User.prototype.hasServiceLevel = function (levels) {
      levels = _.isArray(levels) ? levels : [levels];
      return _.intersection(this.josefServiceLevel, levels).length > 0;
    };

    User.prototype.supportRep = function () {
      return {
        name: this.supportRepName,
        phone: parseIntoPhoneNumber(this.supportRepTelephoneNumber),
        email: this.supportRepEmail
      };
    };

    User.prototype.salesRep = function () {
      return {
        name: this.salesRepName,
        phone: parseIntoPhoneNumber(this.salesRepTelephoneNumber),
        email: this.salesRepEmail
      };
    };

    return {
      logOut,
      getUser,
      getToken,
      refresh,
      User
    };

  });
