'use strict';

angular.module('tailor')
  .factory('timer',
    function TimerService($timeout) {
      let timer = null;
      let timerState = false;
      let timeUpFunc = angular.noop;

      function initTimer(initFunc, idle) {
        initFunc();
        timeUpFunc = idle;
      }

      function startTimer(waitTime) {
        waitTime = waitTime || 30000;

        timerState = 'stated';

        timer = $timeout(function onTimeUp() {
          timerState = 'timedOut';

          timeUpFunc();
        }, waitTime);
      }

      function stopTimer() {
        $timeout.cancel(timer);
        timerState = 'stopped';
      }

      function getTimerState() {
        return timerState;
      }

      return {
        startTimer,
        stopTimer,
        timerState: getTimerState,
        initTimer
      };
    }
  );
