'use strict';

angular.module('tailor').directive('picker', function picker($state, authorizationService) {
  return {
    templateUrl: 'views/directives/fields/picker.html',
    restrict: 'EA',
    require: ['^ngModel'],
    transclude: true,
    scope: {
      disabled: '@',
    },
    controller: function ($scope, $element, $attrs) {
      authorizationService.addAuthToScope($scope);
      $scope.definition = $scope.$parent.definition;
      $scope.unit = $scope.$parent.unit;
      $scope.module = $scope.$parent.module;
      $scope.configuration = $scope.$parent.configuration;
      $scope.customer = $scope.$parent.customer;
      $scope.model = $scope.$parent.model[$scope.definition.name];
      $scope.unitDisplayName = $scope.$parent.unitDisplayName;

      $scope.customText = {
        buttonDefaultText: 'Select Items...',
      };
      if ($scope.definition.selectionLimit === 1) {
        $scope.singleselect = true;
        $scope.searchSelectAllSettings = {
          selectionLimit: 1,
          enableSearch: false,
          showUncheckAll: false,
          keyboardControls: true,
          dynamicTitle: false,
        };
        $scope.selectedItems = $scope.model.value.items[0] || {};
      } else {
        const isIE = Boolean(window.MSInputMethodContext) && Boolean(document.documentMode);
        $scope.selectedItems = $scope.model.value.items;
        $scope.multiselect = true;
        $scope.searchSelectAllSettings = {
          enableSearch: !isIE, // search doesn't work in IE, so just hide it in IE.
          showSelectAll: true,
          keyboardControls: true,
          dynamicTitle: false,
        };
        if ($scope.definition.selectionLimit) {
          $scope.searchSelectAllSettings.selectionLimit = parseInt(
            $scope.definition.selectionLimit,
            10
          );
        }
      }
      $scope.updateZIndex = function() {
        $element
          .parents('[ng-form]')
          .children('.picker')
          .removeClass('picker-active');
        $element.parents('.field-wrapper').addClass('picker-active');
      };

      let referencedCollection;
      let associatedCollectionName;
      let associatedCollection;
      let referencedModule;
      if ($scope.definition.collection.external) {
        const unitDef = _.find($scope.module.definition.children, {
          name: $scope.unit.name,
        });
        const fieldDef = _.find(unitDef.children, {
          name: $scope.definition.name,
        });
        if (fieldDef) {
          associatedCollection = fieldDef.collection.data;
        } else {
          associatedCollection = [ ];
        }
        referencedCollection = { };
      } else {
        // LOOKUP SERVICE?
        // the following lines of code are pretty similar to the lines from `dependency.js`
        // both places take a given field lookup object ({ settings, module, unit, field }),
        // and use that object to "lookup" field values
        // these can probably be DRYed up in a reusable "field lookup" service
        // but we don't need to do that today
        // todo: figure out how to dry this up.
        referencedCollection = $scope.definition.collection;

        // analogous to `currentModule` in dependency.js
        if (referencedCollection.module) {
          referencedModule = _.find($scope.configuration.Modules, {
            name: referencedCollection.module,
          });
        } else {
          referencedModule = $scope.module;
        } // analogous to `currentUnit` in dependency.js
        if (referencedCollection.settings) {
          associatedCollection = _.find(referencedModule.Units, {
            name: 'settings',
          });
        } else {
          associatedCollection = $scope.unit.data;
        }
        if (referencedCollection.unit) {
          associatedCollection = _.filter(referencedModule.Units, {
            name: referencedCollection.unit,
          });
          associatedCollection = _.map(associatedCollection, function(unit) {
            return Object.assign({ id: unit.id }, unit.data);
          });
        } else if (referencedCollection.field) {
          associatedCollectionName = referencedCollection.field;
          if (referencedCollection.group) {
            associatedCollection =
              associatedCollection[referencedCollection.group].value[
                referencedCollection.field
              ].value;
          } else {
            associatedCollection =
              associatedCollection[referencedCollection.field].value;
          }
        }

        if (associatedCollection === undefined) {
          throw {
            message: 'invalid field lookup',
            lookup: $scope.definition.collection,
          };
        }
        // end "LOOKUP SERVICE?"
      }
      $scope.associatedCollection = associatedCollection;

      // initialize the items in the collection
      $scope.collectionItems = [];
      Object.keys($scope.associatedCollection).forEach(function(item) {
        $scope.collectionItems.push({
          id: $scope.associatedCollection[item].id,
          label: itemLabel($scope.associatedCollection[item]),
          link: itemLink($scope.associatedCollection[item]),
        });
      });

      function itemLabel(item) {
        let label = item.name;
        if (label.value) {
          label = label.value;
        }
        if (referencedCollection.unit) {
          const unit = _.find(referencedModule.Units, { id: item.id });
          label = $scope.unitDisplayName(unit);
        }
        return label;
      }
      function itemLink(item) {
        if (referencedCollection.module || referencedCollection.unit) {
          return $state.href('configuration.module.unit', {
            configurationId: $scope.configuration.id,
            moduleName: referencedModule.name,
            unitName: referencedCollection.unit,
            unitId: item.id,
          });
        }
      }
      function updateItemLabels() {
        let items;
        if (_.isArray($scope.selectedItems)) {
          items = $scope.selectedItems;
        } else {
          items = [$scope.selectedItems];
        }
        $scope.selectedItemLabels = _.filter($scope.collectionItems, function(item) {
          return Boolean(_.find(items, { id: item.id }));
        });
      }
      $scope.updateItemLabels = updateItemLabels;
      updateItemLabels();


      function reEmitFieldChange() {
        let items;
        if (_.isArray($scope.selectedItems)) {
          items = $scope.selectedItems;
        } else {
          items = [$scope.selectedItems];
        }
        $scope.updateItemLabels();
        const valid = $scope.getModelValidity();
        $scope.$emit(
          'field:change',
          $attrs.ngModel,
          { items },
          valid
        );
      }
      $scope.events = {
        onSelectionChanged: reEmitFieldChange,
      };

      // handling name changes to items in a collection - change to below
      $scope.$watch(
        'associatedCollection',
        function(newCollection, oldCollection) {
          if (
            Object.keys(newCollection).length ===
            Object.keys(oldCollection).length
          ) {
            const idOfChangedItem = _.reduce(
              oldCollection,
              function(result, value, key) {
                const matches = _.isEqual(value, newCollection[key]);
                return matches ? result : result.concat(newCollection[key].id);
              },
              []
            );
            if (idOfChangedItem.length) {
              const id = idOfChangedItem[0];
              const updatedField = _.find($scope.collectionItems, {
                id: parseInt(id, 10),
              });
              const item = _.find(newCollection, {
                id: parseInt(id, 10),
              });
              if (updatedField) {
                updatedField.label = itemLabel(item);
              }
            }
          }
        },
        true
      );

      // handling item additions to a collection
      if (referencedCollection.field) {
        $scope.$on('addedCollectionItem', function(event, collection, path) {
          if (path === associatedCollectionName) {
            const collectionArray = [];
            for (const key in collection) {
              collectionArray.push(collection[key]);
            }

            const addedItems = collectionArray.filter(function(newItem) {
              return !$scope.collectionItems.some(function(oldItem) {
                return newItem.id === oldItem.id;
              });
            });
            addedItems.forEach(function(item) {
              $scope.collectionItems.push({
                id: item.id,
                label: itemLabel(item),
                link: itemLink(item),
              });
            });
          }
        });

        // handling item removals to a collection
        // NOTE 4/22/24: Not sure this is still being hit, code does not trigger
        // on collection item deletion. this is $broadcast from section-ctrl
        // but when that is called it does not reach here
        $scope.$on('removedCollectionItem', function(event, path) {
          const fieldTokens = path.split('.');
          const collectionName = fieldTokens[0];
          const idToBeRemoved = parseInt(fieldTokens[2], 10);
          if (collectionName === associatedCollectionName) {
            _.remove($scope.collectionItems, function(item) {
              return item.id === idToBeRemoved;
            });
            _.remove($scope.selectedItems, function(item) {
              return item.id === idToBeRemoved;
            });

            let items;
            if (_.isArray($scope.selectedItems)) {
              items = $scope.selectedItems;
            } else {
              delete $scope.selectedItems.id;
              items = [];
            }
            $scope.$emit('field:change', $attrs.ngModel, {
              items,
            });
          }
        });
      }
    },
    link: function ($scope, $element, $attrs, ctrl) {
      const ngModel = ctrl[0];
      const selectedItemsCollection = _.isArray($scope.selectedItems)
        ? $scope.selectedItems
        : [$scope.selectedItems];
      const scopeWatch = _.isArray($scope.selectedItems)
        ? '[selectedItems.length]'
        : '[selectedItems.id]';
      function validateMinItems() {
        const undeletedSelectedItems = _.intersectionBy(
          selectedItemsCollection, $scope.collectionItems, 'id'
        );
        ngModel.$setValidity('pickerHasMinItems', undeletedSelectedItems.length > 0);
      }
      $scope.$watch(scopeWatch, validateMinItems);
      $scope.getModelValidity = function () {
        validateMinItems();
        ngModel.$validate();
        return ngModel.$valid;
      };
    },
  };
});
