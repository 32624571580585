'use strict';

angular.module('tailor')
  .directive('answerZip', function (moduleService, generalModal) {
    return {
      templateUrl: 'views/directives/eligibility/answer-zip.html',
      restrict: 'EA',
      replace: true,
      controller: function ($scope, $element) {
        $scope.zipQuestionInstance = _.find($scope.associatedQuestions, { id: $scope.question.id });

        let current = $scope;
        while (typeof current.module === 'undefined' && current.$parent != null) {
          current = current.$parent;
        }
        $scope.module = current.module;

        function broadcastZipUpdate(fileName, zipListId) {
          $scope.$emit('zip:update', $scope.question, fileName, zipListId);
        }

        $scope.$on('field:upload', function(event, field, file) {
          event.stopPropagation();

          const reader = new FileReader();

          reader.onload = function (event) {
            const rawStrings = event.target.result.split('\n');
            let zipArray = [];
            const invalidZips = [];
            let maxZip = 0;

            for (let i = 0; i < 100000; i++) {
              zipArray[i] = false;
            }

            for (let j = 0; j < rawStrings.length; j++) {
              const zip = rawStrings[j].trim();
              const numZip = Number(zip);

              // Ignore blank lines
              if (zip === '') {
                continue;
              }

              if (zip.length === 5 && !isNaN(numZip)) {
                maxZip = Math.max(maxZip, numZip);
                zipArray[numZip] = true;
              } else {
                invalidZips.push(zip);
              }
            }
            zipArray = zipArray.slice(0, maxZip + 1);

            function saveZips() {
              return moduleService.createZipList($scope.module, zipArray)
                .then(function (data) {
                  $scope.zipQuestionInstance = {
                    fileName: file.name,
                    zipListId: data.id,
                    id: $scope.zipQuestionInstance.id,
                    inclusive: $scope.zipQuestionInstance.inclusive,
                  };
                  const zipQIndex = _.findIndex($scope.associatedQuestions,
                    { id: $scope.zipQuestionInstance.id }
                  );
                  $scope.associatedQuestions[zipQIndex] = $scope.zipQuestionInstance;
                  broadcastZipUpdate(file.name, data.id);
                });
            }

            const expectedMessage = '<br/>Zips should be 5 digits (e.g. <pre>60657</pre>, or <pre>00001</pre>), with one zip code on each line of the CSV.';
            if (maxZip <= 0) {
              generalModal.activate({
                headerText: 'Uh oh!',
                text: 'There were no valid zips in that CSV!' + expectedMessage,
                okText: 'Let me fix that...',
              });
            } else if (invalidZips.length > 0) {
              const verb = invalidZips.length > 1 ? 'were' : 'was';
              const invalidZipText = invalidZips.slice(0, 5).join('</pre>,<pre>') + (invalidZips.length > 5 ? '...' : '');
              const message = 'There ' + verb + ' ' + invalidZips.length + ' invalid zips in this CSV (<pre>' + invalidZipText + '</pre>).' + expectedMessage + '<br/> Would you like to update the remaining ' + (rawStrings.length - invalidZips.length) + ' of the zips?';
              generalModal.activate({
                headerText: 'Invalid Zip Codes!',
                text: message,
                okText: 'Yes please!',
                cancelText: 'Let me fix that...',
                confirm: function() {
                  saveZips();
                },
              });
            } else {
              saveZips();
            }
          };

          reader.readAsText(file);
        });

        $scope.$on('field:download', function(event) {
          event.stopPropagation();

          moduleService.getZipList($scope.module, $scope.zipQuestionInstance.zipListId).then(function (data) {
            const zipList = data.zips.reduce(function (acc, value, i) {
              if (value) {
                let zip = String(i);

                while (zip.length < 5) {
                  zip = '0' + zip;
                }

                acc.push(zip);
              }

              return acc;
            }, []);

            const $downloadLink = $element.find('.download-link').get(0);
            $downloadLink.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(zipList.join('\n')));
            $downloadLink.setAttribute('download', $scope.zipQuestionInstance.fileName);
            $downloadLink.click();
          });
        });

        $scope.$on('field:delete', function(event) {
          event.stopPropagation();
          $scope.zipQuestionInstance = {
            fileName: null,
            zipListId: null,
            id: $scope.zipQuestionInstance.id,
            inclusive: $scope.zipQuestionInstance.inclusive,
          };
          const zipQIndex = _.findIndex($scope.associatedQuestions,
            { id: $scope.zipQuestionInstance.id }
          );
          $scope.associatedQuestions[zipQIndex] = $scope.zipQuestionInstance;
          broadcastZipUpdate(null, null);
        });
      },
    };
  }
  );
