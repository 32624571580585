'use strict';

angular.module('tailor')
  .directive('phoneNumber', function PhoneDirective() {
    return {
      restrict: 'A',
      require: ['ngModel'],
      link: function postLink($scope, $element, $attrs, ctrl) {
        const ngModel = ctrl[0];

        function formatPhoneNumber(value) {
          if(!value) {
            return value;
          }

          let phoneNumber = value.replace(/\D+/g, ''); // remove everything except numbers
          if(phoneNumber[0] === '1') { // if the phone number starts with a 1,
            phoneNumber = phoneNumber.substr(1); // chop the 1 off
          }

          if(phoneNumber.length < 10) {
            // We don't actually have a phone number, so just pass through what we got.
            return value;
          }

          return '(' + phoneNumber.substr(0, 3) + ')' + ' ' + // area code
                  phoneNumber.substr(3, 3) + '-' + // exchange
                  phoneNumber.substr(6, 4) + // number
                  (phoneNumber.length > 10 ? ' x' + phoneNumber.substr(10) : ''); // optional extension
        }

        ngModel.$parsers.push(formatPhoneNumber);
        ngModel.$formatters.push(formatPhoneNumber);

        const FORMATTED_PHONE_REGEX = /^\(\d{3}\) \d{3}-\d{4}(?: x\d+)?$/;
        ngModel.$validators.phoneNumber = function(modelValue, viewValue) {
          return ngModel.$isEmpty(viewValue) || FORMATTED_PHONE_REGEX.test(modelValue);
        };
      }
    };
  });
