'use strict';

angular
  .module('tailor')
  .directive('extraMenu', function extraMenu(btfModal, $window, $animate, $rootScope) {
    const container = angular.element($window.document.body);
    function makeModal(template, $element) {
      const mask = angular.element('<div class="extra-menu-mask"></div>');
      function activate() {
        $animate.enter(mask, container, null, function () {});
        $animate.enter(template, container, null, function () {});
      }
      function deactivate() {
        $animate.leave(mask);
        $animate.leave(template);
        $rootScope.$apply();
      }
      function cleanup() {
        mask.off('click scroll mousewheel');
        template.off('click');
      }

      mask.on('click scroll mousewheel', deactivate);
      template.on('click', deactivate);
      $element.on('$destroy', cleanup);

      return {
        activate,
        deactivate,
      };
    }

    return {
      templateUrl: 'views/directives/extra-menu.html',
      restrict: 'EA',
      transclude: true,
      scope: true,
      link: function () {},
      controller: function postLink($scope, $element, $attrs, $transclude) {
        function showMenu($event) {
          const target = $($event.target);
          let left = $event.pageX - $event.offsetX + target.outerWidth(),
            top = $event.pageY - $event.offsetY;
          const fontSize = target.css('fontSize');
          left += parseInt(fontSize, 10) * 0.75;
          top -= parseInt(fontSize, 10) * 0.75;
          const childScope = $scope.$new();
          $transclude(childScope, function (clone) {
            const wrapper = angular
              .element('<div/>')
              .append(clone)
              .addClass('extra-menu')
              .addClass($attrs.extraMenu)
              .css({
                top,
                left,
                fontSize,
              });
            makeModal(wrapper, $element).activate();
          });
        }
        $scope.showMenu = showMenu;
      },
    };
  });
