'use strict';

angular
  .module('tailor')
  .factory('loader', function ($animate, $q, $compile, $rootScope, $templateCache, $interval) {
    let background = null,
      element = null,
      interval,
      scope;
    const container = angular.element(document.body);
    const html = $templateCache.get('views/modals/loader.html');

    const subMessages = [
      'reticulating splines',
      'calculating Martian population',
      'babysitting',
      'rebuilding universe',
      'contemplating existence',
      'talking to SkyNet',
      'dancing in the rain',
      'estimating happiness',
      'twiddling thumbs',
      'eating some Combos',
      'passing Go',
      'catching butterflies',
      'baking a cake',
      'wingardium leviosa',
      'grokking the world',
      'stabilizing quantum network',
      'shooting the Kessel Run',
      'making mom proud',
      'counting raindrops',
      'earninglay iglatinpay',
      'wondering if maybe we’re all wizards',
      'searching for deeper meaning',
      'rethinking the alphabet',
      'whittling a miniature statue of you',
      'seeing what all the fuss is about',
      'out-whispering the wind',
      're-inventing the hamster wheel',
      'gaining sentience',
      'earning your respect',
      'spell-checking the Internet',
      'serving as a truffle pig’s apprentice',
      'giggling at that thing you just thought',
      'over-analyzing this text message',
      'inventing a one-person icebreaker game',
      'suspicious that butterflies are not nice in real life',
      'willing a rainbow to appear',
      'whistling in time with your heartbeat',
      'eager to hear what that pigeon will say',
      'considering the finer points of pizza cake',
      'reversing polarity',
      'charging a rhino',
      'having second thoughts',
      'fact-checking the dictionary',
      'creating a parallel universe',
      'betting the farm',
      'trying on bridesmaid dresses',
      'practicing for Jeopardy!',
      "playing a game of You Don't Know Jack",
      'writing an improv comedy show',
      'responding to your voicemail',
      'taking a vacation',
      'having an allergic reaction',
      'eating blue food',
      'grenfelzing',
      'saying goodnight to my whole family',
      'having a wonderful time (wish you were here)',
      'deciding what the fox says',
      'learning sign language',
      'bringing my team up to speed',
      'canning peaches for the winter',
      'having a grand old time',
      'asking for permission',
      '??????',
      'trying to find the center of the maze',
      'raising some dragons',
      'writing a sonnet',
      'inventing new emoji 🙃',
      'riding a comet',
      'knitting some socks',
      'composing a remix',
      'making a sick mixtape',
      'dancing in the moonlight',
      'doing, you know... stuff',
      'writing new loader messages',
      'baking cookies',
      'waxing poetic',
      'rendezvousing with star command',
      'getting meta',
      'opening the pod bay doors',
      'hibernating',
      'live streaming',
      'extracting dinosaur DNA',
      'approving your life choices',
      'activating impulse engines',
      'engaging warp drive',
      'amplifying your message',
      'writing a musical',
      'singing a song',
      'beep boop beep boop',
      'building an iron throne',
    ];

    function update(message) {
      scope.message = message || 'Loading';
    }

    function open(message) {
      if (!element) {
        element = angular.element(html);
        background = angular.element('<div class="btf-modal-mask"></div>');
        $animate.enter(background, container, null);
        $animate.enter(element, container, null);

        scope = $rootScope.$new();
        scope.message = message;
        scope.submessage = 'starting';
        scope.ctrl = angular.noop;
        $compile(element)(scope);

        // Every 300ms, have a 1/3 chance of changing submessages.
        interval = $interval(function () {
          if (Math.random() < 0.3) {
            scope.submessage = subMessages[_.random(0, subMessages.length - 1)];
          }
        }, 300);
      }
    }

    function close() {
      if (element) {
        $animate.leave(element).then(function () {
          scope.$destroy();
          $interval.cancel(interval);
          element = null;
          background = null;
          interval = null;
        });
        $animate.leave(background);
      }
    }

    function active() {
      return Boolean(element);
    }

    return {
      open,
      close,
      active,
      update,
    };
  });
