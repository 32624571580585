'use strict';

angular
  .module('tailor')
  .factory('validationAttributesService', function validationAttributesService() {
    // This maps validation types in the field definition to attributes/directives in HTML.
    const validationsMapping = {
      // angular built-ins (if the field uses ng-model)
      required: { attributes: ['required'] },
      minlength: { attributes: ['ng-minlength', 'minlength'] },
      max: { attributes: ['number', 'ng-max', 'max'] },
      min: { attributes: ['number', 'ng-min', 'min'] },
      regex: {
        attributes: ['ng-pattern', 'pattern'],
        transformer: s => `'${s}'`,
      },

      // not-built in / custom
      maxlength: { attributes: ['max-length'] },
      maxItems: { attributes: ['max-items'] },
      minItems: { attributes: ['min-items'] },
      maxwidth: { attributes: ['maxwidth'] },
      maxheight: { attributes: ['maxheight'] },
      filetype: { attributes: ['filetype'] },
      filesize: { attributes: ['filesize'] },
      number: { attributes: ['number'] },
      unique: { attributes: ['unique'] },
      url: { attributes: ['url'] },
      phone_number: { attributes: ['phone-number'] },
    };

    function decorateWithValidationAttributes($element, fieldDefinition) {
      $element.attr(
        _(fieldDefinition.validations)
          .map(function (validation) {
            return _.map((validationsMapping[validation.key] || {}).attributes, function (validationAttr) {
              return [
                validationAttr,
                validationsMapping[validation.key].transformer ? validationsMapping[validation.key].transformer(validation.value) : validation.value
              ];
            });
            // returns something like: [[['ng-min', 0], ['min', 0]], [['number', true]]]
            // which we can flatten and zip to get {ng-min: 0, min: 0, number: true}
          })
          .flatten()
          .fromPairs()
          .valueOf()
      );
      return $element;
    }

    return {
      decorate: decorateWithValidationAttributes,
    };
  });
