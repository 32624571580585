'use strict';

angular.module('tailor')
  .directive('url', function UrlDirective() {
    const SLAPDASH_URL_REGEX = /^([a-z0-9.+-]+:\/\/)?[^\s]+$/i;
    return {
      restrict: 'A',
      require: ['ngModel'],
      link: function postLink($scope, $element, $attrs, ctrl) {
        const ngModel = ctrl[0];
        function formatUrl(value) {
          if (!value) {
            return value;
          }
          if (value.indexOf('://') === -1) {
            return 'https://' + value;
          }
          if (value.indexOf('://') === 0) {
            return 'https' + value;
          }
          return value;
        }

        function onBlur() {
          if ($element.text()) {
            $element.text(formatUrl($element.text()));
          }
        }

        ngModel.$parsers.push(formatUrl);
        ngModel.$formatters.push(formatUrl);
        ngModel.$validators.url = function(modelValue, viewValue) {
          return ngModel.$isEmpty(viewValue) || SLAPDASH_URL_REGEX.test(modelValue);
        };

        $element.on('blur', onBlur);
      }
    };
  });

// If you're looking for a better URL regex:
// var VERY_SPECIFIC_URL_REGEX =
// adapted further from http://stackoverflow.com/a/6927878, which was originally adapted from http://daringfireball.net/2010/07/improved_regex_for_matching_urls
//   (base domain                                                                                   )(                                                  optional query strings                                               )
//   ((optional protocol                  ) (domain                                                ))(                                                  optional query strings                                               )
//   (protocol       (slashes or letters) ) ((optional prefix )   (sub)domain       . tld        / ))(                                                  optional query strings                                               )
//   /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%]))?(?:(?:www\d{0,3}[\.])?(?:[a-z0-9\.\-]+[[\.][a-z]{2,4}\/?]?))(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’])?)/i;
