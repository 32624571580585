'use strict';

angular.module('tailor')
  .factory('httpErrorInterceptor', function httpErrorInterceptor($rootScope, $q, $injector, $window) {
    // we need to have access to loader and errorModal
    // but injecting them in the normal manner would result in circular dependencies
    // see http://stackoverflow.com/questions/22908508/angularjs-circular-dependency
    let loader, errorModal, generalModal;

    function injectModals() {
      if (!loader) {
        loader = $injector.get('loader');
      }
      if (!errorModal) {
        errorModal = $injector.get('errorModal');
      }
      if (!generalModal) {
        generalModal = $injector.get('generalModal');
      }
    }

    function showGeneralErrorModal(errorMessage, finallyValue) {
      injectModals();

      loader.close();
      return errorModal.open(errorMessage).then(function confirm() { // user hit "ok"
        $window.location.reload();
      })
        .catch(function() { // user hit "cancel"
        // do nothing
        })
        .finally(function() {
          return finallyValue;
        });
    }
    return {
      response: function handleHTTPSuccess(response) {
        if(response.data && response.data.error) {
          const message = response.data.error.message || '';
          return showGeneralErrorModal(message, response.data);
        }
        return response;
      },
      responseError: function handleHTTPError(rejection) {
        if (rejection.status === 409) {
          injectModals();
          generalModal.activate({
            headerText: 'Out of sync',
            text: 'One of the items in your update is out of sync with the latest data! It\'s recommended that you refresh your page to get up-to-date with the latest data.',
            okText: 'Refresh now!',
            confirm: function() {
              $window.location.reload();
            },
          });
        } else if (rejection.status === 404 && rejection.config.method === 'HEAD') {
          return $q.resolve(rejection);
        } else if (rejection.status >= 400) {
          showGeneralErrorModal(rejection.message, rejection);
        }
        return $q.reject(rejection);
      }
    };
  });
