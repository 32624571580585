'use strict';

angular.module('tailor')
  .factory('previewConfirmModal', function(btfModal) {
    return btfModal({
      controller: 'PreviewConfirmCtrl',
      controllerAs: 'ctrl',
      templateUrl: 'views/modals/preview-confirm-popup.html'
    });
  })
  .controller('PreviewConfirmCtrl', function($scope, previewConfirmModal) {
    function cancel() {
      previewConfirmModal.deactivate();
    }

    function ok() {
      $scope.confirm($scope.model);
      previewConfirmModal.deactivate();
    }

    $scope.cancel = cancel;
    $scope.ok = ok;
  });

angular.module('tailor')
  .factory('previewModal', function(btfModal) {
    return btfModal({
      controller: 'PreviewCtrl',
      controllerAs: 'ctrl',
      templateUrl: 'views/modals/preview-popup.html'
    });
  })
  .controller('PreviewCtrl', function($scope, previewModal) {
    function cancel() {
      previewModal.deactivate();
    }

    function ok() {
      $scope.confirm($scope.model);
      previewModal.deactivate();
    }

    $scope.cancel = cancel;
    $scope.ok = ok;
  });
