'use strict';

angular.module('tailor')
  .factory('authorizationService', function authorizationService($rootScope) {
    const policies = {};

    function userCan(policy, context) {
      context = _.merge(this, context || {});
      const user = $rootScope.user;
      if (policies[policy]) {
        return policies[policy].apply({
          user: user || {},
          context: context || {},
          userCan,
        }) || false;
      } else {
        console.log('Policy not found: ' + policy);
        return false;
      }
    }

    function use(policy, handler) {
      policies[policy] = handler;
    }
    function useAll(ps) {
      Object.keys(ps).forEach(function(p) {
        use(p, ps[p]);
      });
    }

    // todo: until we figure out a better way to deal with template helpers
    // we'll need to re-inject userCan on any isolated scope from a directive
    // unfortunately, stack overflow says do exactly that, but i think we can do better
    // https://stackoverflow.com/questions/12466661/using-helper-methods-while-templating-with-angular-js
    function addAuthToScope(scope) {
      scope.userCan = userCan;
      scope.userIs = userCan;
    }
    addAuthToScope($rootScope);

    return {
      use,
      useAll,
      userCan,
      userIs: userCan,
      addAuthToScope,
    };
  });
