angular
  .module('tailor')
  .component('encyclopedia', {
    templateUrl: 'views/directives/encyclopedia.html',
    controller: 'EncyclopediaController',
    bindings: {
      fieldBinding: '<',
    },
  })
  .controller(
    'EncyclopediaController',
    function ($compile, $log, $sce, lightboxModal, unitService) {
      const $ctrl = this;
      $ctrl.loading = false;

      $ctrl.formattedDisplayText = function () {
        if ($ctrl.fieldBinding) {
          return $ctrl.fieldBinding.definition.displayText.replace('^^', '_______');
        }
      };

      $ctrl.openLightboxModal = function (imgSrc) {
        lightboxModal.activate({
          imgSrc,
          okText: 'Close',
        });
      };

      $ctrl.$onChanges = function (changes) {
        const encyclopediaId =
          changes.fieldBinding &&
          changes.fieldBinding &&
          changes.fieldBinding.currentValue &&
          changes.fieldBinding.currentValue.id;
        if (encyclopediaId) {
          $ctrl.loading = true;
          return unitService
            .getEncyclopediaContent(encyclopediaId)
            .then(function (content) {
              // inject lightbox call into img tags
              const doc = new DOMParser().parseFromString(content, 'text/html');
              const images = doc.querySelectorAll('img');
              angular.forEach(images, function (img) {
                const src = img.getAttribute('src');
                img.setAttribute('ng-click', "$ctrl.openLightboxModal('" + src + "')");
              });
              $ctrl.encyclopediaContent = $sce.trustAsHtml(doc.body.innerHTML);
            })
            .catch(function (e) {
              console.error(e);
              $ctrl.encyclopediaContent = '<p>Could not fetch content</p>';
            })
            .finally(function () {
              $ctrl.loading = false;
            });
        } else {
          $ctrl.encyclopediaContent = '';
        }
      };
    }
  );
