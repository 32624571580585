'use strict';

angular.module('tailor')
  .directive('groupWrapper', function groupWrapper(historyService, authorizationService) {
    return {
      restrict: 'A',
      transclude: true,
      scope: {},
      require: ['?^form'],
      controller: function controller($scope, $element, $attrs) {
        authorizationService.addAuthToScope($scope);
        // see also: sectionWrapper directive
        // we need an isolated scope for each group
        // but, do isolate it via the directive scope keyword
        // (a.k.a. the standard angular way)
        // then we would need a LOT of attributes in the dom,
        // plus a lot of scope reassignments at line 9 here
        // instead, we use the "transcludeAndIsolateScope" code below
        // and programatically pass forward parent scope things
        $scope.definition = $scope.$parent.definition;
        $scope.unit = $scope.$parent.unit;
        $scope.settings = $scope.$parent.settings;
        $scope.module = $scope.$parent.module;
        $scope.configuration = $scope.$parent.configuration;
        $scope.customer = $scope.$parent.customer;
        $scope.unitDisplayName = $scope.$parent.unitDisplayName;
        $scope.$watch('$parent.history', function() {
          if (($scope.definition.attributes || {}).inline) {
            $scope.fieldHistory = $scope.$parent.fieldHistory;
            $scope.history = $scope.$parent.history;
          } else {
            $scope.fieldHistory = historyService
              .computeChanges($scope.definition.name + '.value', $scope.$parent.history);
            $scope.history = historyService
              .computeChanges($scope.definition.name + '.value', $scope.$parent.history);
          }
        });

        $scope.groupName = $attrs.groupWrapper;
        $scope.model = $scope.$parent.model[$scope.groupName].value;
      },
      link: function transcludeAndIsolateScope($scope, $element, attr, controller, $transclude) {
        const ngModel = controller[0];

        let block;
        $scope.$on('$destroy', function() {
          block.el.remove();
          block.scope.$destroy();
          block = null;
        });
        const childScope = $scope.$new();
        const appendClone = function(clone) {
          $element.append(clone);
          // keep these for garbage collection
          block = {
            el: clone,
            scope: childScope
          };
        };
        $transclude(childScope, appendClone);

        function reEmitFieldChange(event, ngModelName, newValue) {
          event.stopPropagation();

          const fieldTokens = ngModelName.split('.');
          const fieldName = fieldTokens[1];

          const partialUpdate = {};
          partialUpdate[fieldName] = {
            value: newValue,
            valid: ngModel.$valid,
          };

          $scope.$parent.$emit(
            'field:change',
            'model.' + $scope.groupName,
            partialUpdate,
            ngModel.$valid
          );
        }
        function reEmitCollectionAdd(event, collection, path) {
          event.stopPropagation();
          $scope.$parent.$emit(
            'collection:addItem',
            collection,
            $scope.groupName + '.value.' + path
          );
        }
        function reEmitCollectionDelete(event, collection, path) {
          event.stopPropagation();
          $scope.$parent.$emit(
            'collection:deleteItem',
            collection,
            $scope.groupName + '.value.' + path
          );
        }
        function reEmitCommentAdd(event, fieldName, comment, commentsArray) {
          event.stopPropagation();
          $scope.$parent.$emit(
            'field:addComment',
            $scope.groupName + '.value.' + fieldName,
            comment,
            commentsArray
          );
        }

        $scope.$on('field:addComment', reEmitCommentAdd);
        $scope.$on('field:change', reEmitFieldChange);
        $scope.$on('collection:addItem', reEmitCollectionAdd);
        $scope.$on('collection:deleteItem', reEmitCollectionDelete);
      },
    };
  });
