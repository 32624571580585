'use strict';

// The window.env is required for FE unit tests to run
if (!window.env) {
  window.env = {};
}

angular.module('tailor', [
  'ngTagsInput',
  'ngMessages',
  'ngSanitize',
  'vAccordion',
  'ngAnimate',
  'ui.router',
  'btford.modal',
  'angular-json-editor',
  'templates-tailor',
  'user',
  'angularjs-dropdown-multiselect',
  'isolateForm',
  'angular-bind-html-compile',
]);

// old angular version hacks
angular.lowercase = function (text) {
  return (text || '').toLowerCase();
};

if (Sentry && Sentry.init) {
  Sentry.init({
    // DSN for builder-front-end project in Sentry
    dsn: 'https://6ca3962d230b8c207a1d6918ccd12c15@o31114.ingest.us.sentry.io/4507170618933248',
    environment: window.env.SENTRY_ENV,
    // disable replay sessions
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });
}
