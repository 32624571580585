'use strict';

angular
  .module('tailor')
  .factory(
    'ownerService',
    function ownerService($rootScope, SERVICE_LEVELS, TIER_TO_SERVICE_LEVEL_MAP) {
      function lookupServiceLevel(customerTier) {
        const customerTierLower = (customerTier || '').toLowerCase();
        const trueKey = Object.keys(TIER_TO_SERVICE_LEVEL_MAP).find(function (key) {
          return key.toLowerCase() === customerTierLower;
        });
        return TIER_TO_SERVICE_LEVEL_MAP[trueKey];
      }

      function isServiceLevel(customerTier, serviceLevel) {
        return lookupServiceLevel(customerTier) === serviceLevel;
      }

      function isBasic(customerTier) {
        return isServiceLevel(customerTier, SERVICE_LEVELS.BASIC);
      }

      function isComplete(customerTier) {
        return isServiceLevel(customerTier, SERVICE_LEVELS.COMPLETE);
      }

      function isDIY(customerTier) {
        return isServiceLevel(customerTier, SERVICE_LEVELS.DIY);
      }

      function isExpress(customerTier) {
        return isServiceLevel(customerTier, SERVICE_LEVELS.EXPRESS);
      }

      function isOwner(customerTier) {
        const owners = [];
        if (isDIY(customerTier)) {
          owners.push('partner');
          owners.push('customer');
        }
        owners.push('employee'); // Always make employees owners for now.
        return _.includes(owners, $rootScope.user.role);
      }

      // See note in authorization service related to `addAuthToScope`
      function addHelpersToScope(scope) {
        scope.isDIY = isDIY;
      }
      addHelpersToScope($rootScope);

      function shouldShowFieldForRole(definition, userRole, customerTier) {
        const configurationServiceLevel = lookupServiceLevel(customerTier).toLowerCase();
        if (definition.roles && definition.roles.length > 0) {
          // check if items in array are strings
          if (typeof definition.roles[0] === 'string' || definition.roles[0] instanceof String) {
            return definition.roles.includes(userRole);
          } else {
            const restrictedTier = _.find(definition.roles, function (role) {
              return role.serviceLevel === configurationServiceLevel;
            });
            if (!restrictedTier) {
              return true;
            } else {
              return _.includes(restrictedTier.value || [], userRole);
            }
          }
        } else {
          return true;
        }
      }

      return {
        addHelpersToScope,
        isBasic,
        isComplete,
        isDIY,
        isExpress,
        isServiceLevel,
        isOwner,
        lookupServiceLevel,
        shouldShowFieldForRole,
      };
    }
  );
