'use strict';

angular
  .module('tailor')
  .run(function addStateServicesToRootScope($rootScope, $state, $stateParams, $uiRouter) {
    /* makes these services available from templates
    without having to add to every $scope */
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;
    window.$uiRouter = $uiRouter;
    $rootScope.$uiRouter = $uiRouter;
  })
  .run(function logLogin(analytics) {
    analytics.addLogin();
  })
  .run(function logPageView($rootScope, analytics) {
    $rootScope.$on('$stateChangeSuccess', function(/* event, toState, toParams, fromState, fromParams*/) {
      analytics.addPageView();
    });
  })
  .run(function detectIE() {
    // https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
    // https://stackoverflow.com/questions/31757852/how-can-i-detect-internet-explorer-ie-and-microsoft-edge-using-javascript
    const isIE11 = Boolean(window.MSInputMethodContext) && Boolean(document.documentMode);
    const isIEEdge = /Edge\/\d./i.test(navigator.userAgent);
    if (isIE11) {
      document.documentElement.className += ' ie-11';
    }
    if (isIEEdge) {
      document.documentElement.className += ' ie-edge';
    }
  })
  .run(function savePreviousState($rootScope) {
    $rootScope.$on('$stateChangeSuccess', function(ev, to, toParams, from /* , fromParams*/) {
      $rootScope.previousState = from;
    });
  })
  .run(function setPageTitle($rootScope, $window, $state) {
    $rootScope.$on('$stateChangeSuccess', function() {
      const pageTitle = $state.$current.locals.resolve.$$values.pageTitle || '';
      if(pageTitle.length > 0) {
        $window.document.title = pageTitle + ' - Tailor';
      } else {
        $window.document.title = 'Tailor';
      }
    });
  })
  .run(function logStateChangeErrors($rootScope, $log, $state, analytics) {
    $rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error) {
      $log.error('$stateChangeError: ' + $state.href(toState, toParams) + ' <- ' + $state.href(fromState, fromParams));
      $log.error(event);
      $log.error(error);
      $log.error(error.stack);
      analytics.addError('$stateChangeError: ' + $state.href(toState, toParams) + ' <- ' + $state.href(fromState, fromParams));

      // $state.go('error', {
      //   error: error,
      //   stateInfo: {
      //     toState: toState,
      //     toParams: toParams,
      //     fromState: fromState,
      //     fromParams: fromParams
      //   }
      // });
    });

    $rootScope.$on('$stateNotFound', function(event, unfoundState/* , fromState, fromParams*/) {
      $log.error('$stateNotFound', unfoundState);
      analytics.addError('$stateNotFound: ' + unfoundState);
    });
  })
  .run(function startIdleTimer($document, timer, analytics) {
    // idleTimer.start();
    timer.initTimer(function initFunction() {
      $document.on('mousemove keydown scroll', function onActive() {
        if (timer.timerState === 'timedOut') {
          analytics.addAction({
            eventAction: 'active'
          });
        }
        timer.stopTimer();

        timer.startTimer();
      });
    }, function idleCallback() {
      analytics.addAction({
        eventAction: 'idle'
      });
    });
  })
  .run(function auth($rootScope, $state, loginCtrl) {
    const authData = {
      ClientId: window.env.AWS_USER_POOL_CLIENT_ID,
      AppWebDomain: window.env.AWS_USER_POOL_DOMAIN + '.auth.' + window.env.AWS_USER_POOL_REGION + '.amazoncognito.com',
      TokenScopesArray: ['profile', 'email', 'openid'],
      RedirectUriSignIn: window.location.protocol + '//' + window.location.host + '/',
      RedirectUriSignOut: window.location.protocol + '//' + window.location.host + '/',
      UserPoolId: window.env.AWS_USER_POOL_ID,
    };
    const auth = new AmazonCognitoIdentity.CognitoAuth(authData);
    auth.useCodeGrantFlow();
    $rootScope.auth = auth;

    auth.launchUri = function(url) {
      loginCtrl.activate({
        url
      });
    };
  });
