'use strict';

angular
  .module('tailor')
  .factory(
    'customerService',
    function customerService(
      $http,
      $q,
      $stateParams,
      $window,
      generalModal,
      analytics,
      API,
      debounceHttpService
    ) {
      function getCustomers(configIds) {
        const options = configIds
          ? {
            params: {
              configIds: _.join(configIds, ','),
            },
          }
          : {};
        return $http.get(API.base + '/customers', options).then(function (response) {
          return response.data;
        });
      }

      function get(customerId) {
        return $http.get(API.base + '/customers/' + customerId).then(function (response) {
          return response.data;
        });
      }

      function search(searchKeyword) {
        return $http
          .get(API.base + '/customers/operations/search?searchKeyword=' + searchKeyword)
          .then(function (response) {
            return response.data;
          });
      }

      function getCustomerUrls(customerId) {
        return $http
          .get(API.base + '/customers/' + customerId + '/unique_urls')
          .then(function (response) {
            return response.data;
          });
      }

      function update(customer, fieldName, value) {
        const data = {};
        data[fieldName] = value;
        return debounceHttpService
          .patch(API.base + '/customers/' + customer.id, data)
          .then(function (response) {
            return response.data;
          });
      }

      function create(customer) {
        return $http.post(API.base + '/customers', customer).then(function (response) {
          return response.data;
        });
      }

      function remove(customerId) {
        return $http.delete(API.base + '/customers/' + customerId);
      }

      function checkSlugAvailability(slug, field, customerId) {
        let options = {};
        options[field] = slug;
        options = Object.assign(options, customerId ? { id: customerId } : null);
        return $http.head(API.base + '/customers?' + $.param(options)).then(function (response) {
          // if 404, then slug IS available
          // if 200, then the slug is NOT available
          return response.status === 404 ? true : false;
        });
      }

      return {
        getCustomers,
        get,
        getCustomerUrls,
        update,
        remove,
        create,
        checkSlugAvailability,
        search,
      };
    }
  );
