'use strict';

angular
  .module('tailor')
  .directive('file', function file(fileReader, $window, authorizationService) {
    return {
      templateUrl: 'views/directives/fields/file.html',
      restrict: 'EA',
      replace: true,
      scope: {
        isZip: '@',
      },
      require: ['ngModel', '^field'],
      controller: function ($scope) {
        authorizationService.addAuthToScope($scope);
      },
      link: function postLink($scope, $element, $attrs, ctrl) {
        const ngModel = ctrl[0];
        const field = ctrl[1];
        const $label = $element.find('label');
        const $input = $element.find('input');
        // var inputPolyfillElement;
        const id = _.uniqueId('inline_file_');

        function render() {
          $scope.isSet = Boolean(ngModel.$modelValue.fileName);
          $label[$scope.isSet ? 'removeClass' : 'addClass'](
            'placeholder-shown'
          );
          if (ngModel.$modelValue.fileName) {
            $scope.label = ngModel.$modelValue.fileName.split('/').pop();
          } else {
            $scope.label ='upload a file';
          }
        }

        function onDownload(/* event */) {
          $scope.$emit('field:download', $attrs.ngModel);
        }

        function onFileChange(event) {
          const fileToUpload = event.target.files[0];
          if (!$scope.isZip) {
            ngModel.$modelValue.filesize = fileToUpload.size;
            ngModel.$modelValue.filetype = fileToUpload.type;
            ngModel.$modelValue.fileName = fileToUpload.name;
          }
          $scope.$emit('field:upload', $attrs.ngModel, fileToUpload);

          // need this line to trigger $validators to run
          ngModel.$validate();

          // track-visits directive doesn't work on inputs, so do it manually
          $element.addClass('visited');
          $element.closest('[ng-form]').addClass('visited');
          render();
        }

        function onDelete() {
          $scope.$emit('field:delete', $attrs.ngModel);
          $element.addClass('visited');
          $element.closest('[ng-form]').addClass('visited');
        }

        function onLabelClick(/* event */) {
          if ($input.get(0).files) {
            $input.val('');
          }
        }

        // because we're using a span and not an input,
        // the default ng-disabled behavior isn't gonna do anything.
        if ($attrs.ngDisabled) {
          $scope.$watch($attrs.ngDisabled, function(newValue) {
            $scope.disabled = newValue;
          });
        }

        if (fileReader.polyfilled) {
          const fileInput = new $window.mOxie.FileInput({
            browse_button: $element[0], // inputPolyfillElement,
            multiple: false,
          });
          fileInput.onchange = onFileChange;
          fileInput.init();
        } else {
          $input.on('change', onFileChange);
          $element.on('keydown keypress', function(event) {
            if (event.which === 32) {
              event.preventDefault();
            }
          });
          $input.attr('id', id);
          $label.attr('for', id);
          $label.on('click', onLabelClick);
        }

        // adapted from bytes library
        // https://github.com/visionmedia/bytes.js/blob/master/index.js#L137
        // Parse the string value into an integer in bytes.
        function parse(val) {
          if (typeof val === 'number' && !isNaN(val)) {
            return val;
          }
          if (typeof val !== 'string') {
            return null;
          }
          const parseRegExp = /^((-|\+)?(\d+(?:\.\d+)?)) *(kb|mb|gb|tb|pb)$/i;
          const map = {
            b: 1,
            kb: 1 << 10,
            mb: 1 << 20,
            gb: 1 << 30,
            tb: Math.pow(1024, 4),
            pb: Math.pow(1024, 5),
          };
          // Test if the string passed is valid
          const results = parseRegExp.exec(val);
          let floatValue;
          let unit = 'b';

          if (!results) {
            // Nothing could be extracted from the given string
            floatValue = parseInt(val, 10);
          } else {
            // Retrieve the value and the unit
            floatValue = parseFloat(results[1]);
            unit = results[4].toLowerCase();
          }

          return Math.floor(map[unit] * floatValue);
        }

        // don't validate if uploading file for answer zip
        if (!$scope.isZip) {
          ngModel.$validators.filesize = function(modelValue /* , viewValue */) {
            const filesizeBytes = parse((modelValue || {}).filesize);
            if ($attrs.filesize) {
              return (
                ngModel.$isEmpty(parse((modelValue || {}).filesize)) ||
                filesizeBytes <= $attrs.filesize
              );
            } else {
              return true;
            }
          };

          ngModel.$validators.filetype = function(modelValue /* , viewValue */) {
            if ($attrs.filetype && $attrs.filetype.length) {
              return (
                ngModel.$isEmpty((modelValue || {}).filetype) ||
                $attrs.filetype.indexOf((modelValue || {}).filetype) >= 0
              );
            } else {
              return true;
            }
          };

          // default ng-required won't work because it looks at the whole viewValue which is object
          ngModel.$validators.required = function(modelValue /* , viewValue */) {
            return !ngModel.$isEmpty((modelValue || {}).fileName);
          };

          ngModel.$errorMessages = field.definition.validations || [];
        }

        $scope.accept = $attrs.filetype || '';
        $scope.downloadLink = $attrs.downloadLink;
        $scope.onDelete = onDelete;
        $scope.onDownload = onDownload;

        ngModel.$render = render;
      },
    };
  });
