'use strict';

angular.module('tailor')
  .directive('toggle', function toggle() {
    return {
      restrict: 'EA',
      templateUrl: 'views/directives/fields/toggle.html',
      replace: true,
      require: ['^ngModel'],
      scope: true,
      link: function postLink($scope, $element, $attrs, ctrl) {
        const ngModel = ctrl[0];
        let disabled = false;

        // because we're using a span and not an input,
        // the default ng-disabled behavior isn't gonna do anything.
        if ($attrs.ngDisabled) {
          $scope.$watch($attrs.ngDisabled, function(newValue) {
            disabled = newValue;
          });
        }

        if(!ngModel) {
          return;
        }
        function render() {
          let label = '';
          if(typeof ngModel.$viewValue !== 'undefined') {
            const isOn = Boolean(ngModel.$viewValue);
            const displayLabel = isOn ? $attrs.onLabel : $attrs.offLabel;
            const otherLabel = isOn ? $attrs.offLabel : $attrs.onLabel;

            label = displayLabel;
            $scope.titleText = 'click to make it "' + otherLabel + '"';
          }
          $element.text(label);
        }

        function read() {
          const value = !ngModel.$viewValue;
          ngModel.$setViewValue(value);
          ngModel.$render();
          ngModel.$validate();
          if(!Object.prototype.hasOwnProperty.call($attrs, 'preventChangeEvent')) {
            $scope.$emit('field:change', $attrs.ngModel, value, ngModel.$valid);
          }
        }

        function applyValue() {
          if(!disabled) {
            $scope.$apply(read);
          }
        }

        function toggleOnSpace(event) {
          if(event.keyCode === 32) {
            applyValue();
            event.preventDefault();
          }
        }

        ngModel.$render = render;
        ngModel.$render();

        $element.on('click', applyValue);
        $element.on('keydown keypress', toggleOnSpace);
      }
    };
  });
