'use strict';

angular.module('tailor')
  .factory('lockedErrorInterceptor', function lockedErrorInterceptor($rootScope, $q) {
    return {
      response: function(response) {
        if(response.data && response.data.error) {
          const message = response.data.error.message || '';

          // FIXME: This is *not* how we should be detecting locked stuff
          if(message.indexOf('locked, so cannot be modified') !== -1) {
            $rootScope.$broadcast('error:locked', response);
            return $q.reject(response);
          } else {
            console.log(message);
          }
        }

        return response;
      }
    };
  });
