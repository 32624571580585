'use strict';

angular.module('tailor').component('helpText', {
  templateUrl: 'views/directives/help-text.html',
  bindings: {
    definition: '<',
  },
  controller: function HelpTextController(contentService) {
    const ctlr = this;
    ctlr.showing = false;

    ctlr.toggleToolTip = function(event) {
      if (ctlr.showing && event.target.tagName === 'A') {
        ctlr.showing = false;
      } else {
        ctlr.showing = !ctlr.showing;
      }

      if (ctlr.showing && !ctlr.helpTextValue) {
        ctlr.loadHelpText();
      }
    };

    ctlr.loadHelpText = function() {
      if (ctlr.definition && ctlr.definition.helpText) {
        if (ctlr.definition.helpText.text) {
          ctlr.helpTextValue = ctlr.definition.helpText.text;
        } else if (ctlr.definition.helpText.contentCode) {
          contentService.getHelpText(ctlr.definition.helpText.contentCode)
            .then(function(content) {
              ctlr.helpTextValue = content;
            });
        } else {
          ctlr.helpTextValue = '';
        }
      }
    };

    ctlr.hideToolTip = function() {
      ctlr.showing = false;
    };
  },
});
