'use strict';

angular.module('tailor')
  .factory('idleTimer', function IdleTimerService($document, $timeout, analytics) {

    let idleTimer = null;
    let idleState = false;

    function start(idleWait) {
      idleWait = idleWait || 30000;

      $document.on('mousemove keydown scroll', function onActive() {
        $timeout.cancel(idleTimer);

        if (idleState === true) {
          analytics.addAction({
            eventAction: 'active'
          });
        }

        idleState = false;

        idleTimer = $timeout(function onIdle() {
          idleState = true;
          analytics.addAction({
            eventAction: 'idle'
          });
        }, idleWait);
      });
    }

    return {
      start
    };
  });
