'use strict';

angular.module('tailor')
  .controller('UnitCtrl', function UnitCtrl($scope, $parse, $window, unit, module, historyService, unitService) {
    $scope.module = _.find($scope.configuration.Modules, { id: module.id });
    $scope.unit = _.find($scope.module.Units, { id: unit.id });
    $scope.settings = _.find($scope.module.Units, { name: 'settings' }) || { data: {} };
    unitService.prevalidate($scope.unit, $scope.configuration.customerTier);

    $scope.history = [];
    function loadHistory() {
      const viewOptions = $scope.configuration.viewOptions;
      const enabled = viewOptions && viewOptions.changes.enabled;
      if (enabled) {
        const options = {
          startDate: viewOptions.changes.startDate,
          endDate: viewOptions.changes.endDate,
        };
        viewOptions.changes.loadingUnitHistory = true;
        $scope.history = [];
        historyService.sidebarChanges($scope.configuration, options);

        historyService.getUnitHistory($scope.unit, options)
          .then(function(history) {
            viewOptions.changes.loadingUnitHistory = false;
            $scope.history = history;
          });
      }
    }
    if ($scope.configuration.viewOptions && $scope.configuration.viewOptions.changes) {
      loadHistory();
    }
    $scope.$watch('configuration.viewOptions.changes', loadHistory);

    $scope.$on('section:validChange', function(event, section) {
      /*
      our router setup is isolating two different scopes in our app:
        one scope for the left side nav
        one scope for the main content / fields / right side

      each of these scopes has $scope.module, and $scope.unit set,
      but these refer to different objects in memory

      see router.js,
        scope 1 at the 'configuration' state and template
        scope 2 at the 'configuration.module.unit' state and template

      rather than doing a big refactor to merge these currently identical scopes,
      instead,
        we're maniupating scope events
        and we can look "higher" in the scope tree
        find a shared object ($scope.$parent.configurations)
        and manipulate that object together:
        the _.every line here, lookin up from the unit on our local $scope
        but assigning to the unit on the "shared" scope

      /shrug

      #techdebt #redux #react
      */
      const priorUnitValidity = $scope.unit.data.valid;
      const unitOnParentScope = _.find($scope.module.Units, { id: unit.id });
      const unitSection = _.find(unitOnParentScope.sections, { name: section.name });

      unitSection.valid = section.valid;
      unitOnParentScope.data.valid = $scope.unit.data.valid = _.every($scope.unit.sections, { valid: true });
      if (priorUnitValidity !== $scope.unit.data.valid) {
        $scope.$emit('unit:validChange', $scope.unit);
      }
    });
    $scope.$on('unit:validChange', function(/* event, unit*/) {
      const priorModuleValidity = $scope.module.valid;
      $scope.module.valid = _.every($scope.module.Units, function(u) {
        return u.data.valid;
      });
      if (priorModuleValidity !== $scope.module.valid) {
        $scope.$emit('module:validChange', $scope.module);
      }
    });
  });
