'use strict';

angular.module('tailor')
  .directive('eligibilityQuestions',
    function EligibilityQuestions() {
      return {
        templateUrl: 'views/directives/eligibility/questions.html',
        restrict: 'EA',
        replace: true,
        scope: true,
        link: function postLink($scope) {
          $scope.$watchCollection('associatedQuestions', function() {
            const associateQuestionIds = _.map($scope.associatedQuestions, 'id');
            $scope.possibleQuestions = _.filter($scope.configuration.Questions, function(question) {
              return !_.includes(associateQuestionIds, question.id);
            });
          });
        },
        controller: function($scope) {
          function addQuestion() {
            if($scope.selectedQuestionId === 'Add New') {
              $scope.$emit('question:create', $scope.model);
            } else {
              const selectedQuestion = _.find($scope.configuration.Questions, {
                id: parseInt($scope.selectedQuestionId, 10)
              });
              $scope.$emit('question:associateToParent', selectedQuestion, $scope.model);
            }

            $scope.adding = false;
            $scope.selectedQuestionId = '';
          }
          function removeQuestion(question) {
            $scope.$emit('question:dissociateFromParent', question, $scope.model);
          }

          $scope.removeQuestion = removeQuestion;
          $scope.addQuestion = addQuestion;
        }
      };
    }
  );
