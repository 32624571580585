'use strict';

angular.module('tailor')
  .factory('deleteModal', function(btfModal) {
    return btfModal({
      controller: 'DeleteModalCtrl',
      controllerAs: 'ctrl',
      templateUrl: 'views/modals/delete-modal-popup.html'
    });
  })
  .controller('DeleteModalCtrl', function($scope, deleteModal) {
    function cancel() {
      deleteModal.deactivate();
    }

    function ok() {
      $scope.confirm($scope.model);
      deleteModal.deactivate();
    }

    $scope.cancel = cancel;
    $scope.ok = ok;
  });
