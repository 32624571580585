'use strict';

angular
  .module('tailor')
  .controller('ModulesLabelsCtrl', function($scope, $state, moduleService, ownerService, TIER_CAPABILITIES, featureFlagService) {
    $scope.modules = $scope.configuration.Modules;

    const moduleLabels = moduleService.getPrevalidatedLabels($scope.modules, $scope.configuration);
    moduleLabels.push({
      id: null,
      title: 'More Benefits',
      type: 'module-category',
    });

    $scope.labels = moduleService.getSortedLabelsByIdOrder(moduleLabels);

    // splice in an EQ label after product settings
    let productSettingsIndex = _.findIndex($scope.labels, { title: 'Product Settings' });
    if (productSettingsIndex === -1) {
      productSettingsIndex = $scope.labels.length;
    }
    $scope.labels.splice(productSettingsIndex + 1, 0, {
      id: 1234567890,
      title: 'Eligibility Questions',
      action: function() {
        $state.go('configuration.questions');
      },
    });

    $scope.modulesForLabel = function(label) {
      const moduleDefs = $scope.products.reduce(function(acc, product) {
        const definitions = product.ModuleDefinitions.map(function(definition) {
          return definition.moduleKey;
        });
        return acc.concat(definitions);
      }, []);
      let modules = _.filter($scope.modules, function(module) {
        if (label.id) {
          return module.active && moduleDefs.includes(module.name) && _.includes(module.definition.labels, label);
        } else {
          return module.active && moduleDefs.includes(module.name) && _.filter(module.definition.labels, {
            type: 'module-category',
          }).length === 0;
        }
      });
      _.forEach(modules, markAppearsValid);
      label.valid = _.every(modules, { appearsValid: true });
      label.commentCount = _.sumBy(modules, 'commentCount');
      label.edited = _.reduce(modules, function(edited, module) {
        return edited || module.edited;
      }, false);
      if (ownerService.isDIY($scope.configuration.customerTier) && label.title === 'More Benefits') {
        modules = modules.filter(function(m) {
          return (!m.name.includes('chat'));
        });
      }
      return modules.sort(function(a, b) {
        return a.displayOrder > b.displayOrder ? 1 : -1;
      });
    };

    $scope.shouldShowLabel = function(label) {
      return $scope.modulesForLabel(label).length > 0 || typeof label.action !== 'undefined';
    };
    $scope.$on('module:validChange', function() {
      _.forEach($scope.labels, function(label) {
        const modules = $scope.modulesForLabel(label);
        label.valid = _.every(modules, { appearsValid: true });
      });
    });

    function markAppearsValid(module) {
      module.appearsValid = module.valid || _.every(module.Units, function(unit) {
        return _.every(unit.sections, function(section) {
          return section.valid || section.name === 'Publishing';
        });
      });
    }

    const currentModule = _.find($scope.modules, { name: $state.params.moduleName });
    if (currentModule) {
      const currentLabel = _.find($scope.labels, function(label) {
        return _.includes($scope.modulesForLabel(label), currentModule);
      }) || {};
      currentLabel.isExpanded = true;
    }

    $scope.shouldCheck = function (moduleName, customerTier, unitName) {
      unitName = unitName || null;
      const shouldHideForGoOnlyTier =
        (moduleName === 'pharmacy' || moduleName === 'details_page' || ((moduleName === 'hospital_indemnity' || moduleName === 'critical_illness' || moduleName === 'cancer') && unitName === 'settings')) &&
        TIER_CAPABILITIES.GO_ONLY.includes(customerTier);
      return shouldHideForGoOnlyTier;
    };
  });
