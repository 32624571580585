'use strict';

angular.module('tailor')
  .factory('uploadCSVModal', function(btfModal) {
    return btfModal({
      controller: 'UploadCSVModalCtrl',
      controllerAs: 'ctrl',
      templateUrl: 'views/modals/upload-csv-modal.html'
    });
  })
  .controller('UploadCSVModalCtrl', function($scope, uploadCSVModal, moduleService) {

    const module = $scope.module;
    $scope.file = undefined;

    function upload(event) {
      // https://stackoverflow.com/questions/9716474/how-do-i-correctly-get-the-event-target-in-ie-when-document-click-event-is-regis
      const target = event ? event.target : window.event.srcElement;
      // ie missing mimetype
      // https://stackoverflow.com/questions/37802025/how-to-clone-a-blob-in-javascript
      const uploadedFile = target.files[0];
      $scope.file = !uploadedFile ? undefined : new Blob([uploadedFile], { type: uploadedFile.type || 'text/csv' });
      $scope.$apply();
    }

    function cancel() {
      uploadCSVModal.deactivate();
    }

    function ok() {
      moduleService.upload(module, $scope.file).then(function() {
        uploadCSVModal.deactivate();
      });
    }

    $scope.upload = upload;
    $scope.cancel = cancel;
    $scope.ok = ok;
  });
