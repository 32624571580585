'use strict';

angular.module('tailor')
  .factory('choicesService', function choicesService($http, $q, API, debounceHttpService) {
    function create(question) {
      const data = {
        value: 'New Choice'
      };
      return $http.post(API.base + '/questions/' + question.id + '/choices', data)
        .then(function(response) {
          question.Choices = question.Choices || [];
          question.Choices.push(response.data);
        });
    }

    function remove(choice) {
      return $http.delete(API.base + '/choices/' + choice.id);
    }

    function update(choice, field, value) {
      const data = {};
      data[field] = value;
      return debounceHttpService.patch(API.base + '/choices/' + choice.id, data);
    }

    return {
      create,
      remove,
      update
    };
  });
