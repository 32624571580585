'use strict';

angular.module('tailor').directive('unique', function UniqueDirective() {
  return {
    restrict: 'A',
    require: ['ngModel'],
    link: function postLink($scope, $element, $attrs, ctrl) {
      const ngModel = ctrl[0];
      const field = searchParentFor('definition');
      const configId = searchParentFor('configuration').id;

      // FOR NOW: The unique validator will look for the field name followed by 's' on the scope.
      // in the future, this should be an async validator
      const urlsToSearch = ['benefits_counselor_url_paths', 'nhsp_url_paths', 'bsp_url_paths'];
      let existingValues = [];
      urlsToSearch.forEach(function (fieldName) {
        const existing = searchParentFor(fieldName);
        if (existing) {
          existingValues = existingValues.concat(existing);
        }
      });
      ngModel.$validators.unique = function (modelValue) {
        return !_.some(existingValues, function (item) {
          // Update the value returend form unique endpoint to match what user has entered
          if (item.configId === configId && item.field === field.name) {
            item.value = modelValue;
          }
          // Do not check uniqueness against itself only other fields
          return (
            item.value === modelValue && !(item.configId === configId && item.field === field.name)
          );
        });
      };
      ngModel.$errorMessages = field.validations || [];

      function searchParentFor(strAttr, scope) {
        scope = scope || $scope;
        if (scope[strAttr]) {
          return scope[strAttr];
        }
        return scope.$parent ? searchParentFor(strAttr, scope.$parent) : undefined;
      }
    },
  };
});
