'use strict';

angular.module('tailor')
  .directive('nDecimals', function DecimalsDirective() {
    return {
      restrict: 'A',
      require: ['ngModel'],
      link: function postLink($scope, $element, $attrs, ctrl) {
        const ngModel = ctrl[0];

        function formatDecimal(value) {
          const maxDecimal = Number($element.attr('n-Decimals'));
          const valueAsString = value.toString();
          if (valueAsString.indexOf('.') !== -1) {
            const afterDecimal = valueAsString.split('.')[1];
            if (afterDecimal.length > maxDecimal) {
              ngModel.$setValidity('n-decimals', false);
            } else {
              ngModel.$setValidity('n-decimals', true);
            }
          } else {
            ngModel.$setValidity('n-decimals', true);
          }
          return value;
        }
        ngModel.$parsers.push(formatDecimal);
      }
    };
  });
