'use strict';

angular.module('tailor')
  .factory('fileReader', function fileReader($window, $q) {
    let reader;

    if($window.FileReader) {
      reader = new $window.FileReader();
    } else {
      console.log('No FileReader support available.');
      throw new Error('No FileReader support available.');
    }

    function read(method, file) {
      const deferred = $q.defer();
      reader.onload = function onReaderLoad() {
        deferred.resolve(reader.result);
      };
      reader.onerror = function onReaderError(event) {
        deferred.reject(event);
      };
      reader[method](file);
      return deferred.promise;
    }

    function readAsText(file) {
      return read('readAsText', file);
    }

    function readAsBinaryString(file) {
      return read('readAsBinaryString', file);
    }

    return {
      readAsText,
      readAsBinaryString,
      polyfilled: $window.FileReaderPolyfilled
    };
  });
