'use strict';

angular
  .module('tailor')
  .factory('encyclopediasService', function encyclopediasService(
    contentService
  ) {
    function get(encyclopediaId) {
      return contentService.getEncyclopedia(
        encyclopediaId
      ).then(function(content) {
        return content;
      });
    }

    return {
      get,
    };
  });
