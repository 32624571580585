'use strict';

angular
  .module('tailor')
  .factory('medicarePlans', function medicarePlans(
    $http,
    API
  ) {
    function getAll() {
      return $http.get(API.base + '/medicare-plans/')
        .then(function(response) {
          return response.data;
        });
    }

    return {
      getAll,
    };
  });
