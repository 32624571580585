'use strict';

angular
  .module('tailor')
  .factory('errorModal', function(btfModal, $q) {
    const errorModal = btfModal({
      controller: 'errorModalCtrl',
      controllerAs: 'ctrl',
      templateUrl: 'views/modals/error.html',
    });
    let deferred = $q.defer();

    function close(resolve) {
      deferred[resolve ? 'resolve' : 'reject'](null);
      errorModal.deactivate();
    }
    function open(message) {
      return deferred.promise.then(function() {
        deferred = $q.defer(); // make a new promise for the next error (if there is one)
        errorModal.activate({
          message,
        });
        return deferred.promise;
      });
    }
    deferred.resolve(null);

    return {
      close,
      open,
    };
  })
  .controller('errorModalCtrl', function($scope, errorModal) {
    $scope._cancel = function() {
      errorModal.close(false);
    };
    $scope._ok = function() {
      errorModal.close(true);
    };
  });
