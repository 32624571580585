'use strict';

angular.module('tailor')
  .factory('inUseErrorInterceptor', function inUseErrorInterceptor($rootScope, $q) {
    return {
      response: function(response) {
        if(response.data && response.data.error) {
          const message = response.data.error.message || '';
          if(message.indexOf('in use') !== -1) {
            $rootScope.$emit('error:inuse', response);
            return $q.reject(response);
          }
        }

        return response;
      }
    };
  });
