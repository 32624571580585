'use strict';
angular
  .module('tailor')
  .factory('moduleDefinitionService', function ModuleDefinition($http, $q, API, medicarePlans) {
    // hard coded, for now
    const productSettingsLabel = {
      id: 1,
      title: 'Product Settings',
      type: 'module-category',
      order: 1,
    };
    const insuranceLabel = {
      id: 2,
      title: 'Insurance',
      type: 'module-category',
      order: 2,
    };
    const taxAdvantageLabel = {
      id: 4,
      title: 'Accounts',
      type: 'module-category',
      order: 4,
    };
    const loaLabel = {
      id: 5,
      title: 'Leave of Absence',
      type: 'module-category',
      order: 5,
    };
    const jellyvisionLabel = {
      id: 6,
      title: 'Jellyvision',
      type: 'module-category',
      order: 6,
    };
    const alexGoLabel = {
      id: 7,
      title: 'ALEX Go',
      type: 'module-category',
      order: 10,
    };

    const moreBenefitsLabel = {
      id: 8,
      title: 'More Benefits',
      type: 'module-category',
      order: 11,
    };

    /* eslint-disable camelcase */
    const moduleLabels = {
      accident: insuranceLabel,
      alex_go: alexGoLabel,
      benefits_counselor_evergreen: productSettingsLabel,
      benefits_sneak_peek: productSettingsLabel,
      nhsp_2019: productSettingsLabel,
      critical_illness: insuranceLabel,
      hospital_indemnity: insuranceLabel,
      pet: insuranceLabel,
      cancer: insuranceLabel,
      custom_module_1: moreBenefitsLabel,
      custom_module_2: moreBenefitsLabel,
      custom_module_3: moreBenefitsLabel,
      custom_module_4: moreBenefitsLabel,
      custom_module_5: moreBenefitsLabel,
      dental_plan: insuranceLabel,
      disability: insuranceLabel,
      eap: insuranceLabel,
      employee_assistance_program: loaLabel,
      emp_assistance_program: loaLabel,
      employer_disability: loaLabel,
      employer_paid_family_care: loaLabel,
      employer_paid_parental: loaLabel,
      employer_paid_time_off: loaLabel,
      enrollment: insuranceLabel,
      enrollment_integration_test_user: jellyvisionLabel,
      health_care_fsa: taxAdvantageLabel,
      homepage: productSettingsLabel,
      leave_of_absence_v1: loaLabel,
      leave_of_absence_v2: loaLabel,
      life: insuranceLabel,
      loa_reference_page: loaLabel,
      medical_plan: insuranceLabel,
      medicare: productSettingsLabel,
      paid_maternity_paternity_leave: loaLabel,
      pharmacy: insuranceLabel,
      retirement: taxAdvantageLabel,
      short_term_disability_insurance: loaLabel,
      state_disability: loaLabel,
      state_paid_family_care: loaLabel,
      state_paid_parental: loaLabel,
      tax_savings: taxAdvantageLabel,
      test_module: jellyvisionLabel,
      test_module_2: jellyvisionLabel,
      test_module_3: jellyvisionLabel,
      vision_plan: insuranceLabel,
      voluntary_disability: loaLabel,
      voluntary_paid_family_care: loaLabel,
      voluntary_paid_parental: loaLabel,
    };
    /* eslint-enable camelcase */

    function get(module) {
      // currently, this only retrieves the *latest* module definition
      return $http
        .get(API.base + '/module-definitions/' + module.name)
        .then(function (response) {
          return response.data;
        })
        .then(function fakeLabels(moduleDefinition) {
          moduleDefinition.labels = [];
          if (moduleLabels[moduleDefinition.name]) {
            moduleDefinition.labels.push(moduleLabels[moduleDefinition.name]);
          }
          return moduleDefinition;
        })
        .then(function parseModule(moduleDefinition) {
          moduleDefinition.hasPlans = false;
          moduleDefinition.planTypes = [];
          _.each(moduleDefinition.children, function (unit) {
            if (unit.name !== 'settings') {
              moduleDefinition.hasPlans = true;
              if (!_.find(moduleDefinition.planTypes, { name: unit.name })) {
                moduleDefinition.planTypes.push({
                  childTitle: unit.childTitle,
                  name: unit.name,
                  title: unit.title,
                });
              }
            }
          });
          return moduleDefinition;
        })
        .then(function prepareExternalPickerData(moduleDefinition) {
          const deferments = [];
          _.each(moduleDefinition.children, function (unit) {
            _.each(unit.children, function (field) {
              if (field.type === 'picker' && field.collection && field.collection.external) {
                switch (field.collection.external) {
                  case 'medicare_gpe_plans': {
                    const defer = medicarePlans.getAll().then(function (plans) {
                      field.collection.data = plans;
                    });
                    deferments.push(defer);
                    break;
                  }
                  default:
                    throw {
                      message: 'unknown external source for collection',
                      lookup: field.collection.external,
                    };
                }
              }
            });
          });
          return $q.all(deferments).then(function () {
            return moduleDefinition;
          });
        });
    }

    function schema() {
      return $http.get(API.base + '/module-definitions/schema.json').then(function (response) {
        return response.data;
      });
    }
    function fieldSchema() {
      return $http
        .get(API.base + '/module-definitions/field-schema.json')
        .then(function (response) {
          return response.data;
        });
    }
    return {
      get: _.memoize(get, function (m) {
        return m.name;
      }),
      fieldSchema,
      schema,
    };
  });
