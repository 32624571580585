'use strict';

angular.module('tailor')
  .factory('moduleTemplateService', function moduleTemplateService($q, unitTemplateService) {
    function buildFromModuleDefinition(units, path) {
      return _.map(units, function(unit) {
        const $element = angular.element('<div/>');
        $element.attr('unit-wrapper', unit.name);

        const repeater = angular.element('<div/>');
        repeater.html(unitTemplateService.buildFromUnitDefinition(unit, path + '.' + unit.name));
        repeater.attr('ng-repeat', 'unit in units');

        $element.html(repeater);
        return $element[0].outerHTML;
      }).join('');
    }

    return {
      buildFromModuleDefinition
    };
  });
