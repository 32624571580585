'use strict';

/**
 * @description `ExportHtmlCtrl` controller
 * Does a large portion of the "high-level" work for HTML Export.
 * It handles the page hierarchy, rendering, and ports that render to an external page.
 * (The intermediate rendering is hidden from the DOM with CSS found in `_side_nav.scss`.)
 * It iterates over the entire configuration, triggering the templating process all the
 * while. Each section delegates the rendering of its fields by adding
 * 'unit-section-fields' and 'read-only' directives to each section `element`
 * in `renderSection`.
 * See docs/html-export.md for more.
 */
angular
  .module('tailor')
  .controller(
    'ExportHtmlCtrl',
    function ExportHtmlCtrl(
      $scope,
      $compile,
      $window,
      $document,
      $timeout,
      configurationService,
      featureFlagService,
      moduleService,
      ownerService,
      templateService,
      unitService,
      loader,
      ELIGIBILITY_REPORT_URL
    ) {
      const createTag = templateService.createTag;
      let objectURL;
      $scope.loadingStatus = 'ready';
      $scope.validationEnabled =
        featureFlagService.get($scope.user, 'eligibility-validator-button') === 'on';

      function destroyExternalWindow() {
        if (objectURL) {
          $window.URL.revokeObjectURL(objectURL);
        }
      }

      $scope.$on('$destroy', function () {
        destroyExternalWindow();
      });

      /* eslint-disable max-len */
      /**
       * Creates a link tag with the correct `href`:
       * - href needs origin prepended because...
       *   [relative links don't work in externally generated pages](https://stackoverflow.com/a/39260692)
       * - localhost is handled differently, so prepended origin changes accordingly.
       * @returns {string} link tag for use in external page
       */
      /* eslint-enable max-len */
      function createStylesheetLinkTag() {
        const origin = $window.location.origin; // get deployed origin
        // localhost dir: src/html-export.css. Deployed dir: dist/styles/html-export.css
        return '<link rel="stylesheet" href="' + origin + '/styles/html-export.css">';
      }

      function renderInNewWindow() {
        const contentString = $window.document.querySelector('.export-html-content').outerHTML;

        const headTag = createTag('head', createStylesheetLinkTag());
        const bodyTag = createTag('body', contentString);
        const htmlTag = createTag('html', headTag + bodyTag);

        const winUrl = $window.URL.createObjectURL(new Blob([htmlTag], { type: 'text/html' }));

        objectURL = $window.open(
          winUrl,
          'win',
          [
            // external window dimensions
            'width=',
            $window.innerWidth,
            ',height=',
            $window.innerHeight,
          ].join('')
        );

        objectURL.onbeforeunload = function () {
          destroyExternalWindow();
        };
      }

      /**
       * Sorta hacky way to deal with the fact that we don't know how long
       * AngularJS will take to finish rendering the export.
       * We calculate an estimated timeout based on the string size of the config
       * and a "scalingFactor"
       * @returns {number} timeout in milliseconds
       */
      function getTimeoutBasedOnConfigSize() {
        const scalingFactor = 2222;
        return JSON.stringify($scope.configuration).length / scalingFactor;
      }

      // -------------------
      // Trigger HTML Export
      // -------------------
      $scope.doExport = function doExport() {
        const user = $scope.$root.user;
        const supportedProducts = _.filter($scope.products, function productSupported(product) {
          return product.productKey === 'benefits_counselor_evergreen';
        });

        const configurationModules = $scope.configuration.Modules;

        let moduleLabels = moduleService.getPrevalidatedLabels(
          configurationModules,
          $scope.configuration
        );
        moduleLabels = moduleService.getSortedLabelsByIdOrder(moduleLabels);

        // Warning: parts of this were copied from modules-labels-ctrl.
        // Initial attempts at pulling out pieces resulted in breaking validation arrows.
        function modulesForLabel(label) {
          const moduleDefs = supportedProducts.reduce(function (acc, product) {
            const definitions = product.ModuleDefinitions.map(function (definition) {
              return definition.moduleKey;
            });
            return acc.concat(definitions);
          }, []);
          let modules = _.filter(configurationModules, function (module) {
            if (label.id) {
              return (
                module.active &&
                _.includes(moduleDefs, module.name) &&
                _.includes(module.definition.labels, label)
              );
            } else {
              return (
                module.active &&
                _.includes(moduleDefs, module.name) &&
                _.filter(module.definition.labels, {
                  type: 'module-category',
                }).length === 0
              );
            }
          });

          if (
            ownerService.isDIY($scope.configuration.customerTier) &&
            label.title === 'More Benefits'
          ) {
            modules = modules.filter(function (m) {
              return !_.includes(m.name, 'chat');
            });
          }
          return modules.sort(function (a, b) {
            return a.displayOrder > b.displayOrder ? 1 : -1;
          });
        }

        function shouldHideSection(section) {
          return !configurationService.canSeeSection(
            $scope.configuration,
            section,
            $scope.customerTier
          );
        }

        function shouldHideModule(module) {
          const isDIY = ownerService.isDIY($scope.configuration.customerTier);
          return (
            !module.active ||
            module.Units.length === 0 ||
            (module.name === 'chat' && (isDIY || user.role !== 'employee'))
          );
        }

        // ---------------------------------------------------------
        // Set up outer contentWrapperEl and handle opening in new window
        // ---------------------------------------------------------

        const contentWrapperEl = $document.find('.export-html-wrapper');
        contentWrapperEl.html('');

        $scope.loadingStatus = 'loading';
        loader.open('Export is loading (may take a few minutes...)');

        // Set up mainElement, and section rendering functions
        const mainElement = angular.element(
          createTag('div', '', {
            className: 'export-html-content',
          })
        );

        function renderSection(module, unit, section, renderOptions) {
          if (unit.sections.length > 1) {
            // only display section title if the unit has more than one section
            const tag = createTag(renderOptions.tagType, section.name, renderOptions);
            mainElement.append(tag);
          }

          const element = angular.element('<div/>');
          element.append(module.name + ' unit.id: ' + unit.id);
          element.attr('unit-section-fields', '');
          element.attr('read-only', true); // important!
          element.attr('module-name', module.name);
          element.attr('unit-id', unit.id);
          element.attr('section-name', section.name);

          mainElement.append($compile(element)($scope));
        }

        // ---------------------------------------------------------------
        // Create page structure (hierarchy of h tags and rendered fields)
        // ---------------------------------------------------------------

        // Level 1: labels
        for (let labelIdx = 0; labelIdx < moduleLabels.length; labelIdx++) {
          const moduleLabel = moduleLabels[labelIdx];

          const modulesForCurrentLabel = modulesForLabel(moduleLabel);

          if (modulesForCurrentLabel.length === 0 || typeof moduleLabel.action !== 'undefined') {
            continue;
          }

          const moduleLabelTag = createTag('h1', moduleLabel.title, {
            className: 'export-module-label-title',
          });
          mainElement.append(moduleLabelTag);

          // Level 2: modules
          for (let moduleIdx = 0; moduleIdx < modulesForCurrentLabel.length; moduleIdx++) {
            const currentModule = modulesForCurrentLabel[moduleIdx];
            if (shouldHideModule(currentModule)) {
              continue;
            }

            const currentModuleTitle =
              currentModule.title == null // catches null AND undefined
                ? currentModule.definition.description
                : currentModule.title;

            const moduleTag = createTag('h2', currentModuleTitle, {
              className: 'export-module-title',
            });

            mainElement.append(moduleTag);

            const currentUnits = currentModule.Units;

            // Level 3a: settings units
            for (let unitIdx = 0; unitIdx < currentUnits.length; unitIdx++) {
              const unit = currentUnits[unitIdx];

              if (unit.sections.length === 0 || unit.name !== 'settings') {
                continue;
              }

              const unitDisplayName = unitService.getUnitDisplayName(unit);
              const settingsUnitTag = createTag('h3', unitDisplayName, {
                className: 'export-settings-unit-title',
              });

              mainElement.append(settingsUnitTag);

              // Level 4a: sections for settings units
              for (let sectionIdx = 0; sectionIdx < unit.sections.length; sectionIdx++) {
                const section = unit.sections[sectionIdx];

                if (shouldHideSection(section)) {
                  continue;
                }

                renderSection(currentModule, unit, section, {
                  className: 'export-settings-section-title',
                  tagType: 'h4',
                });
              }
            }

            // Level 3b: Plans
            for (let planIdx = 0; planIdx < currentModule.definition.planTypes.length; planIdx++) {
              const planType = currentModule.definition.planTypes[planIdx];

              const planTypeTag = createTag('h3', planType.title, {
                className: 'export-plan-type-title',
              });
              mainElement.append(planTypeTag);

              // Level 4b: plan units
              for (let planUnitIdx = 0; planUnitIdx < currentUnits.length; planUnitIdx++) {
                const planUnit = currentUnits[planUnitIdx];

                if (planUnit.sections.length === 0 || planUnit.name !== planType.name) {
                  continue;
                }

                const planUnitDisplayName = unitService.getUnitDisplayName(planUnit);

                const planUnitTag = createTag('h4', planUnitDisplayName, {
                  className: 'export-plan-unit-title',
                });

                mainElement.append(planUnitTag);

                // Level 5b: plan sections
                for (
                  let planSectionIdx = 0;
                  planSectionIdx < planUnit.sections.length;
                  planSectionIdx++
                ) {
                  const planSection = planUnit.sections[planSectionIdx];

                  if (shouldHideSection(planSection)) {
                    continue;
                  }

                  renderSection(currentModule, planUnit, planSection, {
                    className: 'export-plan-section-title',
                    tagType: 'h5',
                  });
                }
              }
            }
          }
        }

        contentWrapperEl.append(mainElement);

        $timeout(function () {
          $scope.$apply(function () {
            $scope.loadingStatus = 'done';
            loader.close();
          });
          renderInNewWindow();
        }, getTimeoutBasedOnConfigSize());
      };

      $scope.doValidate = function doValidate() {
        const configurationId = $scope.$stateParams.configurationId;

        if (configurationId) {
          let reportUrl = `${ELIGIBILITY_REPORT_URL}/configuration/${configurationId}/product/benefits_counselor_evergreen`;

          $window.open(reportUrl, '_blank');
        }
      };
    }
  );
