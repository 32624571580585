'use strict';

/**
 * @typedef TemplateOptions
 * @property {*} path
 * @property {string} className wrapper css class name
 * @property {number} collectionLength needed for children of 'collection'
 * @property {string} unitData path to access unit data via model. ex: 'premium_groups.value[0]'
 */

angular
  .module('tailor')
  .factory(
    'fieldTemplateReadOnlyService',
    function fieldTemplateReadOnlyService(templateService) {
      /**
       * @param {Object} field
       * @param {TemplateOptions} options
       * @returns {HTMLElement} angular element
       */
      function build(field, options) {
        options = options || {};
        field.attributes = field.attributes || {};
        const $element = angular
          .element('<div/>')
          .attr('field-read-only', JSON.stringify(field))
          .attr('dependent', true)
          .attr('path', false);

        buildField($element, field, options);

        return $element;
      }

      function buildField($element, field, options) {
        if (typeof options.collectionLength === 'number') {
          $element.attr('collection-length', options.collectionLength);
        }

        if (
          _.includes(
            [
              'collection',
              'dropdown',
              'eligibility',
              'file',
              'group',
              'picker',
              'toggle',
            ],
            field.type
          )
        ) {
          if (options.unitData) {
            $element.attr('path', options.unitData);
          }
          // see field-read-only.js
          return $element;
        }

        let unitData = options.unitData || field.name;
        let tagForValue = 'span';
        let classNameForValue = 'field-read-only-field-value';

        if (_.includes(['date', 'text'], field.type)) {
          classNameForValue += ' text-underline';
          unitData = '{{model.' + unitData + '.value}}';
        } else if (_.includes(['richtext'], field.type)) {
          tagForValue = 'div';
          unitData = templateService.createTag('div', '', {
            attributes: 'ng-bind-html="model.' + unitData + '.value"',
            className: 'field-read-only-richtext-value',
          });
        } else if (_.includes(['label'], field.type)) {
          const labelClasses =
            field.attributes.class && field.attributes.class.split(' ');
          const containsAlertClass =
            labelClasses && _.includes(labelClasses, 'alert');
          const shouldHideLabel = containsAlertClass;
          if (shouldHideLabel) {
            return $element;
          }

          classNameForValue = 'field-read-only-field-label';
          if (field.attributes.title_text) {
            tagForValue = 'h6';
            unitData = field.attributes.title_text;
          } else if (field.attributes.subtitle_text) {
            tagForValue = 'div';
            unitData = field.attributes.subtitle_text;
            classNameForValue += ' field-read-only-field-label-subtitle';
          }
        }

        const unitDataTemplate = templateService.createTag(
          tagForValue,
          unitData,
          {
            className: classNameForValue,
          }
        );

        const wrapperClassName = options.className || 'field-read-only-field';

        const $innerElement = angular.element(
          templateService.createTag('div', '', {
            className: wrapperClassName,
          })
        );

        const fieldText = templateService.getFieldText(field.displayText, unitDataTemplate);
        $innerElement.append(fieldText);

        $element.append($innerElement);
        return $element;
      }

      return {
        build,
      };
    }
  );
