'use strict';

angular.module('tailor')
  .factory('lockModal', function(btfModal) {
    return btfModal({
      controller: 'LockModalControl',
      controllerAs: 'ctrl',
      templateUrl: 'views/modals/lock-modal.html'
    });
  })
  .controller('LockModalControl', function($scope, lockModal) {
    function cancel() {
      lockModal.deactivate();
    }

    function ok() {
      $scope.errormessage = '';
      $scope.confirm();
      lockModal.deactivate();
    }
    function keyPress(e) {
      if(e.which === 13) {
        ok();
      }
    }

    $scope.cancel = cancel;
    $scope.keyPress = keyPress;
    $scope.ok = ok;
    $scope.errorMessage = '';
  });
