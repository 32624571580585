/**
 * @directive questionHistory
 * @desc utility function for displaying history for any _question_ in a standard way
 * If you wanna display history on a different piece of data (e.g. other fields or collections),
 * use a one of those history directives intead
 */
'use strict';
angular
  .module('tailor')
  .directive('questionHistory', function questionHistory($compile, DEBOUNCE_RATE, historyService) {
    return {
      restrict: 'A',
      templateUrl: 'views/directives/history/question-history.html',
      priority: 1,
      controller: function ($scope, $element, $attrs) {
        const questionId = $scope.$eval($attrs.questionHistory);
        $scope.questionHistory = { changes: [] };
        $scope.questionHistoryButtonLocation = $element;
        $scope.$watch('history', function () {
          // todo: TAILOR-331
          // backend is communicating storing diffs as arrays
          // but communicating diffs as objects
          // till then, there's a bunch of extra lines to undo that difference

          if ($scope.fieldHistory) {
            if (
              $scope.fieldHistory.priorValue &&
              $scope.fieldHistory.priorValue.value &&
              $scope.fieldHistory.priorValue.value.associated_questions
            ) {
              $scope.fieldHistory.priorValue.value.associated_questions = _.reduce(
                $scope.fieldHistory.priorValue.value.associated_questions,
                function (agg, val, k) {
                  agg[k] = val;
                  return agg;
                },
                {}
              );
            }
            $scope.questionHistory = historyService.computeChanges(function (data) {
              if (data && data.value && data.value.associated_questions) {
                const item = _.find(data.value.associated_questions, function (q, k) {
                  // eslint-disable-next-line eqeqeq
                  return q.id == questionId || (typeof q.id === 'undefined' && k == questionId);
                });
                if (item) {
                  return item;
                }
              }
            }, $scope.fieldHistory);
            $scope.questionHistory.changes = $scope.questionHistory.changes.map(function (change) {
              change.question = _.find($scope.configuration.Questions, function (q) {
                // eslint-disable-next-line eqeqeq
                return q.id == questionId;
              });
              change.answerIsSelected = function (choice) {
                return (
                  (change.change &&
                    change.change.answers &&
                    change.change.answers[choice.id] &&
                    !change.change.answers[choice.id].__REMOVED__) ||
                  (change.prior && change.prior.answers && change.prior.answers[choice.id])
                );
              };
              return change;
            });
          }
        });
      },
    };
  });
