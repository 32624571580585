'use strict';

angular.module('tailor')
  .factory('sectionTemplateService', function sectionTemplateService($q, fieldTemplateService, fieldTemplateReadOnlyService) {
    function buildFromSectionDefinition(sectionDefinition, sectionPath, options) {
      const $element = angular.element('<div></div>');

      if (options && options.isReadOnly) {
        $element.append(_.map(sectionDefinition, function(fieldDefinition) {
          return fieldTemplateReadOnlyService.build(fieldDefinition, { path: sectionPath + '.' + fieldDefinition.name });
        }));
      } else {
        $element
          .attr('name', 'form')
          .attr('ng-form', '')
          .attr('novalidate', '')
          .attr('ng-model-options', '{allowInvalid:true}')
          .attr('focus-manager', '');
        // .attr('text-substitutions', sectionDefinition.substitutions ? JSON.stringify(sectionDefinition.substitutions) : null)

        $element.append(_.map(sectionDefinition, function(fieldDefinition) {
          return fieldTemplateService.build(fieldDefinition, sectionPath + '.' + fieldDefinition.name);
        }));
      }

      return $element[0].outerHTML;
    }

    return {
      buildFromSectionDefinition
    };
  });
