'use strict';

/*
 * TODO: keep refactoring this,
 * just like... in general
 *  - move `buildField` stuff into a new `basic-field` directive
 *  - move `buildCollection` stuff into the `collection` directive
 *  - move `buildGroup` stuff into the `group` directive
 *  - move as much as possible into templates, not js
 *  - make the unit tests as "dumb" as possible... not html checking, but instead
 *    - "the template service should render every field"
 *    - "the field directive should include a comment bubble"
 *    - "the field directive should include help text"
 *    - "the field directive should include field history"
 *    - etc.
 */
angular
  .module('tailor')
  .factory('fieldTemplateService', function fieldTemplateService(
    $log,
    validationAttributesService
  ) {
    const employePerm = [
      'ee_amt',
      'ee_option_code',
      'ee_family_amt',
      'ee_family_option_code',
      'ee_1_amt',
      'ee_1_option_code',
      'ee_2_amt',
      'ee_2_option_code',
      'ee_spouse_amt',
      'ee_spouse_option_code',
      'ee_1_child_amt',
      'ee_1_child_option_code',
      'ee_2_children_amt',
      'ee_2_children_option_code',
      'ee_3_or_more_children_amt',
      'ee_3_or_more_children_option_code',
      'ee_3_children_amt',
      'ee_3_children_option_code',
      'ee_4_or_more_children_amt',
      'ee_4_or_more_children_option_code',
      'ee_spouse_1_child_amt',
      'ee_spouse_1_child_option_code',
      'ee_spouse_2_or_more_children_amt',
      'ee_spouse_2_or_more_children_option_code',
      'ee_spouse_2_children_amt',
      'ee_spouse_2_children_option_code',
      'ee_spouse_3_children_amt',
      'ee_spouse_3_children_option_code',
      'ee_spouse_4_or_more_children_amt',
      'ee_spouse_4_or_more_children_option_code',
      'ee_children_amt',
      'ee_children_option_code',
      'family_amt',
      'family_option_code',
      'individual_amt',
      'individual_option_code',
      'flat_amt',
      'flat_option_code',
      'employer_premiums',
    ];
    // Entry point to the service; does the work of
    // determining how to deal with multi-lingual fields.
    function build(field /* , path */) {
      return angular
        .element()
        .add(buildFieldWrapper(field));
    }

    function buildFieldWrapper(field) {
      field.validations = field.validations || [];
      field.attributes = field.attributes || {};

      let $element = angular.element('<div/>')
        .attr('field', JSON.stringify(field))
        .attr('dependent', true);

      if (field.type === 'collection') {
        $element = buildCollection($element, field);
      } else if (field.type === 'group') {
        $element = buildGroup($element, field);
      } else {
        $element = buildField($element, field);
      }

      return $element;
    }

    function addAttributes($element, field) {
      function serializeAttributeValue(value) {
        return _.isArray(value) || _.isObject(value) ? angular.toJson(value) : value;
      }
      _.each(field.attributes, function(value, attr) {
        $element.attr(_.kebabCase(attr), serializeAttributeValue(value));
      });
      validationAttributesService.decorate($element, field);
    }
    function buildCollection($element, field) {
      $element.attr('collection-wrapper', field.name)
        .attr('ng-form', field.name);

      const $collection = angular
        .element('<div></div>')
        .attr('collection', field.name)
        .attr('ng-model', 'model.' + field.name + '.value')
        .attr('name', field.name);
      addAttributes($element, field);

      // Create a collection-item element to hold the child fields of the collection.
      const $item = angular
        .element('<div></div>')
        .attr('collection-item', '')
        .attr('ng-if', 'activeItem')
        .attr('ng-model', 'model.' + field.name + '.value')
        .append(
          _.map(field.children, function(childField) {
            return build(childField);
          })
        );
      $collection.append($item);
      $element.append($collection);

      return $element;
    }

    function buildGroup($element, field) {
      $element.attr('group-wrapper', field.name);
      addAttributes($element, field);

      $element.append(
        _.map(field.children, function(childField) {
          return build(childField);
        })
      );
      return $element;
    }

    function getDisplayTextParts(field) {
      // todo: this ^^ check should be moved to the backend
      const displayText = field.displayText;
      const displayTextParts = (displayText || '').split('^^');
      // Do we have the right number?
      if (displayTextParts.length > 2) {
        throw 'More than one ^^ found in displayText: ' + field.displayText;
      }
      return displayTextParts;
    }

    function buildField($wrapper, field) {
      const displayTextParts = getDisplayTextParts(field);

      // Create, fill out, and decorate the control
      // This section here is the core of the service
      const $element = angular.element('<span></span>');

      $element
        .attr(field.type, 'model') // maybe the most important line in the file!
        .attr('ng-model', 'model.' + field.name + '.value') // this also has a pretty good claim
        .attr('name', 'field')
        .attr('track-visits', true)
        .attr('ng-disabled', 'configuration.locked || $root.user.readOnly');
      addAttributes($element, field);

      // add comment bubble if not a label
      const $comment = field.type === 'label' ? '' : '<comment-bubble/>';

      // Add the display text, the control, tooltip, and the help text to the field wrapper
      const label = angular.element('<div class="field-label"/>');
      label.append(_.compact([
        displayTextParts[0] ? '<span>' + displayTextParts[0] + '</span>' : null,
        $comment,
        $element,
        displayTextParts[1] ? '<span>' + displayTextParts[1] + '</span>' : null,
      ]));
      $wrapper.append(label);

      // Adding pay per cycle field
      if (field.section === 'Premiums' && employePerm.includes(field.name)) {
        const modelName = 'model.'+field.name+'.value';
        const $elementPay = angular.element('<span ng-if="' + modelName
          + ' > 0 && model.premium_frequency.value >= 1">{{' + modelName
          + '/model.premium_frequency.value | currency:"$":2}} per pay period</span>');
        const $elementPayZero = angular.element('<span ng-if="' + modelName
          + ' <= 0 || model.premium_frequency.value < 1">$X.XX per pay period</span>');
        const $payLabel = angular.element('<div class="pay-period-label"/>');
        $payLabel.append(_.compact([$elementPay, $elementPayZero]));
        $wrapper.append($payLabel);
      }
      return $wrapper;
    }

    return {
      build,
    };
  });
