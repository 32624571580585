'use strict';

angular.module('tailor')
  .directive('answerChoices', function answerChoices() {
    return {
      templateUrl: 'views/directives/eligibility/answer-choices.html',
      restrict: 'EA',
      controller: function($scope) {
        function applyChoice(event, choice, question) {
          if (event.target.checked) {
            $scope.$emit('choice:associateToParent', choice, question);
          } else {
            $scope.$emit('choice:dissociateFromParent', choice, question);
          }
        }
        $scope.applyChoice = applyChoice;
      }
    };
  }
  );
