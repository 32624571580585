'use strict';

angular
  .module('tailor')
  .controller(
    'ConfigurationsCtrl',
    function ConfigurationsCtrl(
      $scope,
      customerService,
      configurationService,
      createConfigurationModal,
      loader,
      cookiesService
    ) {
      const ctrl = this;
      ctrl.columnNameMap = {
        customer_tier: 'CUSTOMER_TIER',
        customer_name: 'CUSTOMER_NAME',
        name: 'CONFIGURATION_NAME',
        id: 'CONFIGURATION_ID',
        expiration_date: 'EXPIRATION_DATE',
        plan_year: 'PLAN_YEAR',
      };
      ctrl.searchSort = {
        column: 'id',
        order: 'ASC',
      };
      ctrl.recentSort = {
        column: 'id',
        order: 'ASC',
      };
      $scope.viewAllConfig = false;
      $scope.configurations = [];
      $scope.recentConfigurationsList = [];
      $scope.greeting = '';
      $scope.maxPage = 0;
      $scope.recentConfigMaxPage = 0;
      $scope.recentConfigLimits = [10, 20, 50, 100];
      $scope.searchPagination = {
        limit: $scope.recentConfigLimits[0],
        page: 1,
      };
      $scope.recentConfigPagination = {
        limit: $scope.recentConfigLimits[0],
        page: 1,
      };

      $scope.searchTerm = null;
      $scope.readOnly = $scope.$root.user.readOnly;
      generateGreetings();

      function init() {
        const user = $scope.$root.user;
        if (user.role !== 'employee') {
          $scope.viewAllConfiguration();
        } else {
          ctrl.recentConfigs = cookiesService.getConfigurationIds().reverse();
          if (ctrl.recentConfigs.length) {
            resetRecentConfigsMaxPage();
            $scope.loadRecentConfig();
          }
        }
      }

      function resetRecentConfigsMaxPage() {
        $scope.recentConfigMaxPage = Math.ceil(
          ctrl.recentConfigs.length / $scope.recentConfigPagination.limit
        );
      }

      function resetSearchConfigsMaxPage() {
        $scope.maxPage = Math.ceil($scope.totalRecords / $scope.searchPagination.limit);
      }

      $scope.keyPressed = function ($event, searchKeyword) {
        if ($event.which === 13) {
          $scope.search(searchKeyword, true);
        }
      };

      $scope.search = function search(searchKeyword, checkForBlank) {
        if (checkForBlank) {
          // eslint-disable-next-line eqeqeq
          if (!searchKeyword || searchKeyword == '') {
            $scope.hasError = true;
            return;
          }
        }
        if (Boolean(searchKeyword) && searchKeyword !== '') {
          if ($scope.viewAllConfig) {
            $scope.searchPagination.page = 1;
          }
          $scope.viewAllConfig = false;
        }
        const query = {};
        if (!$scope.viewAllConfig) {
          if ($scope.previousKeyword !== searchKeyword) {
            $scope.searchPagination.page = 1;
            $scope.previousKeyword = searchKeyword;
          }
          if (!searchKeyword) {
            $scope.hasError = true;
            return;
          }
          $scope.searchTermInvalid = false;
          query.searchKeyword = $scope.searchTerm;
        }
        if (!_.isEmpty(ctrl.searchSort)) {
          const columnToSort = ctrl.columnNameMap[ctrl.searchSort.column];
          if (columnToSort) {
            query.sort = ctrl.searchSort.order === 'DESC' ? '-' + columnToSort : columnToSort;
          }
        }
        Object.assign(query, $scope.searchPagination);
        loader.open('Searching Configurations');
        configurationService.search(query).then(
          function (data) {
            $scope.maxPage = Math.ceil(data.totalCount / $scope.searchPagination.limit);
            $scope.configurations = data.list;
            $scope.totalRecords = data.totalCount;
            $scope.noDataFound = !data.list.length && true;
            loader.close();
            return data.list;
          },
          function () {
            loader.close();
            console.log('Error wile loading the search configurations.');
          }
        );
      };

      $scope.loadRecentConfig = function loadRecentConfig() {
        const query = {};
        if (!_.isEmpty(ctrl.recentSort)) {
          const columnToSort = ctrl.columnNameMap[ctrl.recentSort.column];
          if (columnToSort) {
            query.sort = ctrl.recentSort.order === 'DESC' ? '-' + columnToSort : columnToSort;
          }
        }
        Object.assign(query, $scope.recentConfigPagination);
        query.configIds = _.join(ctrl.recentConfigs, ',');
        loader.open('Retrieving configurations');
        configurationService.search(query).then(
          function (data) {
            loader.close();
            if (!data.list) {
              return;
            }
            $scope.recentConfigurationsList = data.list;
            return data.list;
          },
          function () {
            loader.close();
            console.log('Error wile loading the recent configurations.');
          }
        );
      };

      $scope.matches = function (searchTerm) {
        return function (benefig) {
          if (!searchTerm) {
            return true;
          }
          searchTerm = searchTerm.toLowerCase();
          const matches =
            String(benefig.id).indexOf(searchTerm) >= 0 ||
            benefig.name.toLowerCase().indexOf(searchTerm) >= 0 ||
            benefig.customer.name.toLowerCase().indexOf(searchTerm) >= 0 ||
            benefig.customer.informalName.toLowerCase().indexOf(searchTerm) >= 0;
          return matches;
        };
      };

      $scope.onChangeSearchTerm = function onChangeSearchTerm(newValue) {
        if (newValue) {
          $scope.hasError = false;
          $scope.dirty = true;
        }
      };

      $scope.createNewConfiguration = function createNewConfiguration() {
        loader.open();
        customerService.getCustomers().then(function (customers) {
          loader.close();
          createConfigurationModal.activate({
            customers,
          });
        });
      };

      $scope.previousSearchPage = function previousSearchPage() {
        if ($scope.searchPagination.page === 1) {
          return;
        }
        const searchKeyword = !$scope.viewAllConfig ? $scope.searchTerm : undefined;
        $scope.searchPagination.page--;
        $scope.search(searchKeyword);
      };

      $scope.nextSearchPage = function nextSearchPage() {
        if ($scope.searchPagination.page >= $scope.maxPage) {
          return;
        }
        $scope.searchPagination.page++;
        const searchKeyword = !$scope.viewAllConfig ? $scope.searchTerm : undefined;
        $scope.search(searchKeyword);
      };

      $scope.changeSearchConfigLimit = function changeSearchConfigLimit() {
        $scope.searchPagination.page = 1;
        resetSearchConfigsMaxPage();
        const searchKeyword = !$scope.viewAllConfig ? $scope.searchTerm : undefined;
        $scope.search(searchKeyword);
      };

      $scope.previousRecentPage = function previousRecentPage() {
        if ($scope.recentConfigPagination.page === 1) {
          return;
        }
        $scope.recentConfigPagination.page--;
        $scope.loadRecentConfig();
      };

      $scope.nextRecentPage = function nextRecentPage() {
        if ($scope.recentConfigPagination.page >= $scope.recentConfigMaxPage) {
          return;
        }
        $scope.recentConfigPagination.page++;
        $scope.loadRecentConfig();
      };

      $scope.changeRecentConfigLimit = function changeRecentConfigLimit() {
        $scope.recentConfigPagination.page = 1;
        resetRecentConfigsMaxPage();
        $scope.loadRecentConfig();
      };

      $scope.viewAllConfiguration = function viewAllConfiguration() {
        $scope.viewAllConfig = true;
        $scope.searchTerm = '';
        $scope.previousKeyword = '';
        $scope.hasError = false;
        $scope.searchPagination.page = 1;
        $scope.search();
      };

      function generateGreetings() {
        const currentHour = moment().format('HH');

        if (currentHour >= 3 && currentHour < 12) {
          $scope.greeting = 'Good Morning';
        } else if (currentHour >= 12 && currentHour < 15) {
          $scope.greeting = 'Good Afternoon';
        } else if (currentHour >= 15 && currentHour < 20) {
          $scope.greeting = 'Good Evening';
        } else if (currentHour >= 20 && currentHour < 3) {
          $scope.greeting = 'Good Night';
        } else {
          $scope.greeting = 'Hello';
        }

        $scope.greeting = $scope.greeting + ', ' + $scope.$root.user.givenName + '!';
      }

      const deleteListner = $scope.$on(
        'CONFIGURATION_TABLE_DELETE_CONFIG',
        function ($event, data) {
          const isDeleted = cookiesService.deleteConfigurationId(data.id);
          const user = $scope.$root.user;
          if (user.role === 'employee' && isDeleted) {
            ctrl.recentConfigs = cookiesService.getConfigurationIds().reverse();
            if (ctrl.recentConfigs.length) {
              resetRecentConfigsMaxPage();
              $scope.loadRecentConfig();
            }
          }
          if ($scope.configurations.length) {
            const searchKeyword = !$scope.viewAllConfig ? $scope.searchTerm : undefined;
            $scope.search(searchKeyword);
          }
        }
      );

      const orderListner = $scope.$on('CONFIGURATION_TABLE_ORDER', function ($event, data) {
        // eslint-disable-next-line eqeqeq
        if (data.tableId == 'SEARCH_TABLE') {
          ctrl.searchSort = data;
          const searchKeyword = !$scope.viewAllConfig ? $scope.searchTerm : undefined;
          $scope.search(searchKeyword);
          // eslint-disable-next-line eqeqeq
        } else if (data.tableId == 'RECENT_TABLE') {
          ctrl.recentSort = data;
          $scope.loadRecentConfig();
        }
      });

      $scope.$on('$destroy', function () {
        orderListner();
        deleteListner();
      });

      init();
    }
  );
