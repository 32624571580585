'use strict';
/*
Directive that was created for the express purpose of rebinding
the 'model' value in the scope.  This was needed to correctly handle
dependencies on groups in the HTML Export feature.

One design constraint of the HTML Export feature was that we didn't
want to create a bunch of bindings.  Therefore we're doing a one-time
assignment of the model property when the directive is first executed.
*/

angular
  .module('tailor')
  .directive(
    'oneTimeModel',
    function oneTimeModel() {
      return {
        restrict: 'A',
        priority: 40000,
        scope: true,
        controller: function oneTimeModelCtrl(
          $scope,
          $attrs
        ) {
          $scope.model = $scope.$eval($attrs.oneTimeModel);
        },
      };
    }
  );
