'use strict';
/* eslint camelcase: ["error", {allow: ["body_text"]}] */

angular
  .module('tailor')
  .directive('publishFields', function publishFields() {
    const productKeyAlias = [
      {
        name: 'newhire',
        moduleKeys: ['newhire', 'nhsp_2019'],
      },
    ];
    return {
      restrict: 'A',
      scope: true,
      template:
        '<div' +
        ' unit-section-fields customer="customer"' +
        ' unit-id="{{unit.id}}"' +
        ' configuration-id="{{configuration.id}}"' +
        ' module-name="{{module.name}}"' +
        ' section-name="Publishing"' +
        '></div>',
      controller: function ($scope, $element, $attrs) {
        const product = $scope.$eval($attrs.product);
        $scope.customer = $scope.$eval($attrs.customer);
        $scope.module = getModuleDefinition();
        $scope.spanish = _.find($scope.configuration.Modules, {
          name: 'alex_go',
        });
        $scope.unit = _.find(($scope.module || {}).Units, { name: 'settings' });

        function getModuleDefinition() {
          const productConf = _.find(productKeyAlias, {
            name: product.productKey,
          });
          return productConf
            ? _.find($scope.configuration.Modules, function (module) {
                return _.find(productConf.moduleKeys, function (key) {
                  return key === module.name;
                });
              })
            : _.find($scope.configuration.Modules, {
                name: product.productKey,
              });
        }
      },
    };
  })
  // intention is to reuse this in the future to make codes easier, and page
  // loads faster / non-blocking :D given a unit id, section name, module name -
  // builds the html for that combo.
  .directive(
    'unitSectionFields',
    function (
      $q,
      $compile,
      $controller,
      $rootScope,
      unitService,
      moduleDefinitionService,
      moduleDefinitionTemplateService,
      consumerService
    ) {
      return {
        restrict: 'A',
        scope: true,
        template: '<div class="loading"><span class="field-wrapper">{{message}}</span></div>',
        compile: function (/* element, attrs */) {
          return {
            pre: function ($scope, $element, $attrs) {
              const isReadOnly = $attrs.readOnly;

              const moduleName = $attrs.moduleName;
              const unitId = $attrs.unitId;
              const sectionName = $attrs.sectionName;
              $scope.customer = $scope.$eval($attrs.customer);
              if (moduleName && unitId && sectionName) {
                const deferments = [
                  moduleDefinitionService.get({ name: moduleName }),
                  unitService.get(unitId),
                ];
                if (moduleName === 'benefits_counselor_evergreen' && $scope.productSelection) {
                  deferments.push(
                    consumerService.getResource(
                      $scope.productSelection.Product.productKey,
                      'generateUrls',
                      {
                        configurationId: $attrs.configurationId,
                        user: {
                          email: $rootScope.user.email,
                          role: $rootScope.user.role,
                        },
                      }
                    )
                  );
                }
                $q.all(deferments).then(function (values) {
                  const moduleDefinition = values[0];
                  const unit = values[1];
                  const urls = values[2];
                  const unitDefinition =
                    _.find(moduleDefinition.children, { name: unit.name }) || {};
                  const bcUrlUnit =
                    unitDefinition &&
                    _.find(unitDefinition.children, {
                      name: 'benefits_counselor_url_group',
                    });

                  if (bcUrlUnit && urls) {
                    const bcGoUrlLabel = _.find(bcUrlUnit.children, {
                      name: 'benefits_counselor_and_go_url_label',
                    });
                    if (bcGoUrlLabel && bcGoUrlLabel.attributes) {
                      const formatted = (urls || []).map(function (url) {
                        return '<div>' + url.label + ': ' + url.url + '</div>';
                      });
                      bcGoUrlLabel.attributes.body_text = formatted.join('');
                    }
                    $scope.$on('bcUrlGroupChanged', function () {
                      // Re-compile the directive
                      const compiledElement = $compile($element)($element.scope());
                      $element.replaceWith(compiledElement);
                    });
                  }
                  const module = _.find($scope.configuration.Modules, {
                    name: moduleName,
                  });
                  const unitOnConfig = _.find($scope.module, { id: unitId });
                  Object.assign(unitOnConfig || unit, unit);
                  const section = {
                    name: sectionName,
                    data: unit.data,
                    children: _.filter(unitDefinition.children, {
                      section: sectionName,
                    }),
                  };
                  const path = module.name + '.' + unit.name + '.' + sectionName;
                  const template = moduleDefinitionTemplateService.build(
                    section.children || [],
                    path,
                    { isReadOnly }
                  );

                  // n.b. need controller before template
                  $controller('SectionCtrl', {
                    $scope,
                    configuration: $scope.configuration,
                    module,
                    unit,
                    section,
                  });

                  $element.html($compile(template)($scope));
                });
              }
            },
          };
        },
      };
    }
  );
