'use strict';

angular.module('tailor')
  .directive('tabs', function Tabs($templateCache, $compile) {
    return {
      restrict: 'A',
      transclude: true,
      templateUrl: 'views/directives/tabs.html',
      controller: function($scope, $element, $attrs) {
        $scope.tabs = [];
        $scope._changeTab = function(tabName) {
          $scope[$attrs.changeTab](tabName);
        };
      },
      link: function postLink($scope, $element, $attrs) {
        const watchers = [];
        let base = $element.find('.tab-content');
        base = angular.element(base).empty();

        const tabs = $scope.$eval($attrs.tabs);
        _.each(tabs, function(tab) {
          const template = $templateCache.get(tab.template) || '';
          if(tab.visible) {
            watchers.push(tab.visible);
            tab._visible = $scope.$eval(tab.visible);
          } else {
            tab._visible = true;
          }
          tab.$em = $compile('<div>' + template + '</div>')($scope);
          // TODO: why isn't activeTab working
          // tab.$em = $compile('<div ng-show="_activeTab.name === \'' + tab.name + '\'">' +  template + '</div>')($scope);
          base.append(tab.$em);
        });

        function render() {
          _.each(tabs, function(tab) {
            if(tab.visible) {
              tab.v = $scope.$eval(tab.visible);
              tab._visible = Boolean(tab.v);
            } else {
              tab.v = null;
              tab._visible = true;
            }
          });
        }

        $scope.$watch($attrs.activeTab, function() {
          $scope._activeTab = _.find(tabs, { name: $scope[$attrs.activeTab] });
        });

        $scope.tabs = tabs;
        _.each(watchers, function(watcher) {
          $scope.$watch(watcher, render);
        });
      }
    };
  });
