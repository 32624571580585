'use strict';

angular.module('tailor')
  .directive('richtext', function richtext($timeout, ckeditorSettings) {
    const parentCSSVariables = [];
    [].slice.call(document.styleSheets)
      .forEach(function(styleSheet) {
        [].slice.call(styleSheet.cssRules || [])
          .forEach(function(rule) {
            // https://github.com/niklasvh/html2canvas/issues/1374
            if (rule && typeof rule.cssText === 'string') {
              let css = rule.cssText.split('{');
              css = (css[1] || '').replace('}', '').split(';');
              for (let i = 0; i < css.length; i++) {
                const prop = css[i].split(':');
                if (prop.length === 2 && prop[0].indexOf('--') <= 1) {
                  parentCSSVariables.push(prop[0].trim());
                }
              }
            }
          });
      });
    return {
      restrict: 'A',
      require: ['ngModel', '^field'],
      link: function postLink($scope, $element, $attrs, $ctrl) {
        const ngModel = $ctrl[0];
        const field = $ctrl[1].definition;

        const isShortText = (field.field_type === 'short_text');

        const editorId = _.uniqueId(field.name);
        let editor;
        let focused = false;

        function setEditorContents(html) {
          if(editor && !focused) {
            editor.setData(html);
          }
          ngModel.$validate();
        }

        function render() {
          setEditorContents(ngModel.$viewValue);
        }

        function updateTheme() {
          if (editor && editor.document) {
            const parentCSS = getComputedStyle(editor.element.$);
            for (let i = 0; i < parentCSSVariables.length; i++) {
              const prop = parentCSSVariables[i];
              editor.document.$.documentElement.style
                .setProperty(prop, parentCSS.getPropertyValue(prop));
            }
          }
        }
        $scope.$watch('theme', updateTheme);

        function postProcessHtml(html) {
          if(html.length === 0) {
            return '';
          }

          const nodes = angular.element(html);
          nodes.find('a').prop('target', '_blank');
          return _.map(nodes, function(node) {
            return node.outerHTML;
          }).join('');
        }

        function saveChanges(rawValue) {
          const processedHTML = postProcessHtml(rawValue);

          $scope.$apply(function saveChange() {
            ngModel.$setViewValue(processedHTML);
            ngModel.$validate();
            $scope.$emit('field:change', $attrs.ngModel, processedHTML, ngModel.$valid);
          });
        }

        function initializeEditor() {
          function onChange(event) {
            saveChanges(event.editor.getData());
          }

          function setVisited(newFocusValue) {
            $element.addClass('visited');
            $element.closest('[ng-form]').addClass('visited');
            focused = newFocusValue;
          }

          function onFocus() {
            setVisited(true);
          }
          function onBlur() {
            setVisited(false);
          }

          if (!editor) {
            $element.empty();

            // create the editor
            $scope.editor = editor = CKEDITOR
              .appendTo($element[0], ckeditorSettings[isShortText ? 'short_text' : 'rich_text']);
            editor.id = editorId;

            editor.on('instanceReady', function() {
              if(isShortText) {
                editor.document.$.body.className += ' short_text';
              }

              const dtd = CKEDITOR.dtd;
              for (const e in CKEDITOR.tools.extend(
                {},
                dtd.$nonBodyContent,
                dtd.$block,
                dtd.$listItem,
                dtd.$tableContent
              )) {
                editor.dataProcessor.writer.setRules( e, {
                  indent: false,
                  breakBeforeOpen: false,
                  breakAfterOpen: false,
                  breakBeforeClose: false,
                  breakAfterClose: false
                });
              }

              // because we're using a span and not an input,
              // the default ng-disabled behavior isn't gonna do anything.
              if ($attrs.ngDisabled) {
                // setDisabledState will get triggered initally at the end of this $digest cycle
                $scope.$watch($attrs.ngDisabled, setDisabledState);
              }

              // CKEditor's built-in paste doesn't trigger the change event
              // so we bind to the document's paste event and save changes outselves.
              angular.element(editor.document.$).on('paste', function(/* e */) {
                // CKEditor takes the event loop to parse the paste, so we delay until
                // the next event loop to avoid any race conditions.
                $timeout(function() {
                  saveChanges(editor.getData());
                }, 1);
              });

              // copy custom variables for themeing
              updateTheme();
            });

            editor.on('change', onChange);

            editor.on('focus', onFocus);
            editor.on('blur', onBlur);

            render();
          }
        }

        function setDisabledState(disabled) {
          if (editor) {
            editor.setReadOnly(disabled);
          }
        }

        // Make sure we can see the field definition on this input
        $scope.fieldDefinition = field;

        // Hook up $render
        ngModel.$render = render;

        // Add in some things for CKEditor to latch on to and for us to style.
        $attrs.$addClass('rich_text');
        $attrs.$set('id', editorId);

        if(isShortText) {
          $attrs.$addClass('short_text');
        }

        // Initialize! (after a turn of the event loop so the dependent directive can hit first)
        $timeout(initializeEditor, 1);
      }
    };
  });
