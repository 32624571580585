'use strict';

angular
  .module('tailor')
  .factory('lightboxModal', function (btfModal) {
    return btfModal({
      controller: 'lightboxModalCtrl',
      controllerAs: 'ctrl',
      templateUrl: 'views/modals/lightbox-modal.html',
    });
  })
  .controller('lightboxModalCtrl', function ($scope, lightboxModal) {
    function ok() {
      if (typeof $scope.confirm === 'function') {
        $scope.confirm($scope.model);
      }
      lightboxModal.deactivate();
    }
    $scope._ok = ok;
  });
