'use strict';

angular.module('tailor').factory('contentService', function contentService($http, API) {
  function get(contentType, contentCode) {
    return $http
      .get(API.base + '/contents/' + contentType + '/' + contentCode)
      .then(function (response) {
        return response.data;
      });
  }

  function getHelpText(contentCode) {
    return get('text_field', contentCode)
      .then(function (content) {
        return content;
      })
      .catch(function (error) {
        console.log('An error occurred fetching help text content:', error);
      });
  }

  function getEncyclopedia(contentCode) {
    return get('encyclopedia_entry', contentCode);
  }

  function getModalMessage(contentCode) {
    return get('modal_message', contentCode)
      .then(function (content) {
        return content;
      })
      .catch(function (error) {
        console.log('An error occurred fetching modal text content:', error);
      });
  }

  return {
    get,
    getHelpText,
    getEncyclopedia,
    getModalMessage,
  };
});
