'use strict';

angular.module('tailor')
  .factory('progressInterceptor', function progressInterceptor($rootScope, $q, $window) {
    const progressMethods = [
      'POST',
      'PUT',
      'DELETE',
      'PATCH'
    ];
    const queuedRequests = [];
    function updateProgress() {
      $rootScope.savingInProgress = queuedRequests.length > 0;
    }
    updateProgress();

    $window.onbeforeunload = function(event) {
      if(queuedRequests.length > 0) {
        event.preventDefault();
        return 'Some of your changes are still saving. If you leave the page now, your changes will be lost. Are you sure you want to leave the page?';
      }
    };

    return {
      request: function(config) {
        if(_.includes(progressMethods, config.method)) {
          queuedRequests.push({
            config
          });
        }
        updateProgress();
        return config;
      },
      response: function(response) {
        if(_.includes(progressMethods, response.config.method)) {
          queuedRequests.shift();
          updateProgress();
        }
        return response;
      },
      responseError: function(error) {
        if(_.includes(progressMethods, error.config.method)) {
          queuedRequests.shift();
          updateProgress();
        }
        return $q.reject(error);
      }
    };
  });
