/* eslint camelcase: ["error", {allow: ["benefits_counselor_url_paths", "bsp_url_paths",
  "nhsp_url_paths"]}] */
'use strict';

angular
  .module('tailor')
  .controller(
    'SetupCtrl',
    function SetupCtrl(
      $q,
      $scope,
      configurationService,
      loader,
      moduleService,
      ownerService,
      productService,
      uniqueUrls,
      TIER_CAPABILITIES
    ) {
      $scope.benefits_counselor_url_paths = uniqueUrls.filter(function (obj) {
        return obj.field === 'benefits_counselor_url_path';
      });
      $scope.nhsp_url_paths = uniqueUrls.filter(function (obj) {
        return obj.field === 'nhsp_url_path';
      });
      $scope.bsp_url_paths = uniqueUrls.filter(function (obj) {
        return obj.field === 'bsp_url_path';
      });
      $scope.productSelections = [];

      function refreshProductSelections() {
        productService
          .getProducts($scope.configuration.customerTier)
          .then(function (products) {
            $scope.products = products;

            $scope.productSelections.length = 0; // clear out existing array
            _.each($scope.products, function (product) {
              const productSelection = {
                id: product.id,
                Product: product,
                Consumers: product.Consumers,
                name: product.name,
                key: product.productKey,
                active: Boolean(
                  _.find($scope.configuration.Products, {
                    id: product.id,
                  })
                ),
              };
              $scope.productSelections.push(productSelection);

              const definitions = _.map(
                product.ModuleDefinitions,
                function (definition) {
                  const module = _.find($scope.configuration.Modules, {
                    name: definition.name,
                  });
                  const title =
                    (module && module.title && module.title !== '') ? module.title : definition.description;
                  const moduleSelection = {
                    name: definition.name,
                    description: definition.description,
                    displayOrder:
                      module && module.displayOrder
                        ? module.displayOrder
                        : null,
                    title,
                    originalTitle: title,
                    active: module && module.active,
                    required:
                      definition.ProductModuleDefinitions &&
                      definition.ProductModuleDefinitions.required,
                    product: productSelection,
                  };
                  if (moduleSelection.required && productSelection.active) {
                    module.active = true;
                  }
                  return moduleSelection;
                }
              );
              productSelection.definitions = definitions;
              return productSelection;
            });
          });
      }

      $scope.$watch('configuration.customerTier', function () {
        productService
          .getProducts($scope.configuration.customerTier)
          .then(function (allowedProducts) {
            // when changing customerTier it is possible that some currently
            // active products are no longer allowed in the new tier, therefore find those.
            const toDisable = $scope.productSelections.filter(function (productSelection) {
              return (
                productSelection.active &&
                !allowedProducts.find(function (p) {
                  return p.id === productSelection.id;
                })
              );
            });

            // then disable them
            const promises = toDisable.map(productSelection => {
              productSelection.active = false;
              return $scope.onProductToggle(productSelection);
            });


            // then refresh product selection list
            $q.all(promises).then(function () {
              refreshProductSelections();
            });
          });
      });

      function checkFSABusinessLogic(module) {
        if (
          (module.name === 'medical_plan' || module.name === 'tax_savings') &&
          module.active
        ) {
          const productSelection = _.find($scope.productSelections, {
            key: 'benefits_counselor_evergreen',
          });
          const fsa = _.find(productSelection.definitions, {
            name: 'health_care_fsa',
          });
          fsa.active = true;
          $scope.onModuleToggle(fsa);
        }
      }

      $scope.onModuleToggle = function onModuleToggle(moduleSelection) {
        function updateModuleSelection() {
          $scope.onModuleUpdate(moduleSelection).then(function () {
            checkFSABusinessLogic(moduleSelection);
          });
        }

        if (!moduleSelection.active || moduleSelection.product.active) {
          // if the product is already on, turn the module on immediately
          return updateModuleSelection();
        } else {
          // otherwise, automatically turn that product on for them.
          const module = _.find($scope.configuration.Modules, {
            name: moduleSelection.name,
          });
          if (module) {
            module.active = moduleSelection.active;
          }
          const product = moduleSelection.product;
          product.active = true;
          return $scope
            .onProductToggle(product)
            .then(updateModuleSelection)
            .then(function () {
              refreshProductSelections();
            });
        }
      };
      $scope.onProductToggle = function onProductToggle(product) {
        product.expanded = product.active;
        const toggleStatus = product.active;
        if (toggleStatus) {
          loader.open('Enabling ' + product.name);
        } else {
          loader.open('Disabling ' + product.name);
        }
        const productUpdate = { id: product.id, active: product.active };
        return configurationService
          .updateProduct($scope.configuration, productUpdate)
          .then(function () {
            const moduleUpdatePromises = [];
            if (product.active) {
              _.forEach(product.definitions, function (moduleSelection) {
                const module = _.find($scope.configuration.Modules, {
                  name: moduleSelection.name,
                });
                if (moduleSelection.required || module.lastActive) {
                  if (!module.active) {
                    module.active = true;
                    moduleUpdatePromises.push(
                      moduleService.update(module, { active: true })
                    );
                  }
                }
              });
            } else {
              const lastActiveModules = [];
              _.forEach(product.definitions, function (moduleSelection) {
                const module = _.find($scope.configuration.Modules, {
                  name: moduleSelection.name,
                });
                if (module.active || module.lastActive) {
                  module.active && lastActiveModules.push(module.name);
                  const moduleIsUsedByAnotherProduct = _.find(
                    $scope.productSelections,
                    function (p) {
                      return (
                        p.name !== product.name &&
                        p.active &&
                        _.find(p.definitions, function (m) {
                          return m.name === moduleSelection.name;
                        })
                      );
                    }
                  );
                  if (!moduleIsUsedByAnotherProduct) {
                    module.active = false;
                    module.lastActive = lastActiveModules.includes(module.name);
                    moduleUpdatePromises.push(
                      moduleService.update(module, {
                        active: false,
                        lastActive: module.lastActive,
                      })
                    );
                  }
                }
              });
            }
            return $q.all(moduleUpdatePromises);
          })
          .then(function () {
            loader.close();
          })
          .then(function () {
            refreshProductSelections();
          })
          .catch(function () {
            if (product.active) {
              loader.close();
            }
          });
      };
      $scope.onModuleUpdate = function onModuleUpdate(moduleSelection) {
        const module = _.find($scope.configuration.Modules, {
          name: moduleSelection.name,
        });
        module.active = moduleSelection.active;
        module.title = moduleSelection.title;
        // module.renaming = false;
        return moduleService.update(module, {
          title: module.title,
          active: module.active,
        });
      };

      $scope.shouldHideModule = function shouldHideModule(user, module) {
        const shouldHideCustomModule =
          module.name.includes('custom_module') &&
          !module.description.includes('Text-Only Module') &&
          ownerService.isDIY($scope.configuration.customerTier);
        const shouldHideChat =
          module.name === 'chat' &&
          (ownerService.isDIY($scope.configuration.customerTier) ||
            user.role !== 'employee');
        const shouldHideAlexGo =
          module.name === 'alex_go' && user.role === 'customer';
        const shouldHideForGoOnlyTier =
         (module.name === 'pharmacy' || module.name === 'details_page')
          && TIER_CAPABILITIES.GO_ONLY.includes($scope.configuration.customerTier);

        return shouldHideCustomModule || shouldHideChat ||
        shouldHideAlexGo || shouldHideForGoOnlyTier;
      };
    }
  );
