'use strict';

angular
  .module('tailor')
  .factory('policies', function policies(authorizationService, ownerService) {
    const policies = {
      'edit stuff': function () {
        if (this.user && !this.user.readonly) {
          return true;
        }
      },

      'edit this configuration': function () {
        return this.userCan('edit stuff') && !(this.context.configuration || {}).locked;
      },

      'upload collection csvs': function () {
        return this.userCan('edit this configuration') && this.user.role === 'employee';
      },

      'edit eligibility questions': function () {
        const config = (this.context || {}).configuration || {};
        return (
          this.userCan('edit this configuration') &&
          (this.user.role === 'employee' ||
            (this.user.role === 'partner' && ownerService.isDIY(config.customerTier)))
        );
      },

      'be an employee': function () {
        return this.user.role === 'employee';
      },
    };
    policies.init = function () {
      authorizationService.useAll(policies);
    };
    return policies;
  })
  .run(function (policies) {
    policies.init();
  });
