'use strict';

angular.module('tailor')
  .directive('hello', function extraMenu($window, $templateCache, $compile, $rootScope, $animate, $timeout) {
    const html = $templateCache.get('views/directives/hello/messages.html');
    const messages = _.shuffle(_.filter(angular.element(html).children('.hello'), { tagName: 'DIV' }));

    let i = 0;
    function open () {
      i = i % messages.length;
      const hello = angular.element(messages[i]).clone();
      const duration = parseFloat(hello.attr('duration'), 10) || 750;

      $compile(hello)($rootScope);
      $timeout(function() {
        $animate.enter(hello, angular.element($window.document.body));
      });

      $timeout(function() {
        hello.addClass('hello-leaving');
        $timeout(function() {
          $animate.leave(hello);
        }, 250);
      }, duration);
      i++;
    }
    return {
      restrict: 'A',
      link: function ($scope, $element) {
        $element.addClass('hello-button');
        $element.on('click', open);
        $scope.$on('$destroy', function() {
          $element.removeClass('hello-button');
        });
      },
    };
  });
