'use strict';

angular.module('tailor')
  .factory('httpErrorLogger', function httpErrorLogger($q, analytics) {
    return {
      response: function(response) {
        if(response.data && response.data.error) {
          analytics.addError({
            message: response.data.error.message,
            cause: {
              status: response.status
            },
            config: response.config
          });
        }

        return response;
      },
      responseError: function(rejection) {
        if(rejection.status >= 400) {
          analytics.addError({
            message: rejection.data.status,
            cause: {
              status: rejection.status
            },
            config: rejection.config
          });
        }

        return $q.reject(rejection);
      }
    };
  });
