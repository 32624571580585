'use strict';

angular.module('tailor')
  .directive('answerRange', function answerRange() {
    return {
      templateUrl: 'views/directives/eligibility/answer-range.html',
      restrict: 'EA',
      controller: function($scope) {
        const rangeQuestion = _.find($scope.associatedQuestions, { id: $scope.question.id });
        $scope.range = rangeQuestion.range;

        $scope.operators = [
          {
            text: 'between',
            value: 'between'
          },
          {
            text: 'greater than',
            value: 'greater than'
          },
          {
            text: 'less than',
            value: 'less than'
          },
          {
            text: 'equal to',
            value: 'equal to'
          },
        ];

        function onFieldChange(event, field, value) {
          event.stopPropagation();
          const fieldName = field.split('.')[1];
          if (event.targetScope.range) {
            $scope.$emit('range:update', fieldName, value, $scope.question);
          }
        }

        $scope.$on('field:change', onFieldChange);
      }
    };
  });
