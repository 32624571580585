'use strict';

angular.module('tailor')
  .controller('NewCustomerCtrl', function NewCustomerCtrl($scope, $state, $q, customerService, loader, MAX_CUSTOMER_PATH_LENGTH) {
    $scope.customer = {
      name: '',
      informalName: '',
      customerKey: '',
      customerPath: '',
      partnerKey: '',
      url: '',
      ein: '',
    };
    $scope.editing = false;
    $scope.MAX_CUSTOMER_PATH_LENGTH = MAX_CUSTOMER_PATH_LENGTH;

    $scope.createCustomer = function(andNavigate) {
      if($scope.form.$valid) {
        loader.open('Creating ' + $scope.customer.informalName);
        customerService
          .create($scope.customer)
          .then(function(customer) {
            loader.close();
            if(andNavigate) {
              $state.go('customers.newConfiguration', {
                customerId: customer.id
              });
            } else {
              $state.go('customers.list');
            }
            return customer;
          });
      }
    };

    $scope.returnCharsRemaining = function (urlPathLength) {
      return Math.max(MAX_CUSTOMER_PATH_LENGTH - urlPathLength, 0);
    };

    const identifierRegex = /^[\w\d-]+$/;

    function validateCustomerSlug(slug, field) {
      $scope.form[field].$setValidity('slugValid', true);
      $scope.form[field].$setValidity('slugAvailable', true);

      let isValidSoFar = true;
      if(!slug || !slug.length) {
        isValidSoFar = false;
      } else if(field === 'customerPath' && slug.length > MAX_CUSTOMER_PATH_LENGTH) {
        isValidSoFar = false;
      } else if(!identifierRegex.test(slug)) {
        isValidSoFar = false;
      }

      if(!isValidSoFar) {
        $scope.form[field].$setValidity('slugValid', isValidSoFar);
        return $q.when($scope.form[field].$valid);
      } else {
        $scope.form[field].$setValidity('slugValid', true);
        return customerService.checkSlugAvailability(slug, field).then(function(result) {
          // it's possible that the field has changed
          // while this request was running over the network
          // only update the validity if the tested slug matches the current value
          if ($scope.form[field].$modelValue === slug) {
            $scope.form[field].$setValidity('slugAvailable', result);
            return $scope.form[field].$valid;
          }
        });
      }
    }

    $scope.$watch('customer.name', function(newValue) {
      if($scope.form.customerPath.$pristine && newValue) {
        // automatically fill in the slug
        const autoSlug = newValue.toLowerCase().replace(/\s+/g, '-').replace(/[^\w\d-]+/g, '');
        $scope.customer.customerPath = autoSlug;
        if ($scope.user.role !== 'employee') {
          $scope.customer.customerKey = autoSlug;
        }
      }
    });
    $scope.$watch('customer.customerKey', function(slug) {
      validateCustomerSlug(slug, 'customerKey');
      if (slug === '') {
        $scope.form.customerKey.$setPristine(true);
      }
    });
    $scope.$watch('customer.customerPath', function(slug) {
      validateCustomerSlug(slug, 'customerPath');
      if (slug === '') {
        $scope.form.customerPath.$setPristine(true);
      }
    });
  });
