'use strict';

angular
  .module('tailor')
  .factory(
    'configurationService',
    function configurationService(
      $rootScope,
      $q,
      $timeout,
      $http,
      API,
      customerService,
      moduleDefinitionService,
      unitService,
      ownerService,
      TIER_CAPABILITIES
    ) {
      // this method expects that the passed configuration already has
      // it's Modules and Units loaded via get(config_id, true)
      function getConfigurationUnit(config, moduleName, unitName) {
        const module = config.Modules.find(m => m.name === moduleName);
        return module ? module.Units.find(u => u.name === unitName) : undefined;
      }

      function getConfigurationsForCustomer(customer) {
        return $http.get(API.base + '/customers/' + customer.id).then(function (response) {
          const configurations = response.data.Configurations;
          _.each(configurations, function (config) {
            config.customer = customer;
          });
          return response.data.Configurations;
        });
      }

      function getConfigurations(configIds) {
        return customerService.getCustomers(configIds).then(function (customers) {
          const configurations = [];
          _.each(customers, function (customer) {
            _.each(customer.Configurations, function (config) {
              config.customer = customer;
              configurations.push(config);
            });
          });
          return configurations;
        });
      }

      function get(id, includeUnitData) {
        return $http
          .get(API.base + '/configurations/' + id)
          .then(function (response) {
            return response.data;
          })
          .then(function (config) {
            includeUnitData = typeof includeUnitData !== 'undefined' ? includeUnitData : true;
            if (includeUnitData) {
              return loadAllUnits(config).then(mixInModuleDefinition);
            } else {
              return config;
            }
          });
      }

      function getQuestionsWithUsage(id) {
        return $http
          .get(API.base + '/configurations/' + id + '/questions')
          .then(function (response) {
            return _.sortBy(response.data, ['key', 'createdAt']);
          });
      }

      function search(query) {
        return $http
          .get(API.base + '/configurations/operations/search', {
            params: query,
          })
          .then(function (response) {
            return {
              list: _.map(response.data.rows, function (data) {
                data.customer = data.Customer;
                delete data.Customer;
                const bcModule = data.Modules.find(function (module) {
                  return module.name === 'benefits_counselor_evergreen';
                });
                data.expirationDate =
                  bcModule &&
                  bcModule.expirationDate &&
                  moment(bcModule.expirationDate).format('M/D/YY');
                if (bcModule && bcModule.Units) {
                  const bcUnit = bcModule.Units.find(function (unit) {
                    return unit.name === 'settings';
                  });
                  if (bcUnit && bcUnit.planYear) {
                    data.planYear = bcUnit.planYear;
                  }
                }
                return data;
              }),
              totalCount: response.data.count,
            };
          });
      }

      function loadAllUnits(configuration) {
        return $q
          .all(
            configuration.Modules.map(function (module) {
              return $q.all(
                module.Units.map(function (unit) {
                  unit.data = unit.data || {};
                  return unitService.get(unit.id).then(function (u) {
                    unit.data = u.data;
                  });
                })
              );
            })
          )
          .then(function () {
            return configuration;
          });
      }

      function getChanges(configuration, startDate, endDate) {
        $http({
          url: API.base + '/configurations/' + configuration.id + '/changes',
          method: 'GET',
          params: { startDate, endDate },
        }).then(function (response) {
          const whatChanged = response.data;
          configuration.edited = whatChanged.edited;
          let i, j, k;
          for (i = 0; i < configuration.Products.length; i++) {
            configuration.Products[i].edited = false;
          }

          for (i = 0; i < configuration.Modules.length; i++) {
            configuration.Modules[i].edited = false;
            for (j = 0; j < configuration.Modules[i].Units.length; j++) {
              configuration.Modules[i].Units[j].edited = false;
            }
          }

          if (whatChanged.edited) {
            for (i = 0; i < whatChanged.Products.length; i++) {
              const currentProduct = whatChanged.Products[i];
              _.find(configuration.Products, { id: currentProduct.id }).edited =
                currentProduct.edited;
            }

            for (j = 0; j < whatChanged.Modules.length; j++) {
              const currentModule = whatChanged.Modules[j];
              _.find(configuration.Modules, { id: currentModule.id }).edited = currentModule.edited;
              if (currentModule.edited) {
                for (k = 0; k < whatChanged.Modules[j].Units.length; k++) {
                  const currentUnit = whatChanged.Modules[j].Units[k];
                  const currentScopeModule = _.find(configuration.Modules, {
                    id: currentModule.id,
                  });
                  if (_.find(currentScopeModule.Units, { id: currentUnit.id })) {
                    _.find(currentScopeModule.Units, {
                      id: currentUnit.id,
                    }).edited = currentUnit.edited;
                  }
                }
              }
            }
          }
        });
      }

      const defaultModuleOrders = {
        benefits_counselor_evergreen: 0,
        medical_plan: 1,
        dental_plan: 2,
        vision_plan: 3,
        life: 4,
        disability: 5,
        accident: 6,
        critical_illness: 7,
        hospital_indemnity: 8,
        pharmacy: 9,
        eap: 10,
        pet: 11,
        cancer: 12,
        enrollment: 13,
        retirement: 14,
        tax_savings: 15,
        health_care_fsa: 16,
        custom_module_1: 17,
        custom_module_2: 18,
        custom_module_3: 19,
        custom_module_4: 20,
        custom_module_5: 21,
        details_page: 22,
        chat: 23,
      };

      function mixInModuleDefinition(configuration) {
        const promises = [];
        _.each(configuration.Modules, function (module) {
          if (!module.definition) {
            const promise = moduleDefinitionService.get(module).then(function (definition) {
              module.commentCount = 0;
              module.definition = definition;
              module.valid = true;
              module.displayOrder =
                typeof module.displayOrder !== 'undefined'
                  ? module.displayOrder
                  : defaultModuleOrders[module.name];
              _.each(module.Units, function (unit) {
                unit.definition = _.find(definition.unitDefinitions, {
                  name: unit.name,
                });
                unitService.parseSectionsAndComments(unit, module);
                module.valid = module.valid && unit.data.valid;
              });
            });
            promises.push(promise);
          }
        });
        return $q.all(promises).then(function () {
          return configuration;
        });
      }

      function getEvents(configuration) {
        return $http
          .get(API.base + '/configurations/' + configuration.id + '/events')
          .then(function (response) {
            configuration.Events = response.data;
            return response.data;
          });
      }

      function update(configuration, updates) {
        return $http
          .patch(API.base + '/configurations/' + configuration.id, updates)
          .then(function (response) {
            return response.data;
          });
      }
      function updateProduct(configuration, product) {
        const updates = {
          products: [product],
        };
        return $http
          .patch(API.base + '/configurations/' + configuration.id, updates)
          .then(function () {
            const existingProduct = _.find(configuration.Products, {
              id: product.id,
            });
            if (!product.active) {
              _.remove(configuration.Products, { id: product.id });
              return configuration;
            }
            if (existingProduct) {
              return configuration;
            } else {
              return get(configuration.id).then(function (updatedConfig) {
                // in case a new product / module was added, merge it into the existing config;
                const newProducts = _.filter(updatedConfig.Products, function (p) {
                  return !_.find(configuration.Products, { id: p.id });
                });
                configuration.Products = configuration.Products.concat(newProducts);
                _.each(updatedConfig.Modules, function (module) {
                  let moduleOnConfig = _.find(configuration.Modules, {
                    id: module.id,
                  });
                  if (!moduleOnConfig) {
                    configuration.Modules.push(module);
                    moduleOnConfig = module;
                  }
                  moduleOnConfig.active = module.active;
                  _.each(module.Units, function (unit) {
                    const unitOnConfig = _.find(moduleOnConfig.Units, {
                      id: unit.id,
                    });
                    if (!unitOnConfig) {
                      moduleOnConfig.Units.push(unit);
                    }
                  });
                });
              });
            }
          });
      }

      function create(configuration, customer) {
        return $http
          .post(API.base + '/customers/' + customer.id + '/configurations', configuration)
          .then(function (response) {
            return response.data;
          });
      }

      function publish(configuration, consumer, invoice) {
        invoice = invoice || {};
        if (consumer) {
          return $http
            .post(
              API.base + '/configurations/' + configuration.id + '/consumer/' + consumer.id,
              invoice
            )
            .then(function (response) {
              return response.data;
            });
        } else {
          return $http
            .post(API.base + '/configurations/' + configuration.id + '/publish')
            .then(function (response) {
              return response.data;
            });
        }
      }
      function previewPublishJSON(configuration, consumer, product) {
        // todo: we should probs store the key on each consumer row,
        // this should be less magic string-y
        const consumerKeyParts = consumer.url.split('/');
        const consumerKey = consumerKeyParts[consumerKeyParts.length - 1];
        return $http
          .get(
            API.base +
              '/configurations/' +
              configuration.id +
              '/consumers/' +
              consumerKey +
              '?productKey=' +
              product.key
          )
          .then(function (response) {
            return response.data;
          });
      }

      function postResource(configuration, consumer, product, resource) {
        const consumerKeyParts = consumer.url.split('/');
        const consumerKey = consumerKeyParts[consumerKeyParts.length - 1];
        return $http
          .post(
            API.base +
              '/configurations/' +
              configuration.id +
              '/consumers/' +
              consumerKey +
              '?productKey=' +
              product.productKey +
              '&resource=' +
              resource
          )
          .then(function (response) {
            return response.data;
          });
      }

      function getResource(configuration, consumer, product, resource) {
        const consumerKeyParts = consumer.url.split('/');
        const consumerKey = consumerKeyParts[consumerKeyParts.length - 1];
        return $http
          .get(
            API.base +
              '/configurations/' +
              configuration.id +
              '/consumers/' +
              consumerKey +
              '?productKey=' +
              product.productKey +
              '&resource=' +
              resource
          )
          .then(function (response) {
            return response.data;
          });
      }

      function duplicate(configuration, customer, configUpdates, unitUpdates, incrementYears) {
        const updates = {
          configUpdates,
          unitUpdates,
          incrementYears,
        };
        return $http
          .post(
            API.base +
              '/customers/' +
              customer.id +
              '/configurations/' +
              configuration.id +
              '/duplicate',
            updates
          )
          .then(function (response) {
            return response.data;
          });
      }

      function remove(configuration) {
        return $http
          .delete(API.base + '/configurations/' + configuration.id)
          .then(function removeLocally() {
            return configuration;
          });
        // .then(function(configuration) {
        //   analytics.addAction({
        //     eventAction: 'delete',
        //     eventLabel: 'configuration',
        //     eventValue: configuration.id
        //   });
        // });
      }

      function lock(configuration) {
        return $http
          .patch(API.base + '/configurations/' + configuration.id, {
            locked: true,
          })
          .then(function () {
            configuration.locked = true;
          });
      }

      function unlock(configuration) {
        return $http
          .patch(API.base + '/configurations/' + configuration.id, {
            locked: false,
          })
          .then(function () {
            configuration.locked = false;
          });
      }

      function validate(configuration) {
        return get(configuration.id).then(function (result) {
          configuration.valid = result.valid;
          return configuration;
        });
      }

      /*
    function checkSlugAvailability(slug) {
      // todo: fixme: make this integrate with the API
      return $q.when(true);
    }
    */

      function canSeeSection(configuration, section, customerTier) {
        const isBcSettingsModule = _.find(configuration.Modules, function (module) {
          return module.name === 'benefits_counselor_evergreen';
        });
        const isBcSettingsUnit = _.find(isBcSettingsModule.Units, function (unit) {
          return unit.name === 'settings';
        });
        const isGoOnlyTier = TIER_CAPABILITIES.GO_ONLY.includes(customerTier);

        // If the section is 'Settings' and the tier is in GO_ONLY, hide the section
        if (section.name === 'Settings' && isGoOnlyTier) {
          return false;
        }

        // Check for Cancer Insurance module and hide "Settings" if needed
        const cancerInsuranceModule = _.find(configuration.Modules, { name: 'cancer_insurance' });
        if (
          section.name === 'Settings' &&
          cancerInsuranceModule &&
          TIER_CAPABILITIES.GO_ONLY.includes(customerTier)
        ) {
          const hasIntroNoteField = _.find(cancerInsuranceModule.children, {
            name: 'has_intro_note',
          });
          const introNoteShortField = _.find(cancerInsuranceModule.children, {
            name: 'intro_note_short',
          });
          const introNoteTextField = _.find(cancerInsuranceModule.children, {
            name: 'intro_note_text',
          });

          // Check if has_intro_note is hidden or explicitly false
          const isHasIntroNoteHidden = hasIntroNoteField.attributes.hiddenToTierGroup === 'GO_ONLY';
          const isIntroNoteHidden =
            (introNoteShortField.attributes.hiddenToTierGroup === 'GO_ONLY' &&
              introNoteTextField.attributes.hiddenToTierGroup === 'GO_ONLY') ||
            isHasIntroNoteHidden ||
            hasIntroNoteField.defaultValue === false;

          if (isIntroNoteHidden) {
            return false; // Hide the entire Settings section
          }
        }
        if (
          section.name === 'Publishing' ||
          (isBcSettingsUnit.data.use_comm_api.value &&
            section.name === 'Module Order' &&
            TIER_CAPABILITIES['GO_ONLY'].some(t => t.toLowerCase() === customerTier.toLowerCase()))
        ) {
          return false;
        }

        if(TIER_CAPABILITIES.GO_ONLY.includes(customerTier)
          && (section.name === 'Raw costs' || section.name === 'Healthcare Vendor Solutions')) {
          return false;
        }

        return _.some(section.definition, function (field) {
          return _.includes(
            field.serviceLevels || [],
            ownerService.lookupServiceLevel(configuration.customerTier).toLowerCase()
          )
            ? !field.ownerOnly || (field.ownerOnly && ownerService.isOwner(customerTier))
            : false;
        });
      }

      return {
        getConfigurationsForCustomer,
        getConfigurations,
        create,
        defaultModuleOrders,
        get,
        getEvents,
        getChanges,
        getResource,
        getQuestionsWithUsage,
        postResource,
        publish,
        previewPublishJSON,
        duplicate,
        search,
        lock,
        unlock,
        validate,
        update,
        updateProduct,
        remove,
        canSeeSection,
        // checkSlugAvailability: checkSlugAvailability,
        getConfigurationUnit,
      };
    }
  );
