'use strict';

angular.module('tailor')
  .factory('loginCtrl', function(btfModal) {
    return btfModal({
      controller: function() {},
      controllerAs: 'ctrl',
      templateUrl: 'views/login.html'
    });
  });
