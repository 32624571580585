'use strict';

angular.module('tailor').directive('focusManager', function FocusManager() {
  return {
    restrict: 'A',
    // eslint-disable-next-line
    controller: function FocusManagerCtrl($scope, $element) {
      // TODO
      // FIXME
      // This code is currently disabled as it was conflicting with custom template updates.
      // After the custom template refactor is complete, we should work to get this back in.
      // $scope.$on('field:keypress:enter', function onEnterPress(ngEvent, jqEvent) {
      //     var $fields = $element.find('[tabindex=0]').filter(':visible');
      //     var currentIndex = $fields.index(jqEvent.target);
      //     var nextField = $fields.get(currentIndex + 1);
      //     if(nextField) {
      //         nextField.focus();
      //     }
      // });
    },
  };
});

angular.module('tailor').directive('focusFirst', function focusFirst() {
  return {
    restrict: 'A',
    // eslint-disable-next-line
    link: function (scope, element, attrs) {
      // attrs.$observe('focusFirst', function(newValue){
      //     if(newValue === 'true'){
      //         element.find(".focusable")
      //             .addClass("visited")
      //             .focus();
      //     }
      // });
    },
  };
});
