'use strict';

angular
  .module('tailor')
  .factory(
    'consumerService', function consumerService(
      API,
      $http
    ) {
      function getResource(consumerName, resource, params) {
        return $http
          .get(
            API.base + '/consumers/' + consumerName + '/resource/' + resource,
            { params }
          )
          .then(function (response) {
            return response.data;
          });
      }

      return {
        getResource,
      };
    }
  );
