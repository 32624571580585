'use strict';

angular.module('tailor')
  .controller('EligibilityCtrl',
    function EligibilityCtrl($scope, questionsService, choicesService, questionsWithUsage) {
      $scope.configuration.Questions = questionsWithUsage || $scope.configuration.Questions;
      $scope.questions = $scope.configuration.Questions;

      function createQuestionWithoutUnit() {
        questionsService.create($scope.configuration);
      }
      function onQuestionDelete(question) {
        _.remove($scope.configuration.Questions, question);
        questionsService.remove(question);
      }
      function onQuestionUpdate(event, question, field, value) {
        questionsService.update(question, field, value);
      }
      function onChoiceCreate(event, question) {
        choicesService.create(question);
      }
      function onChoiceUpdate(event, choice, field, value) {
        choicesService.update(choice, field, value);
      }
      function onChoiceDelete(event, choice) {
        choicesService.remove(choice);
      }

      $scope.$watchCollection('questions', function() {
        _.each($scope.questions, function(q) {
          q.inUse = !_.isEmpty(q.usages);
        });
      });
      $scope.addQuestion = createQuestionWithoutUnit;
      $scope.deleteQuestion = onQuestionDelete;
      $scope.$on('question:update', onQuestionUpdate);
      $scope.$on('choice:create', onChoiceCreate);
      $scope.$on('choice:update', onChoiceUpdate);
      $scope.$on('choice:remove', onChoiceDelete);
    });
