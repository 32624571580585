'use strict';

angular.module('tailor')
  .controller('LockCtrl', function LockCtrl($scope, $http, $state, $q, configurationService, generalModal, loader, lockModal) {
    // function displayConfigurationIsInvalidModal() {
    //   generalModal.activate({
    //     headerText: 'Almost there!',
    //     text: 'The benefit configuration isn\'t ready to be locked yet — it looks like you missed a few fields here and there. Look for the orange asterisks in the menu on the left to figure out where you still need to fill in some answers.',
    //     okText: 'Okay, thanks for letting me know!'
    //   });
    // }

    $scope.confirmLockConfiguration = function confirmLockConfiguration(event, configuration, customer) {
      event.stopPropagation();
      lockModal.activate({
        model: {
          configuration,
          customer
        },
        confirm: $scope.lockConfiguration,
        cancel: angular.noop,
        headerText: 'Lock Configuration',
        text: 'You will not be able to edit this configuration while it is locked. This will prevent you from accidentally making changes to an ALEX experience your customer is reviewing or an experience that\'s already published.',
        okText: 'Lock it!',
        cancelText: 'Oops, forget it.'
      });
    };


    $scope.lockConfiguration = function lockConfiguration() {
      return configurationService.lock($scope.configuration);
      // TAILOR-29 validate configuration
      // loader.open('Validating configuration');
      // configurationService.validate($scope.configuration).then(function(configuration) {
      // loader.close();
      // if(configuration.valid) {
      // return configurationService.lock($scope.configuration);
      // } else {
      //   return $q(function(resolve, reject) { reject(new Error('Configuration is invalid!')); });
      // }

      // }).catch(function(/*e*/) {
      //   displayConfigurationIsInvalidModal();
      // });
    };

    $scope.unlockConfiguration = function unlockConfiguration() {
      configurationService.unlock($scope.configuration);
    };

    // Occasionally the benefit configuration gets locked because more than one person is working on it at a time.
    // So if we try to modify something and the benefit configuration is locked, it'll broadcast an error:locked event.
    // c.f. services/interceptors/locked-error-interceptor.js.
    // In this case, we display a modal that asks the user if they want to unlock the config and retry the change.
    // This is that modal. (LAW AND ORDER NOISE)
    $scope.$on('error:locked', function(event, httpResponse) {
      function unlockAndRetry() {
        configurationService
          .unlock()
          .then(function retryRequest() {
            return $http(httpResponse.config);
          });
      }

      function lockAndReload() {
        configurationService
          .lock()
          .then($state.reload);
      }

      // Pull the level, Kronk!
      generalModal.activate({
        headerText: 'This benefit configuration is locked.',
        text: "This configuration can't be updated because it was locked by someone else. Do you want to unlock it now?",

        okText: 'Yes, unlock the configuration',
        confirm: unlockAndRetry,

        cancelText: 'No, keep it unlocked',
        cancel: lockAndReload
      });
    });
  });
