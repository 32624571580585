'use strict';
angular
  .module('tailor')
  .factory(
    'unitService',
    function unitService(
      $debounce,
      $http,
      $httpParamSerializerJQLike,
      $q,
      $window,
      API,
      DEBOUNCE_RATE,
      commentsService,
      encyclopediasService,
      ownerService
    ) {
      function baseUrl(unitOrId) {
        const id = Object.hasOwn(unitOrId, 'id') ? unitOrId.id : unitOrId;
        return `${API.base}/units/${id}`;
      }

      function get(id) {
        return $http.get(baseUrl(id)).then(function (response) {
          return response.data;
        });
      }

      function getComments(id) {
        return $http.get(baseUrl(id) + '/comments').then(function (response) {
          return response.data;
        });
      }

      function create(module, name) {
        return $http
          .post(API.base + '/modules/' + module.id + '/units', { name })
          .then(function (response) {
            return response.data;
          })
          .then(attachDefinitionsToUnit(module));
      }

      function uploadFile(unit, data, file, opts) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('data', JSON.stringify(data));

        // Override $http's settings so that we don't transform the request at
        // all or use application/json as the Content-Type.  In this case, it's
        // because Content-Type needs to be multipart/form-data, which has a
        // boundary string that we can't predict to separate the parts. So we're
        // just gonna let $http handle it.
        const config = {
          transformRequest: angular.identity,
          headers: {
            'Content-Type': undefined,
          },
        };

        const url = baseUrl(unit) + '/file?useLegacyFileStructure=' + opts.useLegacyFileStructure;
        return $http.post(url, formData, config).then(function (response) {
          return response.data;
        });
      }

      function update(unit, data) {
        return throttledUpdate(unit, data);
      }
      function actuallyUpdate(unit, data) {
        return $http.patch(baseUrl(unit), data).then(function (response) {
          return response.data;
        });
      }

      // aggregate requests in [DEBOUNCE_RATE] blocks;
      const throttledUpdates = {};
      function throttledUpdate(unit, data) {
        throttledUpdates[unit.id] = throttledUpdates[unit.id] || {};
        throttledUpdates[unit.id].deferred = throttledUpdates[unit.id].deferred || $q.defer();
        throttledUpdates[unit.id].data = throttledUpdates[unit.id].data || {};
        throttledUpdates[unit.id].data = _.merge(throttledUpdates[unit.id].data, data);
        throttledUpdates[unit.id].request =
          throttledUpdates[unit.id].request ||
          $debounce(function () {
            const deferred = throttledUpdates[unit.id].deferred;
            actuallyUpdate(unit, throttledUpdates[unit.id].data).then(function (response) {
              deferred.resolve(response);
              return response;
            });
            delete throttledUpdates[unit.id];
          }, DEBOUNCE_RATE);
        throttledUpdates[unit.id].request();
        return throttledUpdates[unit.id].deferred.promise;
      }
      $window.onbeforeunload = function (event) {
        if (_.keys(throttledUpdates).length > 0) {
          event.preventDefault();
          return 'Some of your changes are still saving. If you leave the page now, your changes will be lost. Are you sure you want to leave the page?';
        }
      };

      function deleteFile(unitId, fieldName) {
        return $http.delete(baseUrl(unitId) + '/file/' + fieldName, {}).then(function (response) {
          return response.data;
        });
      }
      function sanitizeFieldName(fieldName) {
        const sanitized = fieldName.replace(/[^a-zA-Z0-9\-_.]/g, '');
        return sanitized;
      }

      function getFileURL(unitId, fieldName) {
        return $http.get(baseUrl(unitId) +
        '/file/' + sanitizeFieldName(fieldName), {}).then(function (response) {
          return response.data;
        });
      }

      function deleteCollectionItem(unit, collection, path) {
        const data = {
          field: path,
        };
        return $http({
          method: 'DELETE',
          url: baseUrl(unit) + '/data',
          data,
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }
      function addCollectionItem(unit, collection, path) {
        const data = {
          field: path,
        };
        return $http.post(baseUrl(unit) + '/data', data).then(function (response) {
          collection[response.data.id] = response.data;
          return collection;
        });
      }

      function duplicate(unit, module) {
        return $http
          .post(baseUrl(unit) + '/duplicate', {})
          .then(function (response) {
            return response.data;
          })
          .then(attachDefinitionsToUnit(module));
      }

      function attachDefinitionsToUnit(module) {
        return function (unit) {
          unit.definition = _.find(module.definition.children, {
            name: unit.name,
          });
          parseSectionsAndComments(unit);
          module.Units.push(unit);
          return unit;
        };
      }

      function remove(unit, module) {
        return $http.delete(baseUrl(unit)).then(function (response) {
          _.remove(module.Units, unit);
          return response;
        });
      }

      function addComment(unit, commentData) {
        return $http.post(baseUrl(unit) + '/comments', commentData);
      }
      function history(unit, options) {
        options = _.pickBy(options);
        return $http
          .get(baseUrl(unit) + '/history?' + $httpParamSerializerJQLike(options))
          .then(function (response) {
            return response.data;
          });
      }
      function revisions(unit, options) {
        options = _.pickBy(options);
        return $http
          .get(baseUrl(unit) + '/revisions?' + $httpParamSerializerJQLike(options))
          .then(function (response) {
            return response.data;
          });
      }

      function downloadCSV(unit, collection, path) {
        return $http
          .get(baseUrl(unit) + '/data?path=' + path + '&format=text/csv')
          .then(function (response) {
            const headers = response.headers();
            const filename = ((unit.data.name || {}).value || 'plan') + '.csv';
            const blob = new Blob([response.data], { type: headers['content-type'] });
            const url = URL.createObjectURL(blob);
            // https://stackoverflow.com/questions/46232980/click-giving-access-denied-in-ie11
            if (navigator.msSaveOrOpenBlob) {
              navigator.msSaveOrOpenBlob(blob, filename);
            } else {
              // https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
              const a = document.createElement('a');
              document.body.appendChild(a);
              if (typeof a.style === 'string') {
                a.style = 'display: none';
              } else {
                a.style.display = 'none';
              }
              a.href = url;
              a.download = filename;
              a.click();
              document.body.removeChild(a);
            }
          });
      }

      // Side-effect: mark sections within unit "valid" for modules that are visible
      function prevalidate(unit, customerTier) {
        unit.sections.forEach(function checkSectionsValid(section) {
          section.valid = _.reduce(
            section.definition,
            function (sectionValid, field) {
              const fieldInaccessible =
                field.serviceLevels.indexOf(
                  ownerService.lookupServiceLevel(customerTier).toLowerCase()
                ) === -1;
              if (section.data[field.name]) {
                return (
                  sectionValid &&
                  (section.data[field.name].valid ||
                    section.data[field.name].hidden ||
                    fieldInaccessible)
                );
              } else {
                return sectionValid;
              }
            },
            true
          );
        });
      }

      /**
       * @param {Object} unit
       * @returns {string} nickname, name, or title of unit, depending on what's defined
       */
      function getUnitDisplayName(unit) {
        if (
          unit.data.has_nickname &&
          unit.data.has_nickname.value &&
          unit.data.nickname &&
          unit.data.nickname.value
        ) {
          return unit.data.nickname.value;
        }

        if (unit.data.name && unit.data.name.value) {
          return unit.data.name.value;
        }

        return unit.definition.title;
      }

      function parseSectionsAndComments(unit, module) {
        module = module || { commentCount: 0 };
        unit.commentCount = 0;
        let sections = _.uniq(_.map(unit.definition.children, 'section'));
        sections = _.map(sections, function (sectionName) {
          return {
            name: sectionName,
            data: unit.data,
            definition: _.filter(unit.definition.children, {
              section: sectionName,
            }),
          };
        });
        for (const i in sections) {
          const section = sections[i];
          section.commentCount = 0;
          for (const j in section.definition) {
            const fieldName = section.definition[j].name;
            if (Object.hasOwn(unit.data, fieldName)) {
              const commentCount = commentsService.getCommentCount(unit.data[fieldName], fieldName);
              unit.commentCount += commentCount;
              section.commentCount += commentCount;
              module.commentCount += commentCount;
            }
          }
        }
        unit.sections = sections;
      }

      function getEncyclopediaContent(encyclopediaId) {
        return encyclopediasService.get(encyclopediaId);
      }

      return {
        addComment,
        create,
        downloadCSV,
        get,
        getComments,
        update,
        remove,
        duplicate,
        addCollectionItem,
        deleteCollectionItem,
        deleteFile,
        getFileURL,
        uploadFile,
        history,
        revisions,
        parseSectionsAndComments,
        prevalidate,
        getEncyclopediaContent,
        getUnitDisplayName,
      };
    }
  );
