'use strict';

angular
  .module('tailor')
  .directive(
    'collection',
    function collection(authorizationService, commentsService, historyService) {
      return {
        templateUrl: 'views/directives/fields/collection.html',
        transclude: true,
        scope: {},
        restrict: 'A',
        require: ['^ngModel'],
        controller: function ($scope) {
          authorizationService.addAuthToScope($scope);
          $scope.definition = $scope.$parent.definition;
          $scope.unit = $scope.$parent.unit;
          $scope.settings = $scope.$parent.settings;
          $scope.module = $scope.$parent.module;
          $scope.configuration = $scope.$parent.configuration;
          $scope.customer = $scope.$parent.customer;
          $scope.unitDisplayName = $scope.$parent.unitDisplayName;
          $scope.user = $scope.$parent.user;

          $scope.model = $scope.$parent.model[$scope.definition.name];
          $scope.collection = $scope.$parent.model[$scope.definition.name].value;

          $scope.collectionName = {
            singular: $scope.definition.attributes.nameSingular || 'item',
            plural: $scope.definition.attributes.namePlural || 'items',
          };

          $scope.$watch('$parent.history', function () {
            if ($scope.$parent.history) {
              $scope.fieldHistory = historyService.computeChanges(
                $scope.definition.name + '.value',
                $scope.$parent.history
              );
              $scope.history = $scope.$parent.history;
            }
          });
          function itemHistory (item) {
            try {
              return historyService.computeChanges(String(item.id), $scope.fieldHistory);
            } catch (e) {
              return [];
            }
          }
          this.itemHistory = itemHistory;

          function addItem() {
            $scope.$emit('collection:addItem', $scope.collection, $scope.definition.name);
          }
          function deleteItem(event, item) {
            event.stopPropagation();
            delete $scope.collection[item.id];
            $scope.$emit(
              'collection:deleteItem',
              $scope.collection,
              $scope.definition.name + '.value.' + item.id
            );
          }
          function selectItem(item) {
            $scope.activeItem = item;
          }
          function hasValidChoiceAnswer(q) {
            return q.answers && q.answers.length;
          }
          function hasValidZipAnswer(q) {
            return q.zipListId != null && q.fileName != null;
          }
          function hasValidRangeAnswer(q) {
            if (q.range) {
              switch (q.range.operator) {
                case 'between':
                  return (q.range.min_value || q.range.min_value === 0) && q.range.max_value;
                case 'equal to':
                case 'greater than':
                  return q.range.min_value;
                case 'less than':
                  return q.range.max_value;
                default:
                  return false;
              }
            }
          }
          function sortCollection() {
            let foundActiveItem = false;
            $scope.items = _.map($scope.collection, function (item) {
              if ($scope.activeItem === item) {
                foundActiveItem = true;
              }
              const valid = _.reduce(
                $scope.definition.children,
                function (itemValid, childField) {
                  if (childField.type === 'eligibility') {
                    // similar logic exists in validateAvailableToAll in
                    // eligibility directive
                    const collectionSize = Object.keys($scope.collection).length;
                    const availToAll = item[childField.name].value.available_to_all;
                    if (collectionSize > 1 && availToAll) {
                      // if there are multiple items in the collection then the
                      // item cannot be available to all employees, so
                      // eligibility validation fails
                      return false;
                    } else if (collectionSize === 1 && availToAll) {
                      // if only one item in the collection, the item can be
                      // available to all employees
                      return itemValid && true;
                    } else {
                      // otherwise base the item's eligibility validity on
                      // whether or not there is at least one question associated
                      // and all question's choices (choice/range/zip) are valid
                      const questions = item[childField.name].value.associated_questions;
                      const validAnswers = _.every(questions, function (q) {
                        return (
                          hasValidChoiceAnswer(q) || hasValidZipAnswer(q) || hasValidRangeAnswer(q)
                        );
                      });
                      return itemValid && Boolean(questions.length >= 1) && validAnswers;
                    }
                  } else {
                    return (
                      itemValid &&
                      (childField.type === 'label' ||
                        childField.type === 'group' ||
                        (item[childField.name] || { valid: true }).valid ||
                        (item[childField.name] || { hidden: true }).hidden)
                    );
                  }
                },
                true
              );
              const history = itemHistory(item);
              return {
                id: item.id,
                name: item.name,
                history,
                valid,
                data: item,
                definition: $scope.definition,
                commentCount: commentsService.getCommentCount(item),
              };
            }).sort(function (a, b) {
              return parseInt(a.id, 10) > parseInt(b.id, 10);
            });
            $scope.collectionLength = $scope.items.length;

            if (!foundActiveItem) {
              selectItem($scope.items[0]);
            }
          }

          function reEmitCommentAdd(event, fieldName, comment, commentsArray) {
            event.stopPropagation();
            $scope.$parent.$emit(
              'field:addComment',
              $scope.definition.name + '.value.' + event.targetScope.model.id + '.' + fieldName,
              comment,
              commentsArray
            );
          }

          function openUploadCSVModal() {
            $scope.$emit('collection:upload');
          }

          function downloadCollectionCSV() {
            if ($scope.definition.updateAcrossAllUnits) {
              $scope.$emit('collection:download', $scope.definition, $scope.definition.name);
            } else {
              $scope.$emit(
                'collection:downloadAcrossAllUnits',
                $scope.definition,
                $scope.definition.name
              );
            }
          }

          function downloadItemCSV(item) {
            if ($scope.definition.updateAcrossAllUnits) {
              $scope.$emit(
                'collection:download',
                $scope.definition,
                $scope.definition.name + '.' + item.id
              );
            } else {
              $scope.$emit(
                'collection:downloadAcrossAllUnits',
                $scope.definition,
                $scope.definition.name + '.' + item.id
              );
            }
          }

          $scope.$on('addedCollectionItem', function (event, collectionValue, path) {
            if ($scope.definition.updateAcrossAllUnits) {
              const otherPlans = $scope.module.Units.filter(function (unit) {
                return unit.name === $scope.unit.name;
              }).filter(function (unit) {
                return unit.id !== $scope.unit.id;
              });
              otherPlans.forEach(function (plan) {
                plan.data[path].value = collectionValue;
              });
            }
          });

          $scope.$on('removedCollectionItem', function (event, path) {
            if ($scope.definition.updateAcrossAllUnits) {
              const otherPlans = $scope.module.Units.filter(function (unit) {
                return unit.name === $scope.unit.name;
              }).filter(function (unit) {
                return unit.id !== $scope.unit.id;
              });
              otherPlans.forEach(function (plan) {
                _.unset(plan.data, path);
              });
            }
          });

          // TAILOR-190
          const required = _.find($scope.definition.validations, {
            key: 'required',
            value: true,
          });
          const minNumberOfItems = (
            _.find($scope.definition.validations, { key: 'minItems' }) || {
              value: required ? 1 : 0,
            }
          ).value;
          const maxNumberOfItems = (
            _.find($scope.definition.validations, { key: 'maxItems' }) || {
              value: Infinity,
            }
          ).value;

          $scope.minItems = minNumberOfItems;
          $scope.maxItems = maxNumberOfItems;

          $scope.addItem = addItem;
          $scope.selectItem = selectItem;
          $scope.deleteItem = deleteItem;
          $scope.hasValidRangeAnswer = hasValidRangeAnswer;
          $scope.openUploadCSVModal = openUploadCSVModal;
          $scope.downloadCollectionCSV = downloadCollectionCSV;
          $scope.downloadItemCSV = downloadItemCSV;
          $scope.$on('field:addComment', reEmitCommentAdd);
          $scope.$watchCollection('collection', sortCollection);
          $scope.$watchCollection('history', sortCollection);
        },
        link: function ($scope, $element, attrs, ctrls, $transclude) {
          const ngModel = ctrls[0];
          const rightPanel = $element
            .children('.collection-wrapper')
            .children('.collection')
            .children('.collection-fields');

          let oldTranscludeScope;
          function render() {
            $transclude(function (transcludeElement, transcludeScope) {
              if (oldTranscludeScope) {
                oldTranscludeScope.$destroy();
              }
              oldTranscludeScope = transcludeScope;
              transcludeScope.model = $scope.activeItem.data;
              transcludeScope.form = $scope.form;
              transcludeScope.activeItem = $scope.activeItem;
              transcludeScope.activeItemForm = $scope.activeItemForm;
              rightPanel.html(transcludeElement);
            });
          }
          $scope.$watch('[collectionLength, activeItemForm.$valid]', function (results) {
            const valid = results[1];
            if ($scope.activeItem) {
              $scope.activeItem.valid = valid;
            }
            const allItemsValid = _.reduce(
              $scope.items,
              function (currentValidity, item) {
                return currentValidity && item.valid;
              },
              true
            );
            ngModel.$setValidity('collectionItemsValid', allItemsValid);
          });
          $scope.$watch('collectionLength', function () {
            ngModel.$setValidity(
              'collectionHasMinItems',
              $scope.collectionLength >= $scope.minItems
            );
            ngModel.$setValidity(
              'collectionHasMaxItems',
              $scope.collectionLength <= $scope.maxItems
            );
          });
          $scope.$watch('activeItem', render);

          function reEmitFieldChange(event, ngModelName, newValue, newValidity) {
            event.stopPropagation();
            ngModel.$validate();

            const fieldTokens = ngModelName.split('.');
            const fieldName = fieldTokens[1];

            const partialUpdate = {};
            const updatedItem = {};
            updatedItem[fieldName] = {
              value: newValue,
              valid: newValidity,
            };

            if (ngModelName === 'item.name.value') {
              partialUpdate[event.targetScope.item.id] = updatedItem;
            } else {
              partialUpdate[event.targetScope.model.id] = updatedItem;
            }

            $scope.$parent.$emit(
              'field:change',
              'model.' + $scope.definition.name,
              partialUpdate,
              newValidity
            );
          }
          $scope.$on('field:change', reEmitFieldChange);
        },
      };
    }
  );

angular.module('tailor').directive('collectionItem', function () {
  return {
    restrict: 'A',
    scope: true,
    require: ['^collection'],
    link: function ($scope, $attrs, $element, $ctrl) {
      const collection = $ctrl[0];
      $scope.history = [];
      $scope.$watch('$parent.fieldHistory', function () {
        $scope.history = collection.itemHistory($scope.model);
      });
    },
    controller: function ($scope) {
      function reEmitCollectionAdd(event, collection, path) {
        event.stopPropagation();
        $scope.$parent.$emit(
          'collection:addItem',
          collection,
          $scope.definition.name + '.value.' + $scope.model.id + '.' + path
        );
      }
      function reEmitCollectionDelete(event, collection, path) {
        event.stopPropagation();
        $scope.$parent.$emit(
          'collection:deleteItem',
          collection,
          $scope.definition.name + '.value.' + $scope.model.id + '.' + path
        );
      }
      $scope.$on('collection:addItem', reEmitCollectionAdd);
      $scope.$on('collection:deleteItem', reEmitCollectionDelete);
    },
  };
});
