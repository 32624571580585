'use strict';

angular.module('tailor')
  .factory('fifoInterceptor', function fifoInterceptor($q) {
    const fifoMethods = [
      'PUT',
      'POST',
      'DELETE',
      'PATCH'
    ];
    const queuedRequests = [];

    function fireNextRequest() {
      const nextRequest = queuedRequests[0];
      nextRequest.promise.resolve(nextRequest.config);
    }

    return {
      request: function(config) {
        if(_.includes(fifoMethods, config.method) && !config.isRetryRequest) {
          const deferred = $q.defer();
          queuedRequests.push({
            promise: deferred,
            config
          });
          if(queuedRequests.length === 1) {
            fireNextRequest();
          }
          return deferred.promise;
        } else {
          return config;
        }
      },
      response: function(response) {
        if(_.includes(fifoMethods, response.config.method) && !response.config.isRetryRequest) {
          queuedRequests.shift();
          if(queuedRequests.length >= 1) {
            fireNextRequest();
          }
        }
        return response;
      },
      responseError: function(error) {
        if(_.includes(fifoMethods, error.config.method)) {
          queuedRequests.shift();
          if(queuedRequests.length >= 1) {
            fireNextRequest();
          }
        }
        return $q.reject(error);
      }
    };
  });
