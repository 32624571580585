'use strict';

angular
  .module('tailor')
  .factory('templateService', function templateService() {
    function createTag(tag, text, options) {
      options = options || {};
      const className = options.className
        ? ' class="' + options.className + '"'
        : '';

      const attributes = options.attributes ? ' ' + options.attributes : '';

      return ['<', tag, className, attributes, '>', text, '</', tag, '>'].join(
        ''
      );
    }

    /**
     * @param {string} str
     * @param {string} [replaceWith] string to replace curly quotes with
     * @returns {string} with curly quotes replaced with empty string
     */
    function replaceCurlyQuotes(str, replaceWith) {
      replaceWith = replaceWith || '';

      const regex = {
        curly: RegExp('[“”]+', 'g'),
        lquo: RegExp('&ldquo;', 'g'),
        rquo: RegExp('&rdquo;', 'g'),
      };
      let newStr = str;

      while (
        regex.curly.test(newStr) ||
        regex.lquo.test(newStr) ||
        regex.rquo.test(newStr)
      ) {
        newStr = newStr.replace('“', replaceWith);
        newStr = newStr.replace('”', replaceWith);
        newStr = newStr.replace('&ldquo;', replaceWith);
        newStr = newStr.replace('&rdquo;', replaceWith);
      }

      return newStr;
    }

    /**
     * @param {*} displayText most likely a string. ex: "The plan's name is `^^`"
     * @param {string} value
     * @returns {string} replaced field text
     */
    function getFieldText(displayText, value) {
      if (typeof displayText !== 'string') {
        return value;
      }

      const content = replaceCurlyQuotes(displayText);
      return content.replace('^^', value);
    }

    return {
      createTag,
      getFieldText,
    };
  });
