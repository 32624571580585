'use strict';

angular.module('tailor')
  .directive('sortable', ['$timeout', function sortable($timeout) {
    return {
      restrict: 'A',
      priority: 20000,
      link: function postLink(scope, element/* , attrs*/) {

        function updateItemOrder(/* event, item*/) {
          const newOrder = _.map(element.children(), function(li) {
            return angular.element(li).data().$scope.unit.data;
          });

          scope.$emit('units:reorder', scope.module, newOrder);
        }

        if(!scope.configuration.is_locked) {
          // Since sortable is a jQuery plugin, we need to reattach it whnever
          scope.$watchCollection('module.units', function() {
            $timeout(function() {
              // detach all sortable hooks and whatnot
              element.unbind('sortupdate');
              element.sortable('destroy');

              // hack override sortable to not takeover our content editable stuff
              // this is why the world moved on from jquery...
              element.children().on('selectstart.h5s', function(e) {
                e.stopImmediatePropagation();
              });
              // then re-attach them with the new units
              element.sortable({
                items: ':not(.not-sortable)',
                handle: '.handle',
                forcePlaceholderSize: true
              }).on('sortupdate', updateItemOrder);
            });
          });
        }
      }
    };
  }]);
