'use strict';

angular
  .module('tailor')
  .service('dependencyService', function dependentService($rootScope, ownerService) {
    function evaluate(
      fieldDefinition,
      configuration,
      currentModule,
      currentUnit,
      localData
    ) {
      return (
        meetsServiceLevels(
          fieldDefinition.serviceLevels,
          [ownerService
            .lookupServiceLevel(configuration.customerTier)
            .toLowerCase()
          ]
        ) &&
        meetsServiceLevels(
          fieldDefinition.serviceLevels,
          $rootScope.user.josefServiceLevel
        ) &&
        meetsDependencies(
          fieldDefinition.dependencies,
          configuration,
          currentModule,
          currentUnit,
          localData
        )
      );
    }

    function meetsServiceLevels(serviceLevelsNeeded, serviceLevelsObtained) {
      // No service levels means that everyone sees the field.
      return (
        !serviceLevelsNeeded ||
        serviceLevelsNeeded.length === 0 ||
        _.intersection(serviceLevelsNeeded, serviceLevelsObtained).length > 0
      );
    }

    function meetsDependencies(
      orDependencies,
      configuration,
      currentModule,
      currentUnit,
      localData
    ) {
      // No dependencies (or empty dependencies)? No problem.
      if (
        !orDependencies ||
        !orDependencies.length ||
        !orDependencies[0].length
      ) {
        return true;
      }

      // The dependency structure is an array of arrays: the nested array is a set of fields and values, and
      // each of the fields must have those values for the whole set to count (the AND dependencies). The outside array
      // collects sets of dependencies, any one of which can be true for the whole to be true (OR dependencies).
      // If you like: ∃(depSet)∀(dep)(dep) => depsMet

      function getDependency(dataObject, dependency) {
        if (dependency.group) {
          return dataObject[dependency.group].value[dependency.field].value;
        } else {
          return dataObject[dependency.field].value;
        }
      }

      return _.some(orDependencies, function evaluateDependencySet(
        andDependencies
      ) {
        return _.every(andDependencies, function evaluateDependency(
          andDependency
        ) {
          // Default to the current module/unit unless one is specified
          let module = currentModule;
          if (andDependency.module) {
            module = _.find(configuration.Modules, {
              name: andDependency.module,
            });
          }
          let unit = currentUnit;
          if (andDependency.settings) {
            unit = _.find(module.Units, { name: 'settings' });
          }
          let targettedDependencyScope = localData;
          if (
            andDependency.unit ||
            andDependency.module ||
            andDependency.parent ||
            andDependency.settings
          ) {
            targettedDependencyScope = unit.data;
          }

          if (andDependency.comparer === 'module_active') {
            return checkModuleActive(andDependency, module);
          } else {
            const fieldValue = getDependency(
              targettedDependencyScope,
              andDependency
            );
            if (fieldValue === undefined) {
              throw 'Unknown dependency: ' +
                [module.name, unit.name, andDependency.field].join('.');
            }
            return checkConditional(andDependency, fieldValue);
          }
        });
      });
    }

    function checkConditional(dependency, value) {
      switch (dependency.comparer) {
        case '=':
          return !_.isNull(value) && value === dependency.value;
        case '<':
          return value < dependency.value;
        case '>':
          return value > dependency.value;
        case '<=':
          return value <= dependency.value;
        case '>=':
          return value >= dependency.value;
        case '!=':
        case '<>':
          return value !== dependency.value;
        case 'in':
          return (
            !_.isNull(value) &&
            _.filter(dependency.value, function(singleSatisfyingValue) {
              return value === singleSatisfyingValue;
            }).length > 0
          );
        case 'not_in':
          return (
            _.filter(dependency.value, function(singleSatisfyingValue) {
              return value === singleSatisfyingValue;
            }).length === 0
          );
      }
    }

    function checkModuleActive(dependency, module) {
      if (!module) {
        return !dependency.value;
      } else {
        return module.active === dependency.value;
      }
    }

    return {
      evaluate,
      meetsServiceLevels,
      meetsDependencies,
    };
  });
