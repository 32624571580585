'use strict';

angular.module('tailor')
  .controller('ErrorCtrl', function CustomersCtrl($scope, $state, $log, loader) {

    // Just in case.
    loader.close();

    $scope.params = $state.params;

    $scope.hasErrorInfo = _.any($scope.params, function(val) {
      return val !== undefined;
    });
    $scope.showErrorInfo = false;

    $scope.error = $state.params.error;
    $scope.stateInfo = $state.params.stateInfo;

    $scope.backSref = $scope.stateInfo && $scope.stateInfo.toState ? $scope.stateInfo.toState.name + '(stateInfo.toParams)' : '';

    function toPrettyJson(obj) {
      return angular.toJson(obj, 2);
    }

    function toggleErrorInfo() {
      $scope.showErrorInfo = !$scope.showErrorInfo;
    }

    $scope.toPrettyJson = toPrettyJson;
    $scope.toggleErrorInfo = toggleErrorInfo;
  });
