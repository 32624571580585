'use strict';

angular.module('tailor')
  .factory('ckeditorSettings', function ckeditorSettings() {
  // In this service, we define the configuration settings for CKEditor.
  // For complete reference (and maddening frustration), see http://docs.ckeditor.com/#!/api/CKEDITOR.config

    const config = {
      linkShowAdvancedTab: false,
      forcePasteAsPlainText: true, // doesn't apply to the PasteFromWord toolbar button
      disableNativeSpellChecker: false,
      resize_enabled: false,
      removePlugins: 'elementspath',

      customConfig: '', // prevent CKEditor from loading a config script
      contentsCss: '/styles/ckeditor.css'
    };

    // Use <b> instead of <strong>.
    config.coreStyles_bold = {
      element: 'b',
      overrides: 'strong'
    };

    // Use <i> instead of <em>
    config.coreStyles_italic = {
      element: 'i',
      overrides: 'em'
    };

    // Choose which formats appear on the Format dropdown
    config.format_tags = 'p;h1;h2;h3';

    // We have two toolbar configurations: for short_texts and richtexts
    // https://ckeditor.com/latest/samples/old/toolbar/toolbar.html
    config.toolbar_RichText = [
      { name: 'styles', items: [ 'Format' ] },
      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', '-', 'RemoveFormat' ] },
      { name: 'paragraph', groups: [ 'list' ], items: [ 'BulletedList', 'Outdent', 'Indent'] },
      { name: 'links', items: [ 'Link', 'Unlink' ] },
      { name: 'clipboard', groups: [ 'clipboard' ], items: [ 'Paste', 'PasteFromWord' ] },
      { name: 'document', groups: [ 'mode' ], items: [ 'Source' ] },
      { name: 'tools', groups: [ 'tools' ], items: [ 'Maximize' ] }
    ];

    config.toolbar_ShortText = [
      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Underline', '-', 'RemoveFormat' ] },
      { name: 'links', items: [ 'Link', 'Unlink' ] },
      { name: 'clipboard', groups: [ 'clipboard' ], items: [ 'Paste', 'PasteFromWord' ] },
      { name: 'document', groups: [ 'mode' ], items: [ 'Source' ] }
    ];

    const extraPlugins = ['plaintext'];
    config.extraPlugins = extraPlugins.join(',');
    _.each(extraPlugins, function(pluginName) {
      CKEDITOR.plugins.addExternal(pluginName, '/scripts/lib/ckeditor/plugins/' + pluginName + '/plugin.js');
    });

    return {
      rich_text: _.assign({}, config, { toolbar: 'RichText' }),
      short_text: _.assign({}, config, { toolbar: 'ShortText' })
    };
  });
