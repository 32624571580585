'use strict';

angular
  .module('tailor')
  .factory('productService', function productService($http, API) {
    function attachProductToModuleDefinitions(products) {
      return _.map(products, attachProductToModuleDefinition);
    }

    function attachProductToModuleDefinition(product) {
      _.each(product.ModuleDefinitions, function (definition) {
        definition.Product = product;
      });
      return product;
    }

    function getProducts(customerTier) {
      const params = customerTier ? { customerTier } : {};
      return $http
        .get(API.base + '/products', { params })
        .then(function (response) {
          return response.data;
        })
        .then(attachProductToModuleDefinitions);
    }

    function get(productId) {
      if (!productId) {
        return getProducts();
      } else {
        return $http
          .get(API.base + '/products/' + productId)
          .then(function (response) {
            return response.data;
          })
          .then(attachProductToModuleDefinition);
      }
    }

    return {
      getProducts,
      get,
    };
  });
