'use strict';

angular.module('tailor')
  .directive('question',
    function question(authorizationService, ownerService) {
      return {
        templateUrl: 'views/directives/eligibility/question.html',
        restrict: 'EA',
        replace: true,
        scope: {
          question: '=',
          configuration: '=',
          applying: '=',
          associatedQuestions: '='
        },
        require: ['question'],
        controller: function($scope, $rootScope) {
          authorizationService.addAuthToScope($scope);
          const benefitsCounselorModule = _.find($scope.configuration.Modules, { name: 'benefits_counselor_evergreen' });
          const benefitsSettingsUnit = _.find(benefitsCounselorModule.Units, { name: 'settings' });

          $scope.isIntegrated = benefitsSettingsUnit.data.is_integrated.value;
          $scope.isBroker = ownerService.isDIY($scope.configuration.customerTier) && $rootScope.user.role === 'partner';

          function onFieldChange(event, field, value) {
            const fieldName = field.split('.')[1];
            if (event.targetScope.choice) {
              $scope.$emit('choice:update', event.targetScope.choice, fieldName, value);
            } if (event.targetScope.question) {
              $scope.$emit('question:update', $scope.question, fieldName, value);
            }
          }

          function createChoice() {
            $scope.$emit('choice:create', $scope.question);
          }

          function removeChoice(choice) {
            _.remove($scope.question.Choices, choice);
            $scope.$emit('choice:remove', choice);
          }

          $scope.$on('field:change', onFieldChange);

          $scope.createChoice = createChoice;
          $scope.removeChoice = removeChoice;
        },
        link: function($scope, $element) {
          $scope.$element = $element;
        }
      };
    }
  );
