'use strict';

angular
  .module('tailor')
  .directive('dropdown', function dropdown($document, $rootScope, featureFlagService) {
    const FF_BUTTON_WIDTH = 25;
    const $span = $($document[0].createElement('span'));
    $span.addClass('field-dropdown-width');

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'views/directives/fields/dropdown.html',
      require: ['ngModel'],
      controller: function ($scope) {
        $scope.user = $rootScope.user;
        $scope.flagValue = featureFlagService
          .get($scope.user, 'builder-2151-no-deductible-plans')
          .toLowerCase();
      },
      link: function postLink($scope, $element, $attrs, ctrl) {
        const ngModel = ctrl[0];
        let width;

        function resizeElement(fitToMax) {
          const value = ngModel.$modelValue;
          let text = value;
          let option;

          if (fitToMax) {
            let longest = '';
            _.each($scope._options, function (opt) {
              if (opt.text && opt.text.length > longest.length) {
                longest = opt.text;
              }
            });
            text = longest;
          } else {
            option = _.find($scope._options, function (opt) {
              return opt.value === value;
            });
            if (option) {
              text = option.text;
            }
          }
          if (text) {
            $span.text(text);
            // add to parent so styles are applied
            $element.parent().append($span);
            // adding magic number to workaround https://bugzilla.mozilla.org/show_bug.cgi?id=649849
            width = $span.width() + FF_BUTTON_WIDTH;
            // size either to the width of the span containing the label text or, at most,
            // the original width of the select
            $element.width(width);
            $span.remove();
          }
          return width;
        }

        function emitFieldChange() {
          ngModel.$validate();
          $scope.$emit('field:change', $attrs.ngModel, ngModel.$viewValue, ngModel.$valid);
        }

        function onChange() {
          resizeElement(true);
          emitFieldChange();
          $element.on('mousedown', onMouseDown);
        }

        function onMouseDown(/* event */) {
          if (!$attrs.disabled) {
            resizeElement(true);
            $element.off('mousedown', onMouseDown);
          }
        }

        function onBlur(/* event */) {
          $element.on('mousedown', onMouseDown);
        }

        // options are passed as a attribute, need to evaluate that on the scope object
        const isMedicalPlan = $scope.module && $scope.module.name === 'medical_plan';
        const validOptions = ['copay with range', 'Coupe', 'SimplePay', 'Surest'];
        const options = $scope.$eval($attrs.options) || [];
        $scope._options = options.filter(option => {
          if (option.featureFlagged && isMedicalPlan && validOptions.includes(option.text)) {
            return option.featureFlagged.treatment === 'on' && $scope.flagValue === 'on';
          }
          return true; // Keep options that don’t have feature flags
        });
        // $scope._options = $scope.options || fieldDefinition.fieldDefinition.options;

        $element.on('change', onChange);
        $element.on('blur', onBlur);
        $element.on('mousedown', onMouseDown);
        $element.on('keydown keypress', function (event) {
          if (event.which === 32) {
            event.preventDefault();
          }
        });

        const originalRender = ngModel.$render;
        function render() {
          originalRender();
          resizeElement(true);
        }
        ngModel.$render = render;
      },
    };
  });
