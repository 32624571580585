'use strict';
/* eslint camelcase: ["error", {allow: ["title_text","subtitle_text", "body_text"]}] */

angular.module('tailor')
  .directive('label', function titleText($compile, contentService) {

    function compileSpan($scope, content) {
      return $compile('<span>' + content + '</span>')($scope).html();
    }

    return {
      restrict: 'A',
      templateUrl: 'views/directives/fields/label.html',
      replace: true,
      scope: true,
      link: function($scope, $element, $attrs) {
        if ($attrs.titleText) {
          $scope.title_text = compileSpan($scope, $attrs.titleText);
        }
        if ($attrs.subtitleText) {
          $scope.subtitle_text = compileSpan($scope, $attrs.subtitleText);
        }
        if ($attrs.contentCode) {
          contentService.getHelpText($attrs.contentCode)
            .then(function(content) {
              $scope.body_text = compileSpan($scope, content);
            });
        }
        if ($attrs.bodyText) {
          $scope.body_text = compileSpan($scope, $attrs.bodyText);
        }
      },
    };
  });
