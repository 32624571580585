'use strict';

angular.module('tailor')
  .factory('questionsService', function questionsService($http, API, debounceHttpService ) {
    function create(configuration) {
      const data = {
        text: 'New Question'
      };
      return $http.post(API.base + '/configurations/' + configuration.id + '/questions', data)
        .then(function(response) {
          configuration.Questions.push(response.data);
          return response.data;
        });
    }
    function remove(question) {
      return $http.delete(API.base + '/questions/' + question.id);
    }
    function update(question, field, value) {
      const data = {};
      data[field] = value;
      return debounceHttpService.patch(API.base + '/questions/' + question.id, data);
    }

    return {
      create,
      remove,
      update,
    };
  });
