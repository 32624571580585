'use strict';

angular.module('tailor')
  .directive('plainTextPaste', function plainTextPaste($window, $document, $timeout) {
    return {
      restrict: 'A',
      link: function postLink($scope, $element/* , $attrs, ctrl*/) {
        function onPaste(e) {
          // adapted from http://stackoverflow.com/questions/12027137/javascript-trick-for-paste-as-plain-text-in-execcommand
          // added comments, adjusted to work in IE, made compatibile with angular
          let text = '';

          if (e.clipboardData) { // in case angular or jquery pass it through
            text = e.clipboardData.getData('text/plain');
          } else if ($window.clipboardData) { // IE
            text = $window.clipboardData.getData('Text');
          } else if (e.originalEvent.clipboardData) { // webkit
            text = e.originalEvent.clipboardData.getData('text');
          }

          const em = angular.element('<div/>');
          em.text(text);
          const plaintext = em.html();

          if ($document[0].queryCommandSupported('insertText')) { // webkit
            $document[0].execCommand('insertHTML', false, plaintext);
            return handlePasteData(plaintext);
          } else if ($window.clipboardData) { // otherwise
            $document[0].selection.createRange().pasteHTML(plaintext);
            return handlePasteData(plaintext);
          }
        }

        function handlePasteData(value) {
          // We need to return false, to prevent default browser handling the paste event if we handle it here;
          // Default browser handling could mean richtext, duplicated text, or other bad things
          // But by returning false, it will also not trigger updating the ngModel or other stuff
          // Because we want the best of both worlds, do it on an timeout.
          $timeout(function () {
            $scope.$emit('plainTextPaste', $element.text());
          }, 1);

          // also emit a utility event of just the pasted value
          // used for unit testing (at least)
          // could also be used to get JUST the pasted value, instead of the entire contents of the element
          $scope.$emit('plainTextPastedValue', value);

          return false;
        }



        $element.on('paste', onPaste);
      }
    };
  }
  );
