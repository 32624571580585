'use strict';

angular.module('tailor').factory('cookiesService', function cookiesService() {
  function saveConfigurationId(configurationId) {
    const mostRecentConfig = getConfigurationIds();
    if (mostRecentConfig) {
      mostRecentConfig.includes(configurationId) ? '' : mostRecentConfig.push(configurationId);
    }
    const mostRecentConfigJson_str = JSON.stringify(mostRecentConfig);
    setCookie('recentlyViewedConfig', mostRecentConfigJson_str, 2);
  }

  function deleteConfigurationId(configurationId) {
    const mostRecentConfig = getConfigurationIds();
    if (mostRecentConfig && mostRecentConfig.includes(configurationId)) {
      mostRecentConfig.splice(mostRecentConfig.indexOf(configurationId), 1);
      setCookie('recentlyViewedConfig', JSON.stringify(mostRecentConfig), 2);
      return true;
    }
    return false;
  }

  function getConfigurationIds() {
    const ids = getCookie('recentlyViewedConfig');
    return ids ? JSON.parse(ids) : [];
  }

  function setCookie(name, value) {
    document.cookie = name + '=' + (value || '') + '; path=/';
  }

  function getCookie(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      // eslint-disable-next-line eqeqeq
      while (c.charAt(0) == ' ') {
        c = c.substring(1, c.length);
      }
      // eslint-disable-next-line eqeqeq
      if (c.indexOf(nameEQ) == 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return undefined;
  }

  return {
    saveConfigurationId,
    deleteConfigurationId,
    getConfigurationIds,
  };
});
