'use strict';

angular.module('tailor')
  .directive('min', function MinDirective() {
    return {
      restrict: 'A',
      require: ['ngModel'],
      link: function postLink($scope, $element, $attrs, ctrl) {
        const ngModel = ctrl[0];

        const min = Number($attrs.min);
        ngModel.$validators.min = function(modelValue, viewValue) {
          return ngModel.$isEmpty(viewValue) || Number(modelValue) >= min;
        };
      }
    };
  });

angular.module('tailor')
  .directive('max', function MaxDirective() {
    return {
      restrict: 'A',
      require: ['ngModel'],
      link: function postLink($scope, $element, $attrs, ctrl) {
        const ngModel = ctrl[0];

        const max = Number($attrs.max);
        ngModel.$validators.max = function(modelValue, viewValue) {
          return ngModel.$isEmpty(viewValue) || Number(modelValue) <= max;
        };
      }
    };
  });
