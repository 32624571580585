'use strict';

angular
  .module('tailor')
  .controller(
    'ModulesCtrl',
    function ($controller, $scope, $state, loader, unitService, generalModal) {
      $controller('ModulesLabelsCtrl', { $scope });

      $scope.addUnitToModule = function addUnitToModule(module, name) {
        const foundUnit = _.find(module.definition.planTypes, { name });
        const title = foundUnit.childTitle || foundUnit.title;

        loader.open('Creating new ' + title);
        unitService.create(module, name).then(function (newUnit) {
          $state.go('configuration.module.unit', {
            configurationId: $scope.configuration.id,
            moduleName: module.name,
            unitName: newUnit.name,
            unitId: newUnit.id,
          });
          loader.close();
        });
      };
      $scope.duplicateUnitForModule = function duplicateUnitForModule(module, unit) {
        loader.open('Duplicating ' + $scope.unitDisplayName(unit));
        unitService.duplicate(unit, module).then(function (newUnit) {
          $state.go('configuration.module.unit', {
            configurationId: $scope.configuration.id,
            moduleName: module.name,
            unitName: newUnit.name,
            unitId: newUnit.id,
          });
          loader.close();
        });
      };
      $scope.removeUnitFromModule = function removeUnitFromModule(module, unit) {
        function findReferencedModule(reference) {
          if (reference.module) {
            return $scope.modules.find(function (mod) {
              return reference.module === mod.name;
            });
          } else {
            return module;
          }
        }

        function findReferencedSection(reference) {
          return function (section) {
            return section.definition.find(function (def) {
              return def.name === reference.field;
            });
          };
        }

        function removeUnitReferences(reference) {
          const referencedModule = findReferencedModule(reference);
          if (referencedModule) {
            referencedModule.Units.forEach(function (u) {
              if (u.name === reference.unit && u.data[reference.field]) {
                const items = u.data[reference.field].value.items || [];
                const idx = items.findIndex(val => val.id === unit.id);
                if (idx >= 0) {
                  const dupedItems = items.slice();
                  dupedItems.splice(idx, 1);
                  return unitService.update(u, {
                    data: {
                      [reference.field]: {
                        value: {
                          items: dupedItems,
                        }
                      }
                    }
                  }).then(updatedUnit => {
                    setValidityAfterUpdate(u, updatedUnit, referencedModule, reference);
                  });
                }
              }
            });
          }
        }

        function setValidityAfterUpdate(oldUnit, updatedUnit, referencedModule, reference) {
          updatedUnit.definition = oldUnit.definition;
          unitService.parseSectionsAndComments(updatedUnit, referencedModule);
          const customerTier = $scope.$parent.configuration.customerTier;
          unitService.prevalidate(updatedUnit, customerTier);
          const updatedSection = updatedUnit.sections.find(findReferencedSection(reference));
          const oldSection = oldUnit.sections.find(findReferencedSection(reference));
          if (oldSection.valid !== updatedSection.valid) {
            oldSection.valid = updatedSection.valid;
          }
          if (oldUnit.data.valid !== updatedUnit.data.valid) {
            oldUnit.data.valid = updatedUnit.data.valid;
            referencedModule.valid = referencedModule && updatedUnit.data.valid;
            $scope.$emit('module:validChange', referencedModule);
          }
        }

        function removeAllUnitReferences() {
          if ((unit.definition.pickedBy || []).length > 0) {
            unit.definition.pickedBy.forEach(reference => {
              return removeUnitReferences(reference);
            });
          }
        }

        function removeUnitAndNavigateToOtherUnit() {
          loader.open('Deleting...');
          unitService
            .remove(unit, module)
            .then(function () {
              return removeAllUnitReferences();
            })
            .then(function () {
              if (
                $state.params.moduleName === module.name &&
                parseInt($state.params.unitId, 10) === unit.id
              ) {
                const unitToNavigateTo = _.find(module.Units, { name: unit.name });
                if (unitToNavigateTo) {
                  $state.go('configuration.module.unit', {
                    unitId: unitToNavigateTo.id,
                  });
                } else {
                  $state.go('configuration.setup', {
                    configurationId: parseInt($scope.configuration.id, 10),
                  });
                }
              }
              loader.close();
            });
        }

        generalModal.activate({
          headerText: 'Delete ' + unit.name + '?',
          text:
            'Are you sure you want to delete ' +
            module.name +
            ' » ' +
            unitService.getUnitDisplayName(unit) +
            '? You cannot undo this action.',

          okText: 'Delete it!',
          confirm: removeUnitAndNavigateToOtherUnit,
          cancelText: 'Oops, forget it.',
          cancel: angular.noop,
        });
      };

      $scope.planTypeUnitsInvalid = function planTypeUnitsInvalid(planType, module) {
        const units = _.filter(module.Units, { name: planType.name });
        // should appear valid even if a field in the Publishing section is invalid
        // since it does not fall under any units in the UI
        const valid = _.every(units, function (unit) {
          return _.every(unit.sections, function (section) {
            return section.valid || section.name === 'Publishing';
          });
        });
        return !valid;
      };

      $scope.planTypeUnitsEdited = function planTypeUnitsEdited(planType, module) {
        const units = _.filter(module.Units, { name: planType.name });
        return _.every(units, function (unit) {
          return unit.edited;
        });
      };

      $scope.planTypeUnitsComments = function planTypeUnitsComments(planType, module) {
        const units = _.filter(module.Units, { name: planType.name });
        let numComments = 0;
        _.every(units, function (unit) {
          return (numComments += unit.commentCount);
        });
        return numComments;
      };
    }
  );
