/* eslint camelcase: ["error", {allow: ["benefits_counselor_plan_year",
  "benefits_counselor_url_group", "benefits_counselor_url_path", "bsp_plan_year", "bsp_url_group",
  "bsp_url_path", "eligible_employees", "josef_config_id", "nhsp_plan_year", "nhsp_url_group",
  "nhsp_url_path"]}] */
'use strict';

angular
  .module('tailor')
  .controller(
    'NewConfigurationCtrl',
    function NewConfigurationCtrl(
      $q,
      $scope,
      $state,
      TIERS,
      TIER_CAPABILITIES,
      configuration,
      configurationService,
      customer,
      featureFlagService,
      loader,
      operatorsService,
      ownerService,
      productService,
      uniqueUrls,
      unitService
    ) {
      $scope.customer = customer;
      $scope.configToDuplicate = configuration;
      $scope.uniqueUrls = uniqueUrls;
      const defaultPlanYear = operatorsService.defaultValues.getDefaultYear();
      $scope.TIERS = TIERS;
      $scope.user = $scope.$root.user;
      $scope.isEmployee = $scope.user.role === 'employee';

      if ($scope.user.role === 'partner') {
        $scope.availableTiers = Object.values(TIERS).filter(function (t) {
          const brokersSeeSSFundamentals =
            featureFlagService.get($scope.user, 'brokers_see_ss_fundamentals') === 'on';
          if (t === TIERS.SELF_SERVICE_FUNDAMENTALS) {
            return brokersSeeSSFundamentals;
          } else {
            return ownerService.isDIY(t) && TIER_CAPABILITIES.CURRENT_TIERS.includes(t);
          }
        });
      } else {
        $scope.availableTiers = TIER_CAPABILITIES.CURRENT_TIERS;
      }

      function setDefaultTier() {
        if ($scope.configToDuplicate) {
          // Default to empty tier if old tier is no longer available
          return $scope.availableTiers.includes($scope.configToDuplicate.customerTier)
            ? $scope.configToDuplicate.customerTier
            : '';
        } else {
          return $scope.availableTiers[0];
        }
      }

      $scope.configuration = {
        name: defaultPlanYear,
        eligibleEmployees: '',
        planYear: defaultPlanYear,
        products: [],
        customerTier: setDefaultTier(),
        urlPath: defaultPlanYear,
      };

      productService.getProducts($scope.configuration.customerTier).then(function (prods) {
        $scope.products = prods;
      });

      if ($scope.configToDuplicate) {
        // set defaults for duplication form
        $scope.duplicating = true;
        $scope.configuration.name = '';
        $scope.configuration.incrementYears = true;
        $scope.configToDuplicateBCModule = _.find(configuration.Modules, {
          name: 'benefits_counselor_evergreen',
        });
        $scope.configToDuplicateBCSettings =
          $scope.configToDuplicateBCModule &&
          _.find($scope.configToDuplicateBCModule.Units, { name: 'settings' });

        $scope.configToDuplicateNHSPModule = _.find(configuration.Modules, {
          name: 'nhsp_2019',
        });
        $scope.configToDuplicateNHSPSettings =
          $scope.configToDuplicateNHSPModule &&
          _.find($scope.configToDuplicateNHSPModule.Units, {
            name: 'settings',
          });
      }

      $scope.showPlanYear = function () {
        if (
          $scope.duplicating &&
          $scope.configToDuplicateBCModule &&
          $scope.configToDuplicateBCModule.active
        ) {
          return true;
        } else if (
          !$scope.duplicating &&
          $scope.products &&
          $scope.products.find(
            p => p.productKey === 'benefits_counselor_evergreen' && p.isChecked === true
          )
        ) {
          return true;
        } else {
          return false;
        }
      };

      $scope.$watch('configuration.planYear', function (planYear) {
        $scope.configuration.name = planYear;
        $scope.configuration.urlPath = planYear;
      });

      $scope.$watch('configuration.customerTier', function (customerTier) {
        productService.getProducts(customerTier).then(function (prods) {
          $scope.products = prods;
        });
      });

      function navigateToConfig(config) {
        $state.go('configuration', {
          configurationId: config.id,
        });
      }

      function generateProductUrlSegment(product) {
        return `${product}${$scope.configuration.planYear}`;
      }

      $scope.editing = false;

      $scope.isOptionsRequired = function () {
        return !$scope.products.some(function (product) {
          return product.isChecked;
        });
      };

      $scope.createConfiguration = function () {
        if ($scope.form.$valid) {
          for (const index in $scope.products) {
            const product = $scope.products[index];
            if (product.isChecked) {
              $scope.configuration.products.push({ id: product.id });
            }
          }

          loader.open('Creating ' + $scope.configuration.name);

          return configurationService
            .create($scope.configuration, $scope.customer)
            .then(function (conf) {
              configurationService
                .get(conf.id, true)
                .then(function (conf) {
                  const promises = [];
                  const bcSettings = configurationService.getConfigurationUnit(
                    conf,
                    'benefits_counselor_evergreen',
                    'settings'
                  );
                  if (bcSettings) {
                    const data = {
                      data: {
                        benefits_counselor_url_group: {
                          value: {
                            benefits_counselor_url_path: { value: $scope.configuration.urlPath },
                          },
                        },
                        benefits_counselor_plan_year: {
                          value: $scope.configuration.planYear,
                        },
                      },
                    };
                    promises.push(unitService.update(bcSettings, data));

                    const nhspSettings = configurationService.getConfigurationUnit(
                      conf,
                      'nhsp_2019',
                      'settings'
                    );
                    if (nhspSettings) {
                      const data = {
                        data: {
                          nhsp_url_group: {
                            value: {
                              nhsp_url_path: { value: generateProductUrlSegment('newhire') },
                            },
                          },
                          nhsp_plan_year: {
                            value: $scope.configuration.planYear,
                          },
                        },
                      };
                      promises.push(unitService.update(nhspSettings, data));
                    }

                    const bspSettings = configurationService.getConfigurationUnit(
                      conf,
                      'benefits_sneak_peek',
                      'settings'
                    );
                    if (bspSettings) {
                      const data = {
                        data: {
                          bsp_url_group: {
                            value: {
                              bsp_url_path: { value: generateProductUrlSegment('sneakpeek') },
                            },
                          },
                          bsp_plan_year: {
                            value: $scope.configuration.planYear,
                          },
                        },
                      };
                      promises.push(unitService.update(bspSettings, data));
                    }
                  }
                  return $q.all(promises);
                })
                .then(function () {
                  navigateToConfig(conf);
                });
            });
        }
      };

      $scope.duplicateConfiguration = function () {
        if ($scope.form.$valid) {
          loader.open('Duplicating ' + $scope.configToDuplicate.name);
          const configUpdates = {
            name: $scope.form.name ? $scope.form.name.$modelValue : $scope.configuration.name,
            customerTier: $scope.form.customerTier
              ? $scope.form.customerTier.$modelValue
              : $scope.configToDuplicate.customerTier,
          };
          const unitUpdates = [];
          const BCUnitData = {
            module: 'benefits_counselor_evergreen',
            unit: 'settings',
            data: {
              eligible_employees: {
                value: $scope.form.eligibleEmployees
                  ? $scope.form.eligibleEmployees.$modelValue
                  : null,
              },
              josef_config_id: {
                value: '',
              },
              benefits_counselor_plan_year: {
                value: $scope.form.planYear
                  ? $scope.form.planYear.$modelValue
                  : $scope.configuration.planYear,
              },
              benefits_counselor_url_group: {
                value: {
                  benefits_counselor_url_path: {
                    value: $scope.form.urlPath
                      ? $scope.form.urlPath.$modelValue
                      : $scope.configuration.urlPath,
                  },
                },
              },
            },
          };

          const NHSPUnitData = {
            module: 'nhsp_2019',
            unit: 'settings',
            data: {
              nhsp_plan_year: {
                value: $scope.form.planYear
                  ? $scope.form.planYear.$modelValue
                  : $scope.configuration.planYear,
              },
              nhsp_url_group: {
                value: {
                  nhsp_url_path: {
                    value: generateProductUrlSegment('newhire'),
                  },
                },
              },
            },
          };

          const BSPUnitData = {
            module: 'benefits_sneak_peek',
            unit: 'settings',
            data: {
              eligible_employees: {
                value: $scope.form.eligibleEmployees
                  ? $scope.form.eligibleEmployees.$modelValue
                  : null,
              },
              bsp_plan_year: {
                value: $scope.form.planYear
                  ? $scope.form.planYear.$modelValue
                  : $scope.configuration.planYear,
              },
              bsp_url_group: {
                value: {
                  bsp_url_path: {
                    value: generateProductUrlSegment('sneakpeek'),
                  },
                },
              },
            },
          };
          unitUpdates.push(BCUnitData);
          unitUpdates.push(BSPUnitData);
          unitUpdates.push(NHSPUnitData);
          return configurationService
            .duplicate(
              $scope.configToDuplicate,
              $scope.customer,
              configUpdates,
              unitUpdates,
              $scope.form.incrementYears.$modelValue
            )
            .then(function (conf) {
              configurationService
                .get(conf.id, true)
                .then(function (conf) {
                  const promises = [];
                  const bcSettings = configurationService.getConfigurationUnit(
                    conf,
                    'benefits_counselor_evergreen',
                    'settings'
                  );
                  if (bcSettings) {
                    const data = {
                      data: {
                        benefits_counselor_url_group: {
                          value: {
                            benefits_counselor_url_path: { value: $scope.configuration.urlPath },
                          },
                        },
                        benefits_counselor_plan_year: {
                          value: $scope.configuration.planYear,
                        },
                      },
                    };
                    promises.push(unitService.update(bcSettings, data));

                    const nhspSettings = configurationService.getConfigurationUnit(
                      conf,
                      'nhsp_2019',
                      'settings'
                    );
                    if (nhspSettings) {
                      const data = {
                        data: {
                          nhsp_url_group: {
                            value: {
                              nhsp_url_path: { value: generateProductUrlSegment('newhire') },
                            },
                          },
                          nhsp_plan_year: {
                            value: $scope.configuration.planYear,
                          },
                        },
                      };
                      promises.push(unitService.update(nhspSettings, data));
                    }

                    const bspSettings = configurationService.getConfigurationUnit(
                      conf,
                      'benefits_sneak_peek',
                      'settings'
                    );
                    if (bspSettings) {
                      const data = {
                        data: {
                          bsp_url_group: {
                            value: {
                              bsp_url_path: { value: generateProductUrlSegment('sneakpeek') },
                            },
                          },
                          bsp_plan_year: {
                            value: $scope.configuration.planYear,
                          },
                        },
                      };
                      promises.push(unitService.update(bspSettings, data));
                    }
                  }
                  return $q.all(promises);
                })
                .then(function () {
                  navigateToConfig(conf);
                });
            });
        }
      };
    }
  );
