'use strict';

angular.module('tailor')
  .directive('eligibility',
    function eligibility($controller, $window, questionsService, choicesService, historyService, featureFlagService) {
      return {
        restrict: 'EA',
        templateUrl: 'views/directives/fields/eligibility.html',
        replace: true,
        require: ['ngModel', 'eligibility'],
        link: function postLink($scope, $element, $attrs, ctrls) {
          const ngModel = ctrls[0];
          const eligibilityCtrl = ctrls[1];
          $scope.questionIsAssociatedToPlan = eligibilityCtrl.questionIsAssociatedToPlan;
          $scope.answerIsSelected = eligibilityCtrl.answerIsSelected;
          $scope.noAnswerSelected = eligibilityCtrl.noAnswerSelected;

          const fieldTokens = $attrs.ngModel.split('.');
          const fieldName = fieldTokens[1];

          $scope.eligibilityData = $scope.model[fieldName].value;
          $scope.associatedQuestions = $scope.eligibilityData.associated_questions || [];
          $scope.configuration.Questions.forEach(function(question) {
            question.applying = true;
          });
          $scope.$on('$destroy', function() {
            $scope.configuration.Questions.forEach(function(question) {
              question.applying = false;
            });
          });
          let collectionScope;
          let validateCollection = false;
          function checkCollection() {
            // Recursively scan scopes to determine if collection controller is in there
            let currentScope = $scope;
            while (currentScope.$parent) {
              if (currentScope.$parent.activeItem) {
                validateCollection = true;
                collectionScope = currentScope.$parent;
                return;
              } else {
                currentScope = currentScope.$parent;
              }
            }
          }
          checkCollection();
          $scope.numPremiumGroups = validateCollection
            ? collectionScope.collectionLength
            : 0;

          function validateAvailableToAll() {
            if (
              validateCollection &&
              $scope.numPremiumGroups > 1 &&
              $scope.eligibilityData.available_to_all
            ) {
              ngModel.$setValidity('available_to_all', false);
            } else if ($scope.eligibilityData.available_to_all) {
              ngModel.$setValidity('available_to_all', true);
            } else {
              ngModel.$setValidity('available_to_all', $scope.associatedQuestions.length >= 1);
            }
            if (ngModel.$error.available_to_all && validateCollection) {
              collectionScope.activeItem.valid = false;
            }
          }
          $scope.validateAvailableToAll = validateAvailableToAll;
          $scope.$watch(
            '[eligibilityData.available_to_all, associatedQuestions.length]',
            validateAvailableToAll
          );

          $scope.$watch('fieldHistory', function() {
            if ($scope.fieldHistory) {
              $scope.availableToAllHistory = historyService
                .computeChanges('value.available_to_all', $scope.fieldHistory);
            }
          });
        },
        controller: function ($scope, $element, $attrs) {
          this.questionIsAssociatedToPlan = function(question) {
            return Boolean(_.find($scope.associatedQuestions, { id: question.id }));
          }.bind(this);
          this.answerIsSelected = function (choice, question) {
            const selectedQuestion = _.find($scope.associatedQuestions, { id: question.id });
            return this.questionIsAssociatedToPlan(question) &&
              Boolean(_.find(selectedQuestion.answers, { id: choice.id }));
          }.bind(this);
          this.noAnswerSelected = function(question) {
            const selectedQuestion = _.find($scope.associatedQuestions, { id: question.id });
            return selectedQuestion.answers.length === 0;
          }.bind(this);

          // inject the questionsCtrl;
          // this ctrl will listen for the events broadcast by the directives
          // and send the appropriate updates to our services
          $controller('EligibilityCtrl', {
            $scope,
            $window,
            questionsService,
            choicesService,
            questionsWithUsage: null,
          });
          function onQuestionCreate(event, group) {
            questionsService.create($scope.configuration)
              .then(function(question) {
                associateQuestionToParent(event, question, group);
              });
          }
          function associateQuestionToParent(event, question) {
            if (question) { // IE fix: if question is null, it breaks the page
              const associatedQuestion = {
                id: question.id,
              };

              if (question.answerType === 'zip') {
                associatedQuestion.inclusive = true;
              } else {
                associatedQuestion.answers = [];
              }

              $scope.associatedQuestions.push(associatedQuestion);
              reEmitAssociatedQuestions(event);
            }
          }
          function dissociateQuestionFromParent(event, question) {
            _.remove($scope.associatedQuestions, { id: question.id });
            reEmitAssociatedQuestions(event);
          }
          function reEmitAssociatedQuestions(event) {
            event.stopPropagation();
            validateAndEmitUpdate({
              associated_questions: $scope.associatedQuestions
            });
          }
          function onRangeUpdate(event, field, value, question) {
            const rangeQuestion = _.find($scope.associatedQuestions, { id: question.id });
            if (!rangeQuestion.range) {
              rangeQuestion.range = { operator: '', min_value: null, max_value: null };
            }
            rangeQuestion.range[field] = value;
            reEmitAssociatedQuestions(event);
          }
          function onFieldChange(event, fieldTokens) {
            if (fieldTokens === 'eligibilityData.available_to_all') {
              event.stopPropagation();
              validateAndEmitUpdate({
                available_to_all: $scope.eligibilityData.available_to_all,
              });
            } else if (fieldTokens === 'zipQuestionInstance.inclusive') {
              reEmitAssociatedQuestions(event);
            }
          }
          $scope.showPlanEligibilityWarning =
            ($scope.definition.attributes || {}).showPlanEligibilityWarning || false;

          $scope.flagValue = featureFlagService
            .get($scope.user, 'builder-2151-no-deductible-plans')
            .toLowerCase();

          $scope.flagTeladocMtM = featureFlagService
            .get($scope.user, 'BUILDER-2211-teladoc-MtM')
            .toLowerCase();

          $scope.showMedicalDropdownWarning =
            $scope.flagValue === 'on' &&
            $scope.module.name === 'medical_plan' &&
            $scope.field && $scope.field.section === 'Procedures'
              ? ($scope.definition.attributes || {}).showMedicalDropdownWarning
              : false;
          // showHealthCareDropdownWarning MTM
          $scope.showHealthCareDropdownWarning =
            $scope.flagTeladocMtM === 'on' &&
            $scope.module.name === 'medical_plan' &&
            $scope.field && $scope.field.section === 'Healthcare Vendor Solutions'
              ? ($scope.definition.attributes || {}).showHealthCareDropdownWarning
              : false;

          function onZipUpdate(event, question, fileName, zipListId) {
            event.stopPropagation();

            validateAndEmitUpdate({
              associated_questions: $scope.associatedQuestions.map(function (q) {
                return q.id === question.id ? Object.assign({}, q, {
                  fileName,
                  zipListId,
                }) : q;
              }),
            });
          }

          function validateAndEmitUpdate(data) {
            $scope.validateAvailableToAll();
            $scope.$emit('field:change', $attrs.ngModel, data, $scope.eligibility.$valid);
          }

          // creating custom question on a plan - rename to createQuestionWithUnit
          $scope.$on('question:create', onQuestionCreate);
          $scope.$on('question:associateToParent', associateQuestionToParent);
          $scope.$on('question:dissociateFromParent', dissociateQuestionFromParent);
          $scope.$on('choice:associateToParent', reEmitAssociatedQuestions);
          $scope.$on('choice:dissociateFromParent', reEmitAssociatedQuestions);
          $scope.$on('range:update', onRangeUpdate);
          $scope.$on('field:change', onFieldChange);
          $scope.$on('zip:update', onZipUpdate);
        },
      };
    }
  );

