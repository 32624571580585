'use strict';

angular.module('tailor')
  .service('dependencyHacks', function dependencyHacks(operatorsService) {
    function evaluate(
      fieldDefinition,
      configuration,
      currentModule,
      originalInclude
    ) {
      const DEPENDENCY_HACK_FIELDS = operatorsService.dependencyHacks.DEPENDENCY_HACK_FIELDS;
      if (
        DEPENDENCY_HACK_FIELDS.FIDELITY_INTEGRATION_FIELDS.includes(fieldDefinition.name) ||
        DEPENDENCY_HACK_FIELDS.STANDARD_INTEGRATION_FIELDS.includes(fieldDefinition.name) ||
        DEPENDENCY_HACK_FIELDS.ALEX_CALC_FIELDS.includes(fieldDefinition.name) ||
        DEPENDENCY_HACK_FIELDS.COMM_API_FIELDS.includes(fieldDefinition.name)
      ) {
        const bcSettingsModule = _.find(configuration.Modules, function(module) {
          return module.name === 'benefits_counselor_evergreen';
        });
        const bcSettingsUnit = _.find(bcSettingsModule.Units, function(unit) {
          return unit.name === 'settings';
        });
        const hidden = operatorsService.dependencyHacks.checkHidden(
          fieldDefinition,
          bcSettingsUnit,
          !originalInclude,
          currentModule
        );
        return !hidden;
      } else {
        return originalInclude;
      }
    }
    return {
      evaluate,
    };
  });
