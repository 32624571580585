'use strict';

angular.module('tailor')
  .directive('errorMessages', function errorMessages($compile, $templateCache) {
    return {
      restrict: 'A',
      priority: 550,
      require: ['form', '^field'],
      controller: function errorMessagesController($scope, $element, $attrs) {
        function spelunkForErrorMessage(fieldObj, errorKey) {
          if(fieldObj === undefined || fieldObj.$error[errorKey] === undefined) {
            return undefined;
          }
          if (fieldObj.$error[errorKey] === true) {
            if (errorKey === 'required') {
              return ''; // Showing a custom error in the html so no need to display this error key.
            }
            if (fieldObj.$errorMessages) {
              const validationRule = _.find(fieldObj.$errorMessages, {
                key: errorKey
              });
              if (validationRule && validationRule.message) {
                return validationRule.message;
              } else {
                return 'This field is invalid because of error key: ' + errorKey;
              }
            }
          }
          return spelunkForErrorMessage(fieldObj.$error[errorKey][0], errorKey);
        }

        function getErrorMessages(errorObj) {
          const m = _.reduce(errorObj, function(messages, ngModelControllerArray, errorKey) {
            errorKey = $attrs.$normalize(errorKey);
            const message = spelunkForErrorMessage(ngModelControllerArray[0], errorKey);
            if(message) {
              messages[errorKey] = message;
            }
            return messages;
          }, {});
          return m;
        }

        $scope.getErrorMessages = getErrorMessages;
      },
      link: function postLink($scope, $element, $attrs, ctrls) {
        const formController = ctrls[0];
        $scope.formError = formController.$error;

        // The template is getting appended to an existing element, so we have to grab it from the template cache.
        const errorMessagesEl = $compile($templateCache.get('views/directives/error-messages/error-messages.html'))($scope);
        $element.append(errorMessagesEl);
      }
    };
  });
