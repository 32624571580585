'use strict';

angular.module('tailor')
  .directive('editor', function Editor(authorizationService, fieldTemplateService, $compile) {
    return {
      restrict: 'EA',
      templateUrl: 'views/editor/editor.html',
      scope: {
        schema: '=',
        definition: '='
      },
      link: function($scope, $element) {
        authorizationService.addAuthToScope($scope);

        const previewScope = $scope.$new();
        previewScope.model = {};
        $scope.$on('$destroy', function() {
          previewScope.$destroy();
        });

        $scope.onChange = function(newDefinition) {
          const previewElement = $element.children('div').children('.preview').children('.field-preview');

          if (newDefinition) {
            let fieldHTML = fieldTemplateService.build(newDefinition, '').html();
            fieldHTML = fieldHTML.replace('dependent', '');
            previewElement.html(fieldHTML);

            previewScope.model[newDefinition.name] = { value: newDefinition.defaultValue || '' };
            $compile(previewElement)(previewScope);
          }
        };
      },
    };
  }).controller('JSONEditorCtrl', function($scope, $timeout) {
    $scope.$watchCollection('startval', function() {
      $scope.startval.helpText = $scope.startval.helpText || {};
      if ($scope.editor) {
        $scope.editor.setValue($scope.startval);
      }
    }, true);
    $scope.copyToClipboard = function($event) {
      const clipboard = new Clipboard('.null', {
        text: function() {
          return JSON.stringify($scope.editor.getValue());
        }
      });
      clipboard.on('success', function() {
        $scope.copied = true;
        $timeout(function() {
          $scope.copied = false;
        });
      });

      clipboard.on('error', function() {
        $scope.error = true;
      });

      clipboard.onClick($event.originalEvent);
    };
  });
