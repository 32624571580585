'use strict';

angular.module('tailor')
  .directive('maxLength', function($compile, $templateCache) {
    return {
      restrict: 'A',
      require: ['ngModel', '^field'],
      link: function postLink($scope, $element, $attrs, ctrls) {
        const ngModel = ctrls[0];
        const field = ctrls[1];
        const maxLength = $scope.$eval($attrs.maxLength);

        function getUsedCharactersLength(html) {
          return angular.element('<div></div>').html(html).text()
            .replace(/[\r\n]+/g, '') // character count doesn't include newlines
            .trim() // get rid of trailing whitespace
            .length;
        }

        function checkValidity(value) {
          let length;

          // some number fields have "maxlengths" that should really be maxs.
          // i.e. maxlength 10 means "at most 9 digits"
          // and should be converted to max: 999,999,999
          // but, users will probs keep making this mistake,
          // so till we build better rules around this,
          // coerce numbers to strings
          const str = String(value || '');

          if (field.definition.type === 'richtext') {
            length = getUsedCharactersLength(str);
          } else {
            length = str.length;
          }

          $scope.usedCharacters = length; // used by the template
          $scope.maxCharacters = maxLength; // used by template
          ngModel.$setValidity('maxlength', length <= maxLength);
          return value;
        }
        const counter = $compile($templateCache.get('views/directives/fields/character-count.html'))($scope);
        $element.after(counter);
        ngModel.$parsers.push(checkValidity);
        ngModel.$formatters.push(checkValidity);
      }
    };
  }
  );
