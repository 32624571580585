'use strict';

angular
  .module('tailor')
  .controller('ViewOptionsCtrl', function($scope, $state, configurationService, eventService, historyService) {
    const today = new Date();
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    const viewOptions = $scope.viewOptions = {
      expanded: false,
      changes: {
        enabled: false,
        type: 'tillToday',
        startDate: lastWeek,
        endDate: null,
        startDataRev: null,
        endDataRev: null,
      },
    };
    $scope.configuration.dataRevisions = [];
    $scope.configuration.Events = [];
    configurationService.getEvents($scope.configuration)
      .then(function(Events) {
        $scope.configuration.dataRevisions = _.filter(Events, { type: eventService.PUBLISH_EVENT }).map(function(e) {
          return {
            eventId: e.id,
            path: e.data.path,
            productKey: e.data.productKey,
            number: e.data.dataRevisionNumber,
            date: new Date(e.createdAt),
          };
        });
      });

    $scope.selectDataRev = function($event, startOrEnd, number) {
      const dataRev = _.find($scope.configuration.dataRevisions, { number: parseInt(number, 10) });
      if (dataRev) {
        viewOptions.changes[startOrEnd] = dataRev.date;
      }
    };

    function toLocalTime(time) {
      return moment(time).toDate();
    }
    $scope.applyChanges = function() {
      viewOptions.changes.startDate = toLocalTime(viewOptions.changes.startDate);
      viewOptions.changes.endDate = toLocalTime(viewOptions.changes.endDate);
      if (viewOptions.changes.type === 'tillToday') {
        viewOptions.changes.endDate = new Date();
      }
      if (viewOptions.changes.enabled) {
        $scope.configuration.viewOptions = _.cloneDeep(viewOptions);
      }
      historyService.sidebarChanges($scope.configuration, viewOptions.changes);
    };
    $scope.$watch('viewOptions.changes.enabled', function() {
      $scope.configuration.viewOptions = $scope.configuration.viewOptions || { changes: {} };
      $scope.configuration.viewOptions.changes.enabled = viewOptions.changes.enabled;
    });
  });
