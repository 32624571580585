'use strict';

angular.module('tailor')
  .directive('currency', function CurrencyDirective() {
    return {
      restrict: 'A',
      require: ['ngModel'],
      link: function postLink($scope, $element, $attrs, ctrl) {
        const ngModel = ctrl[0];

        ngModel.$formatters.push(function(value) {
          const parsed = parseFloat(value);
          if(isNaN(parsed)) {
            return value;
          } else {
            return parsed.toFixed(2);
          }
        });
      }
    };
  });
