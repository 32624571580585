'use strict';

angular.module('tailor')
  .factory('addHeadersInterceptor', function(API) {
    let tabId;
    return {
      request: function addHeaders(config) {
        if (_.includes(config.url, API.domain)) {
          config.headers['Monotonic-Time'] = performance.now();
          tabId = tabId || Math.random().toString(36).substr(2, 9);
          config.headers['Tab-Id'] = tabId; // changes every refresh
          return config;
        }
        return config;
      },
    };
  });
