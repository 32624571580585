const DEPENDENCY_HACK_FIELDS = {
  FIDELITY_INTEGRATION_FIELDS: [
    'program_code',
    'plan_type_code',
    'plan_code',
    'integration_subheading',
    'integration_title',
    'benefit_group_code',
    'premium_group_program_code',
    'premium_group_plan_type_code',
    'premium_group_plan_code',
    'ee_option_code',
    'ee_family_option_code',
    'ee_1_option_code',
    'ee_2_option_code',
    'ee_spouse_option_code',
    'ee_1_child_option_code',
    'ee_2_children_option_code',
    'ee_3_or_more_children_option_code',
    'ee_3_children_option_code',
    'ee_4_or_more_children_option_code',
    'ee_spouse_1_child_option_code',
    'ee_spouse_2_or_more_children_option_code',
    'ee_spouse_2_children_option_code',
    'ee_spouse_3_children_option_code',
    'ee_spouse_4_or_more_children_option_code',
    'ee_children_option_code',
    'family_option_code',
    'flat_option_code',
    'individual_option_code',
    'ee_2_option_code',
  ],
  STANDARD_INTEGRATION_FIELDS: [
    'partner_code',
    'integration_subtitle',
    'integration_subheading',
    'integration_title',
    'benefit_group_code',
  ],
  ALEX_CALC_FIELDS: [
    'mention_full_premium',
    'show_percentage_paid_group',
    'show_percentage_paid',
    'percentage_paid',
    'has_simple_full_premiums',
    'simple_full_premium_tier_coverage_type_id',
    'employer_premiums',
  ],
  COMM_API_FIELDS: [
    'has_custom_coverage_text_label',
    'has_custom_router_text_label',
    'has_note_label',
    'has_medical_rec_note_text_label',
    'allow_copay_label',
    'has_accident_intro_custom_note_label',
  ],
};

function checkHidden(fieldDefinition, bcSettingsUnit, currentValue, currentModule) {
  let hidden = currentValue;
  let commAPIValue = bcSettingsUnit.data && bcSettingsUnit.data.use_comm_api && bcSettingsUnit.data.use_comm_api.value;
  
  if (DEPENDENCY_HACK_FIELDS.COMM_API_FIELDS.includes(fieldDefinition.name)) {
    hidden = !commAPIValue;
  } else if (!DEPENDENCY_HACK_FIELDS.ALEX_CALC_FIELDS.includes(fieldDefinition.name)) {
    if (!bcSettingsUnit.data.is_integrated.value) {
      hidden = true;
    } else if (bcSettingsUnit.data.partner_key.value === 'Fidelity') {
      if (fieldDefinition.name === 'integration_subtitle') {
        hidden = ['dental_plan', 'vision_plan'].includes(currentModule.name) ? false : true;
      } else {
        hidden = !DEPENDENCY_HACK_FIELDS.FIDELITY_INTEGRATION_FIELDS.includes(fieldDefinition.name);
      }
    } else if (bcSettingsUnit.data.partner_key.value === 'standard') {
      hidden = !DEPENDENCY_HACK_FIELDS.STANDARD_INTEGRATION_FIELDS.includes(fieldDefinition.name);
    }
    // Show/hide "full premium" field data based on whether the config is using Comm API
    // See BUILDER-1520
  } else if (
    commAPIValue === true
  ) {
    hidden = true;
  }
  return hidden;
}

const dependencyHacks = {
  checkHidden,
  DEPENDENCY_HACK_FIELDS,
};

if (typeof window === 'undefined') {
  // we are executing in nodejs
  module.exports = dependencyHacks;
} else {
  // we are executing in the browser
  if (!window.operators) {
    window.operators = {};
  }
  window.operators.dependencyHacks = dependencyHacks;
}
