'use strict';

angular
  .module('tailor')
  .controller('SharingOptionsCtrl', function($scope, configurationService) {
    $scope.applyChanges = function() {
      configurationService.update($scope.configuration, {
        allowExternalUsers: $scope.configuration.allowExternalUsers,
      });
    };
  });
