'use strict';

/*
 * So.
 *
 * In order for many things to work well, it behooves us to have ng-model in a format like 'thing.other_thing'. This
 * means that it's hard to put expressions in ng-model and have it play nice with... anything. So we invented
 * ng-model-expr. Supply ng-model-expr with an angular expression and it will put an ng-model directive on that element
 * with a value of the evaluated expression, and then tell the element to recompile itself, after removing the original
 * ng-model-expr directive so the whole thing doesn't happen all over again. The recompilation will let the new
 * ng-model directive act like it's been there the whole time, and no-one is the wiser.
 *
 * Nature is fascinating.
 */

angular.module('tailor')
  .directive('ngModelExpr', function ngModelExpr($compile) {
    return {
      restrict: 'A',
      priority: Infinity, // FIRST!
      terminal: true,
      link: function postLink($scope, $element, $attrs) {
        $attrs.$set('ngModel', $scope.$eval($attrs.ngModelExpr));
        $element.removeAttr($attrs.$attr.ngModelExpr);

        $compile($element)($scope); // Let's do the time warp again!
      }
    };
  });
