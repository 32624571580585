'use strict';

angular.module('tailor')
  .directive('date', function date($filter) {
    const dateFilter = $filter('date');
    const DISPLAY_FORMAT = 'MMMM d, yyyy';
    const DATA_FORMAT = 'yyyy-MM-dd';
    const PLACEHOLDER = 'pick a date';
    const OPTIONS = {
      today: '',
      clear: '',
      selectMonths: true,
      selectYears: true,
      container: 'body',
    };

    return {
      templateUrl: 'views/directives/fields/date.html',
      restrict: 'EA',
      replace: true,
      scope: true,
      require: ['ngModel'],
      link: function postLink($scope, $element, $attrs, ctrl) {
        const ngModel = ctrl[0];
        const display = $element.find('span.field-date-display');
        const input = $element.find('input').pickadate(OPTIONS);
        const picker = input.pickadate('picker');


        $scope.$on('$destroy', function() {
          picker.stop();
        });
        // because we're using a span and not an input,
        // the default ng-disabled behavior isn't gonna do anything.
        if ($attrs.ngDisabled) {
          $scope.$watch($attrs.ngDisabled, function(newValue) {
            $scope.disabled = newValue;
          });
        }

        function openPicker(/* event */) {
          if(picker.get('open') || $scope.disabled) {
            return;
          }

          const value = ngModel.$modelValue;
          let selectValue;

          if(value) {
            selectValue = dateFilter(value, DATA_FORMAT).split('-');
            // decrement the month to be zero-based
            selectValue[1] = selectValue[1] - 1;
            // set the picker's initial date to the value
            picker.set('select', selectValue, {
              muted: true
            });
          }

          picker.open(false);
        }

        function onSelection(data) {
          // data.select means a date was selected
          // event also fires when changing months or years
          // but with different properties
          if(data.select) {
            const value = data.select;
            ngModel.$setViewValue(value);
            display.text(formatDate(value));
          } else {
            // if we're here, they selected a year or month
            // pickadate's api sucks. what we really want to do is NOT change the 'STATE.open'
            // internal variable to false upon selection.
            // OR update line 295 of picker.js's render function to call nodes(true)
            // OR override the DatePicker.prototype.nodes function to always enable the stuff.
            // but the API isn't very extensible. AT ALL.
            // So, we'll fake it instead.
            // forcefully remove the disabled-ness.
            picker.$root.find('select').attr('disabled', false);
          }
        }

        function emitChangeEvent(/* value */) {
          ngModel.$validate();
          $scope.$emit('field:change', $attrs.ngModel, ngModel.$modelValue, ngModel.$valid);
        }

        function formatDate(value) {
          return value ? dateFilter(value, DISPLAY_FORMAT) : '';
        }

        function togglePlaceholderClass(value) {
          $element[value === PLACEHOLDER ? 'addClass' : 'removeClass']('placeholder-shown');
          return value;
        }

        function parseDate(value) {
          return dateFilter(value, DATA_FORMAT);
        }

        function render() {
          display.text(formatDate(ngModel.$viewValue) || PLACEHOLDER);
        }

        $element.on('click', openPicker);
        $element.on('keydown keypress', function (event) {
          if(event.which === 32) {
            event.preventDefault();
            openPicker(event);
          }
        });

        picker.on('set', onSelection);

        ngModel.$parsers.push(parseDate);
        ngModel.$viewChangeListeners.push(emitChangeEvent);
        ngModel.$formatters.push(togglePlaceholderClass);
        ngModel.$formatters.push(formatDate);
        ngModel.$render = render;

        ngModel.$validate();

        // set the initial text
        ngModel.$render();
      }
    };
  });
