'use strict';

angular.module('tailor').directive('text', function text() {
  return {
    templateUrl: 'views/directives/fields/text.html',
    restrict: 'EA',
    replace: true,
    scope: true,

    require: ['text', '^ngModel'],
    controller: function textCtrl($scope, $element, $attrs) {
      this.update = function (modelValue, valid) {
        this.emitChangeEvent(modelValue, valid);
        this.emitLengthChangeEvent(modelValue);
      };

      this.emitBlurEvent = function () {
        $scope.$emit('field:blur', $attrs.ngModel);
      };

      this.emitChangeEvent = function (modelValue, valid) {
        $scope.$emit('field:change', $attrs.ngModel, modelValue, valid);
      };

      this.emitLengthChangeEvent = function (modelValue) {
        if (modelValue) {
          $scope.$emit('field:lengthChange', $attrs.ngModel, modelValue.length);
        }
      };
    },
    link: function postLink($scope, $element, $attrs, ctrl) {
      const text = ctrl[0];
      const ngModel = ctrl[1];
      let focused = false;

      // Register a baseline formatter
      function convertToSaneString(value) {
        value = _.isUndefined(value) || _.isNull(value) ? '' : String(value);
        // eslint-disable-next-line
        value = value.replace(/[\x00-\x1F\x7F-\x9F]+/gm, ''); // remove control characters -
        if ($attrs.url) {
          value = value.replace(/[\t]+/gm, '');
        }
        value = value.replace(/[\n\r]+/gm, '');

        // replace HTML encoded character that may have come from JOSEF
        value = value.replace(/&quot;/g, '"');
        value = value.replace(/&amp;/g, '&');

        return value;
      }

      ngModel.$parsers.unshift(convertToSaneString); // sanitize when reading from the DOM
      ngModel.$formatters.unshift(convertToSaneString); // format when writing to the DOM

      function render() {
        if (!focused) {
          $element.text(ngModel.$viewValue);
        }
      }
      ngModel.$render = render;
      ngModel.$render();

      // Event handlers!
      const ENTER_KEY = 13;
      function onKeyPress(event) {
        if (event.key === 'Enter' || event.keyCode === ENTER_KEY || event.charCode === ENTER_KEY) {
          event.preventDefault(); // don't create a newline
        }
      }
      $element.on('keydown', onKeyPress);

      function onKeyUp(ev) {
        const viewValue = $element.text();

        if (viewValue !== ngModel.$viewValue) {
          $scope.usedCharacters = viewValue.length;
          ngModel.$setViewValue(viewValue, ev);
          ngModel.$validate();
          text.update(ngModel.$modelValue, ngModel.$valid);
        }
      }
      $element.on('keyup', onKeyUp);

      function onFocus() {
        $element.addClass('focused');
        focused = true;
      }
      $element.on('focus', onFocus);

      function onBlur() {
        $element.removeClass('focused');
        text.emitBlurEvent();
        focused = false;
        // TODO: re-format view value on blur (right now, it doesn't format until you refresh)
      }
      $element.on('blur', onBlur);

      // Handle pastes from the plain-text-paste directive
      function onPlainTextPaste(ev, pastedText) {
        ngModel.$setViewValue(pastedText, ev);
        ngModel.$validate();
        text.update(ngModel.$modelValue, ngModel.$valid);
      }
      $scope.$on('plainTextPaste', onPlainTextPaste);
      // because we're using a span and not an input,
      // the default ng-disabled behavior isn't gonna do anything.
      let disabled = false;
      if ($attrs.ngDisabled) {
        $scope.$watch($attrs.ngDisabled, function (newValue) {
          disabled = newValue;
          $element[disabled ? 'removeAttr' : 'attr']('contenteditable', '');
        });
      }
    },
  };
});
