'use strict';

angular.module('tailor')
  .directive('appliedQuestion',
    function appliedQuestion(authorizationService) {
      return {
        templateUrl: 'views/directives/eligibility/question.html',
        restrict: 'EA',
        replace: true,
        scope: {
          question: '=appliedQuestion',
          configuration: '=',
          associatedQuestions: '=',
        },
        require: ['^eligibility', 'ngModel'],
        link: function ($scope, $element, $attrs, ctrls) {
          authorizationService.addAuthToScope($scope);
          const eligibilityCtrl = ctrls[0];
          const ngModel = ctrls[1];

          /*
            you'll see this concept of "applying" referenced on questions when they are displayed
            on the eligibility questions screen or displayed inline when viewing plans.
            really it is an inversed "editing" flag applying === true means the question is not
            in editable state, and applying === false means the question is in an editable state.
            questions are in the "applying" state when users are adding or
            modifying eligibility requirements of plans/premiums/etc.
            applying is set false when the user edits the question itself
            in the context of a plan view, or when the user is on the
            eligibilty questions page and all questions are in their editable state.
          */
          $scope.applying = $scope.question.applying;
          $scope.answerIsSelected = eligibilityCtrl.answerIsSelected;
          $scope.noAnswerSelected = eligibilityCtrl.noAnswerSelected;

          function validateQuestionHasAnswer() {
            const associatedQuestion = _.find(
              $scope.associatedQuestions,
              { id: $scope.question.id }
            );

            let questionIsValid = true;
            switch ($scope.question.answerType) {
              case 'choice':
                questionIsValid =
                  associatedQuestion.answers && associatedQuestion.answers.length >= 1;
                break;
              case 'range':
                // defer to ng-form in answer-range.html, see TAILOR-829, again
                break;
              case 'zip':
                questionIsValid =
                  associatedQuestion.zipListId != null && associatedQuestion.inclusive != null;
                break;
            }
            ngModel.$setValidity('question_is_valid', questionIsValid);
          }

          function validateZipQuestionHasAnswer(event, question, fileName, zipListId) {
            const questionIsValid = zipListId != null && fileName != null;
            ngModel.$setValidity('question_is_valid', questionIsValid);
          }

          function associateAnswerToParent(event, choice, question) {
            const answer = {
              id: choice.id,
            };
            const selectedQuestion = _.find($scope.associatedQuestions, { id: question.id });
            selectedQuestion.answers.push(answer);
            validateQuestionHasAnswer();
          }
          function dissociateAnswerFromParent(event, choice, question) {
            const answer = {
              id: choice.id,
            };
            const selectedQuestion = _.find($scope.associatedQuestions, { id: question.id });
            _.remove(selectedQuestion.answers, answer);
            validateQuestionHasAnswer();
          }

          $scope.$on('choice:associateToParent', associateAnswerToParent);
          $scope.$on('choice:dissociateFromParent', dissociateAnswerFromParent);
          $scope.$on('zip:update', validateZipQuestionHasAnswer);
          validateQuestionHasAnswer();
        },
      };
    }
  );
