'use strict';
/**
 * @service historyService
 * @desc given a parent history and a local key, computes the "child" history from the parent using key!
 * given a parent history and a local lookup function, computes the "child" history from the parent using given lookup function!
 */

angular.module('tailor')
  .service('historyService', function(unitService, configurationService) {
    function getUnitHistory(unit, options) {
      return unitService.history(unit, options)
        .then(function(h) {
          return unitService.revisions(unit, { at: options.startDate })
            .then(function(r) {
              return {
                changes: h.map(function(h) {
                  return {
                    revisionId: h.revisionId,
                    timestamp: h.timestamp,
                    whoDunnit: h.whoDunnit,
                    change: h.whatChanged.data,
                  };
                }),
                priorValue: r.data,
              };
            });
        }).then(function(h) {
          const history = h.changes;
          const sectionIndex = {};
          const unitDefinition = unit.definition;
          for (let i = 0; i < history.length; i++) {
            const keys = Object.keys(history[i].change);
            for (let j = 0; j < keys.length; j++) {
              if (_.find(unitDefinition.children, { name: keys[j] })) {
                const field = _.find(unitDefinition.children, { name: keys[j] });
                const section = field.section;
                if (typeof sectionIndex[section] !== 'number') {
                  sectionIndex[section] = 1;
                } else {
                  sectionIndex[section] += 1;
                }
              }
            }
          }

          for(let k = 0; k < unit.sections.length; k++) {
            const sectionName = unit.sections[k].name;
            unit.sections[k].edited = sectionIndex[sectionName] > 0;
          }
          return h;
        });
    }

    function computeChanges(pathFn, parentChanges) {
      let getValue;
      if (typeof pathFn === 'string') {
        getValue = function(data) {
          return _.get(data, pathFn);
        };
      } else if (typeof pathFn === 'function') {
        getValue = pathFn;
      } else {
        throw new Error('Invalid change function passed to historyService');
      }

      let priorValue;
      if (parentChanges.priorValue) {
        priorValue = getValue(parentChanges.priorValue);
      }
      const history = {
        priorValue: getValue(parentChanges.priorValue),
      };
      history.changes = _.reverse(_.reduce(_.reverse((parentChanges.changes || []).slice()), function(changes, h) {
        if (h.change) {
          let historicValue = getValue(h.change);
          if (typeof historicValue !== 'undefined') {
            const change = {
              revisionId: h.revisionId,
              timestamp: h.timestamp,
              whoDunnit: h.whoDunnit,
              change: historicValue,
              prior: _.cloneDeep(priorValue),
            };
            // weird json removal hack;
            // see: api/ deep-diff.js
            if (historicValue.___REMOVED___) {
              historicValue = undefined;
              change.change = undefined;
            }
            if (typeof priorValue !== 'undefined' && historicValue === 'undefined') {
              change.removed = true;
              history.removed = true;
            } else if (typeof priorValue === 'undefined' && historicValue !== 'undefined') {
              change.added = true;
              history.added = true;
            } else {
              history.changed = true;
            }
            changes.unshift(change);

            // get the "next" prior value
            priorValue = _.merge({}, priorValue, historicValue || {});
          }
        }
        return changes;
      }, []));
      return history;
    }

    function sidebarChanges(configuration, options) {
      configurationService.getChanges(configuration, options.startDate, options.endDate);
    }

    return {
      getUnitHistory,
      computeChanges,
      sidebarChanges
    };
  });
