angular.module('tailor').directive('invalidSuffix', function () {
  return {
    require: 'ngModel',
    link: function (scope, elm, attrs, ctrl) {
      ctrl.$validators.invalidSuffix = function (modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue)) {
          return true;
        }

        return attrs.invalidSuffix
          .split(',')
          .every(suffix => !viewValue.endsWith(suffix));
      };
    },
  };
});
