'use strict';

angular.module('tailor')
  .factory('unitTemplateService', function unitTemplateService($q, sectionTemplateService) {
    function buildTitle() {
      const title = angular.element('<h3/>');
      title.html('{{unitDisplayName(unit)}}');
      return title[0].outerHTML;
    }

    function buildFromUnitDefinition(unitDefinition, path) {
      return buildTitle() + _.map(unitDefinition.children, function(section) {
        const $element = angular.element('<div/>');
        $element.attr('section-wrapper', section.name);
        $element.html(sectionTemplateService.buildFromSectionDefinition(section, path + '.' + section.name));
        return $element[0].outerHTML;
      }).join('');
    }

    return {
      buildFromUnitDefinition
    };
  });
