'use strict';

angular.module('tailor').directive('field', function field(ownerService, featureFlagService, TIER_CAPABILITIES) {
  return {
    restrict: 'A',
    priority: 30000,
    scope: true,
    replace: true,
    controller: function fieldCtrl($scope, $rootScope, $element, $attrs /* , $transclude */) {
      // make the definition available to other directives
      // without having to pass it into every scope
      // todo: https://jira.jellyvision.com/jira/browse/TAILOR-1027
      $scope.definition = $scope.$eval($attrs.field);
      this.definition = $scope.definition || {};
      $scope.user = $rootScope.user;
      $scope.TIER_CAPABILITIES = TIER_CAPABILITIES;
    },
    // todo: #techdebt
    // convert this to real templates, not manually manipulated elements stuffs
    // templateUrl: 'views/directives/field.html',
    // cause of angular priorities and transclusion limitations, this isn't as easy as it sounds
    compile: function ($element /* , attrs, transclude */) {
      return {
        pre: function ($scope) {
          const definition = $scope.definition;
          const configuration = $scope.$parent.configuration || {};
          const customerTier = (configuration.customerTier || '').toLowerCase();
          const configurationServiceLevel = ownerService
            .lookupServiceLevel(customerTier)
            .toLowerCase();
          const serviceLevels = definition.serviceLevels || [];
          const parentModuleName = $scope.$parent.module.definition.name;

          definition.attributes = definition.attributes || {};

          const hiddenToUI = Boolean(definition.attributes.hiddenToUI);

          const showToUser = ownerService.shouldShowFieldForRole(
            definition,
            $scope.user.role,
            customerTier
          );

          let hiddenByFlag = false;
          if (definition.featureFlagged) {
            const treatment = featureFlagService.get($scope.user, definition.featureFlagged.flag);
            hiddenByFlag = treatment !== definition.featureFlagged.treatment;
          }
          const flagBannerEnabled =
              featureFlagService
                .get($scope.user, 'builder-2151-no-deductible-plans')
                .toLowerCase() === 'on';
          const hiddenToTier =
              definition.attributes.hiddenToTierGroup &&
              TIER_CAPABILITIES[definition.attributes.hiddenToTierGroup].some(
                t => t.toLowerCase() === customerTier.toLowerCase()
              );

          const bcSettingsModule = _.find(configuration.Modules, function (module) {
            return module.name === 'benefits_counselor_evergreen';
          });

          const bcSettingsUnit = _.find(bcSettingsModule.Units, function (unit) {
            return unit.name === 'settings';
          });

          const isCommAPI = bcSettingsUnit.data.use_comm_api.value;
          const hiddenToTierAndCommAPI = (hiddenToTier && isCommAPI
            && parentModuleName === 'benefits_counselor_evergreen')
            || (hiddenToTier && parentModuleName !== 'benefits_counselor_evergreen');

          const showLabelForCommAPIOnly =
            !isCommAPI &&
            (definition.name === 'covered_services_custom_note_label' ||
              definition.name === 'outro_note_custom_note_label');

          const isProcedureBanner =
            definition.section === 'Procedures' &&
            definition.name === 'procedure_banner';

          // Show the procedure banner **only if** the flag is ON and it's the procedure banner
          const showProcedureBanner = flagBannerEnabled && isProcedureBanner;

          const hidden = Boolean(
            hiddenToUI ||
              !showToUser ||
              hiddenByFlag ||
              showLabelForCommAPIOnly ||
              (isProcedureBanner && !showProcedureBanner) ||
              hiddenToTierAndCommAPI ||
              !_.includes(serviceLevels, configurationServiceLevel) ||
              (definition.ownerOnly && !ownerService.isOwner(customerTier))
          );

          $element
            .addClass('field-wrapper')
            .addClass(definition.type)
            .addClass(definition.attributes.class)
            .attr('field-name', definition.name)
            .attr('field-type', definition.type)
            .attr('ng-form', definition.name)
            .attr('error-messages', definition.name)
            .attr('ng-hide', hidden);

          if (!hiddenToUI) {
            const hasHelpText =
              !_.isEmpty(definition.helpText) &&
              (!_.isEmpty(definition.helpText.text) || !_.isEmpty(definition.helpText.contentCode));
            const hasEncyclopediaId = !_.isEmpty(definition.encyclopediaId);
            if (hasHelpText || hasEncyclopediaId) {
              $element.append('<help-text definition="definition" />');
            }
            if (definition.type !== 'label') {
              $element.append('<div field-history="definition.name" />');
            }
          }
        },
      };
    },
  };
});
