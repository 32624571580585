'use strict';

/**
 * @diretive history
 * @desc reusable directive for displaying history on a given piece of data
 * used by: fieldHistory, questionHistory, collectionItemHistory, other individual uses
 */
/**
 * @service historyItem
 * @desc internal per-item directive used by the `history` directive
 * !! not recommended to use separately
 */

angular.module('tailor')
  .directive('history', function history($compile, $templateCache, authorizationService) {
    const historyTemplate = $templateCache.get('views/directives/history/history.html');
    const buttonTemplate = $templateCache.get('views/directives/history/button.html');
    return {
      restrict: 'A',
      scope: {
        history: '=',
        buttonLocation: '=',
      },
      controller: function($scope) {
        authorizationService.addAuthToScope($scope);
        $scope.definition = $scope.$parent.definition;
        $scope.unit = $scope.$parent.unit;
        $scope.unitDisplayName = $scope.$parent.unitDisplayName;
        $scope.module = $scope.$parent.module;
        $scope.configuration = $scope.$parent.configuration;
        $scope.showFieldChanges = false;
        $scope.toggleHistory = function() {
          $scope.showFieldChanges = !$scope.showFieldChanges;
        };
        $scope.changeDate = function(item) {
          return (new Date(item.timestamp)).getTime();
        };
      },
      // explicitly recompile, not nested transclude, because:
      // https://github.com/angular/angular.js/issues/7842
      compile: function($element) {
        const children = $element.contents().clone();
        $element.empty();
        return {
          pre: function($scope, $element) {
            const parent = angular.element(historyTemplate);
            const historyItem = parent.find('ul [historic-value]');
            historyItem.append(children.clone());
            $element.append($compile(parent)($scope));
          },
          post: function($scope, $element) {
            const button = angular.element(buttonTemplate);
            let buttonLocation;
            if($scope.buttonLocation) {
              buttonLocation = angular.element($scope.buttonLocation);
            } else {
              buttonLocation = $element;
            }
            buttonLocation.before($compile(button)($scope));
          },
        };
      },
    };
  })
  .directive('historicValue', function historicValue() {
    return {
      restrict: 'A',
      templateUrl: 'views/directives/history/historic-value.html',
      transclude: true,
      replace: true,
      scope: true,
      controller: function($scope, $element, $attrs) {
        this.historicValue = $scope.historicValue = $scope.$eval($attrs.historicValue);
      }
    };
  });
