'use strict';
// https://gist.github.com/adamalbrecht/7226278
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
angular.module('tailor')
  .factory('$debounce', function($timeout, $q) {
    return function(func, wait, immediate) {
      let timeout;
      let deferred = $q.defer();
      return function() {
        const context = this, args = arguments;
        const later = function() {
          timeout = null;
          if(!immediate) {
            deferred.resolve(func.apply(context, args));
            deferred = $q.defer();
          }
        };
        const callNow = immediate && !timeout;
        if ( timeout ) {
          $timeout.cancel(timeout);
        }
        timeout = $timeout(later, wait);
        if (callNow) {
          deferred.resolve(func.apply(context, args));
          deferred = $q.defer();
        }
        return deferred.promise;
      };
    };
  });
