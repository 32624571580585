function getDefaultYear() {
  // If it is past July 1st we want to use the following year as the default.
  const today = new Date().getUTCMonth();
  return today > 5 ? String(new Date().getUTCFullYear() + 1) : String(new Date().getUTCFullYear());
}

const defaultValues = {
  getDefaultYear
};

if (typeof window === 'undefined') {
  // we are executing in nodejs
  module.exports = defaultValues;
} else {
  // we are executing in the browser
  if (!window.operators) {
    window.operators = {};
  }
  window.operators.defaultValues = defaultValues;
}
