'use strict';

angular.module('tailor').directive('configurationsTable', function configurationsTable() {
  return {
    restrict: 'E',
    transclude: true,
    templateUrl: 'views/directives/configurations-table.html',
    scope: {
      configurations: '=list',
      id: '@',
    },
    controller: function (
      $scope,
      $state,
      customerService,
      configurationService,
      createConfigurationModal,
      deleteModal,
      loader,
      cookiesService,
      authorizationService
    ) {
      authorizationService.addAuthToScope($scope);
      $scope.sortableColumns = ['id', 'name', 'customer_name', 'customer_tier'];
      /**
       * configurations
       * addConfigInCookie
       * aboutToExpire
       * renewConfiguration
       * expired
       * duplicateConfiguration
       * confirmDeleteConfiguration
       * userCan
       */
      $scope.addConfigInCookie = function (configurationId) {
        try {
          Number.parseInt(configurationId, 10);
        } catch (error) {
          console.log('Passed Configuration ID is not a number.');
          return;
        }
        cookiesService.saveConfigurationId(configurationId);
      };

      $scope.aboutToExpire = function aboutToExpire(config) {
        return (
          config.expirationDate && moment() > moment(config.expirationDate).subtract(2, 'months')
        );
      };

      $scope.renewConfiguration = function renewConfiguration(event, configuration, customer) {
        event.stopPropagation();
        $state.go('customers.duplicateConfiguration', {
          customerId: customer.id,
          configurationId: configuration.id,
        });
      };

      $scope.expired = function expired(config) {
        return config.expirationDate && new Date() > new Date(config.expirationDate);
      };

      $scope.duplicateConfiguration = function duplicateConfiguration(event, configuration) {
        event.stopPropagation();
        loader.open();
        // Implement page reload event
        customerService.getCustomers().then(function (customers) {
          loader.close();
          createConfigurationModal.activate({
            configuration,
            customers,
            duplicating: true,
          });
        });
      };

      $scope.confirmDeleteConfiguration = function confirmDeleteConfiguration(
        event,
        configuration,
        customer
      ) {
        event.stopPropagation();
        deleteModal.activate({
          model: {
            configuration,
            customer,
          },
          confirm: $scope.deleteConfiguration,
          cancel: angular.noop,
          okText: 'Delete it!',
          cancelText: 'Oops, forget it.',
          headerText: 'Delete?',
          text: 'Are you sure you want to delete this configuration? You will not be able to undo once deleted.',
        });
      };

      $scope.deleteConfiguration = function (obj) {
        const configuration = obj.configuration,
          customer = obj.customer;
        loader.open('Deleting experience');

        configurationService
          .remove(configuration, customer)
          .then(function (configuration) {
            $scope.$emit('CONFIGURATION_TABLE_DELETE_CONFIG', {
              id: configuration.id,
              tableId: $scope.id,
            });
            // Implement Message Emitter to handle delete behaviour in parent and page reload
            $scope.configurations = _.reject($scope.configurations, { id: configuration.id });
            return configuration;
          })
          .then(loader.close);
      };

      // ORDERING CODE
      $scope.activeOrder = 'id';
      $scope.reverse = false;
      $scope.applyOrder = function (order) {
        if (!$scope.sortableColumns.includes(order)) {
          return;
        }
        if ($scope.activeOrder === order) {
          $scope.reverse = !$scope.reverse;
        } else {
          $scope.activeOrder = order;
          $scope.reverse = false;
        }
        $scope.$emit('CONFIGURATION_TABLE_ORDER', {
          column: order,
          order: $scope.reverse ? 'DESC' : 'ASC',
          tableId: $scope.id,
        });
      };
      $scope.applyOrder('id');
      $scope.orderFunc = function () {
        return function (benefig) {
          switch ($scope.activeOrder) {
            case 'id':
              return benefig.id;
            case 'customer_name':
              return benefig.customer.name || benefig.customer.informal_name;
            case 'customer_tier':
              return benefig.customerTier;
            // case 'name':
            default:
              return benefig.name;
          }
        };
      };
    },
  };
});
