'use strict';

angular
  .module('tailor')
  .factory('featureFlagService', function featureFlagService($http, API) {
    // Note that the assumption here is the user object upon which we are
    // caching is the same as the token that will be passed to the API call.
    // I'm not sure there can ever be a mismatch, just calling that out.
    function cacheAll(user) {
      if (!user) {
        throw new Error('User object is required to cache feature flags.');
      }
      return $http.get(API.base + '/feature-flags/').then(function (response) {
        user.featureFlags = response.data;
      });
    }

    function get(user, name) {
      let treatment;
      if (user && user.featureFlags) {
        treatment = user.featureFlags[name];
      }
      if (!treatment) {
        treatment = 'control';
      }
      return treatment;
    }

    return {
      cacheAll,
      get,
    };
  });
