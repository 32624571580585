'use strict';

angular.module('tailor').config([
  '$provide',
  function ($provide) {
    $provide.decorator('$exceptionHandler', [
      '$delegate',
      'analytics',
      function exceptionHandlerDecorator($delegate, analytics) {
        return function (exception, cause) {
          // call the original $exceptionHandler
          $delegate(exception, cause);
          analytics.addError(exception.toString());

          Sentry && Sentry.captureException(exception);
        };
      },
    ]);
  },
]);
